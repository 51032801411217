import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { OrderState } from "@/types/order";
import { Order } from "@/models/order";
import { Pagination } from "@/models/pagination";

const state: OrderState = {
  order: new Order(),
  pagination: new Pagination()
};

export const order: Module<OrderState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
