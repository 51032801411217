import { removeCookie, setCookie } from "tiny-cookie";
import { MutationTree } from "vuex";
import { LoginState } from "@/types/login";

const mutations: MutationTree<LoginState> = {
  redirectUrl: (state, redirectUrl: string) => {
    setCookie("redirect_url", redirectUrl);
  },
  clearRedirectUrl: () => {
    removeCookie("redirect_url");
  }
};

export default mutations;
