import {
  IArrangementMethod,
  IArrangementMethodRequest
} from "@/types/arrangement_method";
import { ISelectItem } from "@/types";

export class ArrangementMethod implements IArrangementMethod {
  public id;
  public method;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: IArrangementMethod) {
    this.id = params ? params.id : 0;
    this.method = params ? params.method : "";
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.method,
      value: this.id
    };
  }
}

export class ArrangementMethodRequest implements IArrangementMethodRequest {
  public method = "";

  public constructor(params?: IArrangementMethodRequest) {
    if (!params) {
      return;
    }

    this.method = params.method;
  }

  public createFromArrangementMethod(
    arrangement_method: IArrangementMethod
  ): void {
    this.method = arrangement_method.method;
  }
}
