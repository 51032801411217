import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  IReferenceNumberListRequest,
  IReferenceNumberCreateRequest,
  IReferenceNumberUpdateRequest,
  ReferenceNumberState,
  IReferenceNumber
} from "@/types/reference_number";

export const actions: ActionTree<ReferenceNumberState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: IReferenceNumberListRequest) {
    try {
      const response = await axios.get<IPagination<IReferenceNumber>>(
        "/admin/reference_number",
        {
          params
        }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, reference_number_id: number) {
    try {
      const response = await axios.get<IReferenceNumber>(
        `/admin/reference_number/${reference_number_id}`
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { reference_number_id: number; params: IReferenceNumberUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IReferenceNumber>(
        `/admin/reference_number/${data.reference_number_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async adminCreate({ commit }, params: IReferenceNumberCreateRequest) {
    try {
      const response = await axios.post<IReferenceNumber>(
        "/admin/reference_number",
        params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async adminDelete({ commit }, reference_number_id: number) {
    try {
      await axios.delete(`/api/reference_number/${reference_number_id}`);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: IReferenceNumberListRequest) {
    try {
      const response = await axios.get<IPagination<IReferenceNumber>>(
        "/user/reference_number",
        {
          params
        }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, reference_number_id: number) {
    try {
      const response = await axios.get<IReferenceNumber>(
        `/user/reference_number/${reference_number_id}`
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { reference_number_id: number; params: IReferenceNumberUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IReferenceNumber>(
        `/user/reference_number/${data.reference_number_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async userCreate({ commit }, params: IReferenceNumberCreateRequest) {
    try {
      const response = await axios.post<IReferenceNumber>(
        "/user/reference_number",
        params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async userDelete({ commit }, reference_number_id: number) {
    try {
      await axios.delete(`/api/reference_number/${reference_number_id}`);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  }
};

export default actions;
