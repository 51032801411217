import {
  IProductReceivedOrder,
  IProductReceivedOrderRequest
} from "@/types/product_received_order";

export class ProductReceivedOrder implements IProductReceivedOrder {
  public id;
  public quantity;
  public selling_unit_price;
  public amount; //バックエンド側で計算できるようになっらた不要
  public content;
  public note_id;
  public orders_memo;
  public product_id;
  public product_code;
  public product_name;
  public product_unit;
  public customer_product_id;
  public customer_pn;
  public received_order_id;
  public maker_id;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: IProductReceivedOrder) {
    this.id = params ? params.id : 0;
    this.quantity = params ? params.quantity : null;
    this.selling_unit_price = params ? params.selling_unit_price : null;
    this.amount = params ? params.amount : 0; //バックエンド側で計算できるようになっらた不要
    this.content = params ? params.content : "";
    this.note_id = params ? params.note_id : null;
    this.orders_memo = params ? params.orders_memo : "";
    this.product_id = params ? params.product_id : 0;
    this.product_code = params ? params.product_code : "";
    this.product_unit = params ? params.product_unit : "";
    this.product_name = params ? params.product_name : "";
    this.customer_product_id = params ? params.customer_product_id : null;
    this.customer_pn = params ? params.customer_pn : "";
    this.received_order_id = params ? params.received_order_id : 0;
    this.maker_id = params ? params.maker_id : 0;
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  //1つの商品の合計金額計算
  public get product_amount() {
    return Number(
      ((this.selling_unit_price || 0) * (this.quantity || 0)).toFixed(2)
    );
  }
}

export class ProductReceivedOrderRequest
  implements IProductReceivedOrderRequest
{
  public id: undefined | number = undefined;
  public product_id = 0;
  public selling_unit_price: number | null = null;
  public quantity: number | null = null;
  public amount = 0;
  public content: string | null = null;
  public note_id: number | null = null;
  public orders_memo: string | null = null;
  public customer_product_id: number | null = null;

  //1つの商品の合計金額計算
  public get product_amount() {
    return Number(
      ((this.selling_unit_price || 0) * (this.quantity || 0)).toFixed(2)
    );
  }

  public createFromPrductReceivedOrder(
    productReceivedOrder: ProductReceivedOrder
  ) {
    if (productReceivedOrder.id) {
      this.id = productReceivedOrder.id;
    }
    this.product_id = productReceivedOrder.product_id;
    this.selling_unit_price = productReceivedOrder.selling_unit_price
      ? Number(Number(productReceivedOrder.selling_unit_price).toFixed(2))
      : null;
    this.quantity = productReceivedOrder.quantity;
    this.amount = productReceivedOrder.amount;
    this.content = productReceivedOrder.content;
    this.note_id = productReceivedOrder.note_id;
    this.orders_memo = productReceivedOrder.orders_memo;
    this.customer_product_id = productReceivedOrder.customer_product_id;
  }
}
