import { MutationTree } from "vuex";
import { MonthlyCountStockState } from "@/types/monthly_count_stock";

const mutations: MutationTree<MonthlyCountStockState> = {
  monthly_count_stock: (
    state,
    item: { storing_sum: 0; shipment_sum: 0; warehouse_stock_array: [] }
  ) => {
    state.storing_sum = item.storing_sum;
    state.shipment_sum = item.shipment_sum;
    state.warehouse_stock_array = item.warehouse_stock_array;
  },
  clear: state => {
    state.storing_sum = 0;
    state.shipment_sum = 0;
    state.warehouse_stock_array = [];
  }
};

export default mutations;
