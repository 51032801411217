import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  ISupplierListRequest,
  ISupplierCreateRequest,
  ISupplierUpdateRequest,
  SupplierState,
  ISupplier,
  ISupplierRequest
} from "@/types/supplier";

export const actions: ActionTree<SupplierState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: ISupplierListRequest) {
    try {
      const response = await axios.get<IPagination<ISupplier>>(
        "/admin/supplier",
        { params }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, supplier_id: number) {
    try {
      const response = await axios.get<ISupplier>(
        `/admin/supplier/${supplier_id}`
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { supplier_id: number; params: ISupplierUpdateRequest }
  ) {
    try {
      const response = await axios.patch<ISupplier>(
        `/admin/supplier/${data.supplier_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: ISupplierCreateRequest) {
    try {
      const response = await axios.post<ISupplier>("/admin/supplier", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, supplier_id: number) {
    try {
      await axios.delete(`/admin/supplier/${supplier_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // 二重登録チェック
  async adminIsUniqueName(state, params: ISupplierRequest) {
    try {
      const response = await axios.get<boolean>(
        "/admin/supplier/is_unique_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async adminIsUniqueShortName(state, params: ISupplierRequest) {
    try {
      const response = await axios.get<boolean>(
        "/admin/supplier/is_unique_short_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: ISupplierListRequest) {
    try {
      const response = await axios.get<IPagination<ISupplier>>(
        "/user/supplier",
        {
          params
        }
      );

      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, supplier_id: number) {
    try {
      const response = await axios.get<ISupplier>(
        `/user/supplier/${supplier_id}`
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { supplier_id: number; params: ISupplierUpdateRequest }
  ) {
    try {
      const response = await axios.patch<ISupplier>(
        `/user/supplier/${data.supplier_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: ISupplierCreateRequest) {
    try {
      const response = await axios.post<ISupplier>("/user/supplier", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, supplier_id: number) {
    try {
      await axios.delete(`/user/supplier/${supplier_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // 二重登録チェック
  async userIsUniqueName(state, params: ISupplierRequest) {
    try {
      const response = await axios.get<boolean>(
        "/user/supplier/is_unique_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async userIsUniqueShortName(state, params: ISupplierRequest) {
    try {
      const response = await axios.get<boolean>(
        "/user/supplier/is_unique_short_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  }
};

export default actions;
