import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { ProductShipmentState } from "@/types/product_shipment";
import { ProductShipment } from "@/models/product_shipment";
import { Pagination } from "@/models/pagination";

const state: ProductShipmentState = {
  product_shipment: new ProductShipment(),
  pagination: new Pagination()
};

export const product_shipment: Module<ProductShipmentState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
