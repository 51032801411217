import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "@/types/index";
import { login } from "@/store/login";
import { auth } from "@/store/auth";
import { user } from "@/store/user";
import { maker } from "@/store/maker";
import { product } from "@/store/product";
import { supplier } from "@/store/supplier";
import { customer } from "@/store/customer";
import { branch } from "@/store/branch";
import { customer_staff } from "@/store/customer_staff";
import { supplier_staff } from "@/store/supplier_staff";
import { lot } from "@/store/lot";
import { warehouse } from "@/store/warehouse";
import { tax } from "@/store/tax";
import { shipment } from "@/store/shipment";
import { received_order } from "@/store/received_order";
import { order } from "@/store/order";
import { product_shipment } from "@/store/product_shipment";
import { reference_number } from "@/store/reference_number";
import { movement } from "@/store/movement";
import { movement_storing } from "@/store/movement_storing";
import { stock } from "@/store/stock";
import { error } from "@/store/error";
import { product_index } from "@/store/product_index";
import { product_order_status } from "@/store/product_order_status";
import { monthly_count_stock } from "@/store/monthly_count_stock";
import { customer_product } from "@/store/customer_product";
import { booster } from "@/store/booster";
import { received_orders_management } from "@/store/received_orders_management";
import { ship_slip } from "@/store/ship_slip";
import { payment_method } from "@/store/payment_method";
import { price_tag } from "@/store/price_tag";
import { invoice } from "@/store/invoice";
import { customer_price_tag } from "@/store/customer_price_tag";
import { price_tag_supplier } from "@/store/price_tag_supplier";
import { payment_term } from "@/store/payment_term";
import { trade_term } from "@/store/trade_term";
import { arrangement_method } from "@/store/arrangement_method";
import { quantity_history } from "@/store/quantity_history";
import { zipaddress } from "@/store/zipaddress";
import { moneyforward } from "@/store/moneyforward";
import pdf from "@/store/pdf";
import csv from "@/store/csv";
import note from "@/store/note";

Vue.use(Vuex);
const store: StoreOptions<RootState> = {
  state: {
    version: "1.0.0",
    loadingCount: 0
  },
  mutations: {
    loadingError: state => {
      state.loadingCount = 0;
    },
    loadingIncrement: state => {
      state.loadingCount++;
    },
    loadingDecrement: state => {
      state.loadingCount--;
    }
  },
  actions: {},
  getters: {
    isLoading: state => state.loadingCount > 0
  },
  modules: {
    login,
    auth,
    user,
    maker,
    product,
    supplier,
    supplier_staff,
    lot,
    customer,
    customer_staff,
    branch,
    warehouse,
    tax,
    shipment,
    received_order,
    order,
    product_shipment,
    reference_number,
    movement,
    movement_storing,
    stock,
    error,
    product_index,
    product_order_status,
    monthly_count_stock,
    customer_product,
    booster,
    received_orders_management,
    ship_slip,
    payment_method,
    price_tag,
    invoice,
    price_tag_supplier,
    pdf,
    csv,
    note,
    customer_price_tag,
    payment_term,
    trade_term,
    arrangement_method,
    quantity_history,
    zipaddress,
    moneyforward
  }
};

export default new Vuex.Store<RootState>(store);
