import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { MoneyforwardState } from "@/types/moneyforward";
import { Moneyforward } from "@/models/moneyforward";

const state: MoneyforwardState = {
  moneyforward: new Moneyforward()
};

export const moneyforward: Module<MoneyforwardState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
