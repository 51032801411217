import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { TaxState } from "@/types/tax";
import { Tax } from "@/models/tax";
import { Pagination } from "@/models/pagination";

const state: TaxState = {
  tax: new Tax(),
  pagination: new Pagination()
};

export const tax: Module<TaxState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
