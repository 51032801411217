import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { ErrorState } from "@/types/error";

export const getters: GetterTree<ErrorState, RootState> = {
  error: state => (key: string) => {
    if (!state.error.errors) {
      return [];
    }
    return state.error.errors[key] || "";
  },
  message: state => () => [state.error.message]
};
export default getters;
