import { MutationTree } from "vuex";
import { ProductState, IProduct } from "@/types/product";
import { IPagination } from "@/types";
import { Pagination } from "@/models/pagination";
import { Product } from "@/models/product";

const mutations: MutationTree<ProductState> = {
  pagination: (state, items: IPagination<IProduct>) => {
    const params: IPagination<Product> = {
      current_page: items.current_page,
      first_page_url: items.first_page_url,
      from: items.from,
      last_page: items.last_page,
      last_page_url: items.last_page_url,
      next_page_url: items.next_page_url,
      path: items.path,
      per_page: items.per_page,
      prev_page_url: items.prev_page_url,
      to: items.to,
      total: items.total,
      data: items.data.map(item => new Product(item))
    };

    state.pagination = new Pagination(params);
  },
  paginationSegment: (state, items: IPagination<IProduct>) => {
    if (!state.pagination) {
      return; // paginationの方にセットするべき。actionの方で分岐させる
    }
    const products = items.data.map(item => new Product(item)); // 変形
    state.pagination.data = state.pagination.data.concat(products); //要素を追加
  },
  search_list: (state, items: IPagination<IProduct>) => {
    const params: IPagination<Product> = {
      current_page: items.current_page,
      first_page_url: items.first_page_url,
      from: items.from,
      last_page: items.last_page,
      last_page_url: items.last_page_url,
      next_page_url: items.next_page_url,
      path: items.path,
      per_page: items.per_page,
      prev_page_url: items.prev_page_url,
      to: items.to,
      total: items.total,
      data: items.data.map(item => new Product(item))
    };

    state.search_list = new Pagination(params);
  },
  single: (state, item: IProduct) => {
    state.product = new Product(item);
  },
  clear: state => {
    state.product = new Product();
    state.pagination = new Pagination();
  }
};

export default mutations;
