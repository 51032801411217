import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { ProductStockState } from "@/types/product_stock";

export const getters: GetterTree<ProductStockState, RootState> = {
  // selectItem: state => {
  //   if (!state.pagination) {
  //     return [];
  //   }
  all_quantity: state => {
    return state.all_quantity || 0;
  },
  stock_by_warehouse: state => {
    return state.stock_by_warehouse || [];
  }
};
export default getters;
