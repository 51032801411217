import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  ICustomerPriceTagListRequest,
  ICustomerPriceTagCreateRequest,
  ICustomerPriceTagUpdateRequest,
  CustomerPriceTagState,
  ICustomerPriceTag,
  ICustomerPriceTagRequest
} from "@/types/customer_price_tag";

export const actions: ActionTree<CustomerPriceTagState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: ICustomerPriceTagListRequest) {
    try {
      const response = await axios.get<IPagination<ICustomerPriceTag>>(
        "/admin/customer_price_tag",
        { params }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, customer_price_tag_id: number) {
    try {
      const response = await axios.get<{
        customerPriceTagDetailViewModel: ICustomerPriceTag;
      }>(`/admin/customer_price_tag/${customer_price_tag_id}`);
      commit("single", response.data.customerPriceTagDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: {
      customer_price_tag_id: number;
      params: ICustomerPriceTagUpdateRequest;
    }
  ) {
    try {
      const response = await axios.patch<ICustomerPriceTag>(
        `/admin/customer_price_tag/${data.customer_price_tag_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: ICustomerPriceTagCreateRequest) {
    try {
      const response = await axios.post<ICustomerPriceTag>(
        "/admin/customer_price_tag",
        params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, customer_price_tag_id: number) {
    try {
      await axios.delete(`/admin/customer_price_tag/${customer_price_tag_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // 二重登録チェック
  async adminIsUniqueCustomerPN(state, params: ICustomerPriceTagRequest) {
    try {
      const response = await axios.get<boolean>(
        "/admin/customer_price_tag/is_unique_customerPN",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: ICustomerPriceTagListRequest) {
    try {
      const response = await axios.get<IPagination<ICustomerPriceTag>>(
        "/user/customer_price_tag",
        { params }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, customer_price_tag_id: number) {
    try {
      const response = await axios.get<{
        customerPriceTagDetailViewModel: ICustomerPriceTag;
      }>(`/user/customer_price_tag/${customer_price_tag_id}`);
      commit("single", response.data.customerPriceTagDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: {
      customer_price_tag_id: number;
      params: ICustomerPriceTagUpdateRequest;
    }
  ) {
    try {
      const response = await axios.patch<ICustomerPriceTag>(
        `/user/customer_price_tag/${data.customer_price_tag_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: ICustomerPriceTagCreateRequest) {
    try {
      const response = await axios.post<ICustomerPriceTag>(
        "/user/customer_price_tag",
        params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, customer_price_tag_id: number) {
    try {
      await axios.delete(`/user/customer_price_tag/${customer_price_tag_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // nominalUser二重登録チェック
  async userIsUniqueCustomerPN(state, params: ICustomerPriceTagRequest) {
    try {
      const response = await axios.get<boolean>(
        "/user/customer_price_tag/is_unique_customerPN",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  }
};

export default actions;
