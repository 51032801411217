import axios from "axios";
import { ActionTree } from "vuex";
import { RootState } from "@/types";
import { IZipaddress, ZipaddressState } from "@/types/zipaddress";

export const actions: ActionTree<ZipaddressState, RootState> = {
  //admin、user共通メソッド
  async getZipaddress({ commit }, zipcode: string) {
    try {
      const response = await axios.get<IZipaddress[]>(
        `https://apis.postcode-jp.com/api/v5/postcodes/${zipcode}`,
        {
          headers: {
            apikey: process.env.VUE_APP_POSTCODE_JP_API_KEY
          }
        }
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  }
};

export default actions;
