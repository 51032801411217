import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { PriceTagSupplierState } from "@/types/price_tag_supplier";
import { PriceTagSupplier } from "@/models/price_tag_supplier";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<PriceTagSupplierState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.price_tag_supplier || new PriceTagSupplier();
  },
  selectItem: state => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data.map(item => item.toSelectItem());
  },
  find: state => (id: number) => {
    return state.pagination
      ? state.pagination.data.find(supplier => {
          return supplier.id == id;
        })
      : new PriceTagSupplier();
  },
  findSupplierPn: state => (supplier_pn: string) => {
    return state.pagination
      ? state.pagination.data.find(price_tag_supplier => {
          return price_tag_supplier.supplier_pn == supplier_pn;
        })
      : new PriceTagSupplier();
  },
  filterdSelectItem: state => (supplier_id: number, price_tag_id: number) => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data
      .filter(
        item =>
          item.price_tag_id == price_tag_id && item.supplier_id == supplier_id
      )
      .map(item => item.toSelectItem());
  }
};
export default getters;
