import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { ProductState } from "@/types/product";
import { Product } from "@/models/product";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<ProductState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.product || new Product();
  },
  selectItem: state => {
    if (!state.pagination) {
      return [];
    }

    return state.pagination.data.map(item => item.toSelectItem());
  },
  searchList: state => {
    if (!state.search_list) {
      return [];
    }

    return state.search_list.data.map(item => item.toSelectItem());
  },
  alertList: state => {
    if (!state.pagination) {
      return [];
    }

    return state.pagination.data.filter(
      item =>
        item.alert_quantity != null &&
        item.alert_quantity >= item.stockage_quantity
    );
  },
  filterdSelectItem: state => (id: number) => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data
      .filter(item => item.maker_id === id)
      .map(item => item.toSelectItem());
  },
  find: state => (id: number) => {
    if (!state.pagination) {
      return new Product();
    }

    return state.pagination.data.find(item => {
      return item.id == id;
    });
  },
  findCode: state => (code: string) => {
    return state.pagination
      ? state.pagination.data.find(product => {
          return product.code == code;
        })
      : new Product();
  }
};
export default getters;
