import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { ProductState } from "@/types/product";
import { Product } from "@/models/product";
import { Pagination } from "@/models/pagination";

const state: ProductState = {
  product: new Product(),
  pagination: new Pagination(),
  search_list: new Pagination()
};

export const product: Module<ProductState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
