import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  ICustomerStaffListRequest,
  ICustomerStaffCreateRequest,
  ICustomerStaffUpdateRequest,
  CustomerStaffState,
  ICustomerStaff
} from "@/types/customer_staff";

export const actions: ActionTree<CustomerStaffState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: ICustomerStaffListRequest) {
    try {
      const response = await axios.get<IPagination<ICustomerStaff>>(
        "/admin/customer_staff",
        { params }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, customer_staff_id: number) {
    try {
      const response = await axios.get<ICustomerStaff>(
        `/admin/customer_staff/${customer_staff_id}`
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { customer_staff_id: number; params: ICustomerStaffUpdateRequest }
  ) {
    try {
      const response = await axios.patch<ICustomerStaff>(
        `/admin/customer_staff/${data.customer_staff_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: ICustomerStaffCreateRequest) {
    try {
      const response = await axios.post<ICustomerStaff>(
        "/admin/customer_staff",
        params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, customer_staff_id: number) {
    try {
      await axios.delete(`/admin/customer_staff/${customer_staff_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: ICustomerStaffListRequest) {
    try {
      const response = await axios.get<IPagination<ICustomerStaff>>(
        "/user/customer_staff",
        { params }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, customer_staff_id: number) {
    try {
      const response = await axios.get<ICustomerStaff>(
        `/user/customer_staff/${customer_staff_id}`
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { customer_staff_id: number; params: ICustomerStaffUpdateRequest }
  ) {
    try {
      const response = await axios.patch<ICustomerStaff>(
        `/user/customer_staff/${data.customer_staff_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: ICustomerStaffCreateRequest) {
    try {
      const response = await axios.post<ICustomerStaff>(
        "/user/customer_staff",
        params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, customer_staff_id: number) {
    try {
      await axios.delete(`/user/customer_staff/${customer_staff_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  }
};

export default actions;
