import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { ReferenceNumberState } from "@/types/reference_number";
import { ReferenceNumber } from "@/models/reference_number";
import { Pagination } from "@/models/pagination";

const state: ReferenceNumberState = {
  reference_number: new ReferenceNumber(),
  pagination: new Pagination()
};

export const reference_number: Module<ReferenceNumberState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
