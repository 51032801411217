import {
  IDuplicateProduct,
  IInvoiceDuplicateProduct,
  IProductDetails,
  ISelling,
  IShipSlipRequest
} from "@/types/ship_slip";

export class DuplicateProduct implements IDuplicateProduct {
  public product_name;
  public product_id;
  public product_code;
  public customer_pn;
  public quantity_sum;
  public product_unit;
  public sellings: ISelling[];

  public constructor(params?: IDuplicateProduct) {
    this.product_name = params ? params.product_name : "";
    this.product_id = params ? params.product_id : 0;
    this.product_code = params ? params.product_code : "";
    this.customer_pn = params ? params.customer_pn : "";
    this.quantity_sum = params ? params.quantity_sum : 0;
    this.product_unit = params ? params.product_unit : "";
    this.sellings = params ? params.sellings : [];
  }
}

export class ShipSlipRequest implements IShipSlipRequest {
  public shipment_id;
  public is_amount;
  public sheet_size;
  public sheet_type;
  public is_company_stamp;
  public print_date;
  public delivery_slip_remark;
  public invoice_remark;
  public is_amount_slip;

  public products: IProductDetails[];

  public constructor(params?: IShipSlipRequest) {
    this.shipment_id = params ? params.shipment_id : 0;
    this.is_amount = params ? params.is_amount : true;
    this.sheet_size = params ? params.sheet_size : "";
    this.sheet_type = params ? params.sheet_type : "";
    this.is_company_stamp = params ? params.is_company_stamp : true;
    this.print_date = params ? params.print_date : "";
    this.delivery_slip_remark = params ? params.delivery_slip_remark : "";
    this.invoice_remark = params ? params.invoice_remark : "";
    this.is_amount_slip = params ? params.is_amount_slip : true;
    this.products = params ? params.products : [];
  }
}

export class InvoiceDuplicateProduct implements IInvoiceDuplicateProduct {
  public received_order_no;
  public products: IDuplicateProduct[];

  public constructor(params?: IInvoiceDuplicateProduct) {
    this.received_order_no = params ? params.received_order_no : "";
    this.products = params ? params.products : [];
  }
}
