import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { SupplierState } from "@/types/supplier";
import { Supplier } from "@/models/supplier";
import { Pagination } from "@/models/pagination";

const state: SupplierState = {
  supplier: new Supplier(),
  pagination: new Pagination()
};

export const supplier: Module<SupplierState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
