import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  IShipmentListRequest,
  IShipmentCreateRequest,
  IShipmentUpdateRequest,
  ShipmentState,
  IShipment
} from "@/types/shipment";

export const actions: ActionTree<ShipmentState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: IShipmentListRequest) {
    try {
      const response = await axios.get<IPagination<IShipment>>(
        "/admin/shipment",
        {
          params
        }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, shipment_id: number) {
    try {
      const response = await axios.get<{ shipmentDetailViewModel: IShipment }>(
        `/admin/shipment/${shipment_id}`
      );
      commit("single", response.data.shipmentDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGetCalc({ commit }, shipment_id: number) {
    try {
      const response = await axios.get<{ shipmentDetailViewModel: IShipment }>(
        `/admin/shipment/${shipment_id}`
      );
      commit("calcSingle", response.data.shipmentDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { shipment_id: number; params: IShipmentUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IShipment>(
        `/admin/shipment/${data.shipment_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: IShipmentCreateRequest) {
    try {
      const response = await axios.post<IShipment>("/admin/shipment", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, shipment_id: number) {
    try {
      await axios.delete(`/admin/shipment/${shipment_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  //PreShipment
  async adminPreShipmentUpdate(
    { commit },
    data: { shipment_id: number; params: IShipmentUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IShipment>(
        `/admin/shipment_preparation/${data.shipment_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminPreShipmentCreate({ commit }, params: IShipmentCreateRequest) {
    try {
      const response = await axios.post<IShipment>(
        "/admin/shipment_preparation",
        params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminPreShipmentDelete({ commit }, shipment_id: number) {
    try {
      await axios.delete(`/admin/shipment_preparation/${shipment_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  //受注ステータスの自動切替
  async adminIsEstimatedShipment({ commit }, shipment_id: number) {
    try {
      await axios.put<boolean>(
        `/admin/shipment/${shipment_id}/is_estimated_shipment`
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  // shipment_statusのみを変更する処理
  async adminChangeShipmentStatus(
    { commit },
    data: { shipment_id: number; params: IShipmentUpdateRequest }
  ) {
    try {
      await axios.put<IShipment>(
        `/admin/shipment/${data.shipment_id}/change_shipment_status`,
        data.params
      );
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("出荷完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  // 残出荷数量を確認して0だったら受注のステータスを出荷済みにする処理
  // 0以上なら分納ステータスにする処理
  async adminIsShipmentComplete({ commit }, shipment_id: number) {
    try {
      await axios.put(`/admin/shipment/${shipment_id}/is_shipment_complete`);

      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("出荷完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //納品書番号登録及び取得
  async adminStoredShipmentNo(
    { commit },
    data: { shipment_id: number; shipment_date: string }
  ) {
    try {
      await axios.put<boolean>(
        `/admin/shipment/${data.shipment_id}/stored_shipment_no`,
        data.shipment_date
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //納品書番号更新
  async adminUpdateShipmentNo(
    { commit },
    data: { shipment_id: number; shipment_no: string | null }
  ) {
    try {
      await axios.put<boolean>(
        `/admin/shipment/${data.shipment_id}/update_shipment_no`,
        data
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //請求書番号更新
  async adminUpdateInvoiceNo(
    { commit },
    data: { shipment_id: number; invoice_no: string | null }
  ) {
    try {
      await axios.put<boolean>(
        `/admin/shipment/${data.shipment_id}/update_invoice_no`,
        data
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: IShipmentListRequest) {
    try {
      const response = await axios.get<IPagination<IShipment>>(
        "/user/shipment",
        {
          params
        }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, shipment_id: number) {
    try {
      const response = await axios.get<{ shipmentDetailViewModel: IShipment }>(
        `/user/shipment/${shipment_id}`
      );
      commit("single", response.data.shipmentDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGetCalc({ commit }, shipment_id: number) {
    try {
      const response = await axios.get<{ shipmentDetailViewModel: IShipment }>(
        `/user/shipment/${shipment_id}`
      );
      commit("calcSingle", response.data.shipmentDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { shipment_id: number; params: IShipmentUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IShipment>(
        `/user/shipment/${data.shipment_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: IShipmentCreateRequest) {
    try {
      const response = await axios.post<IShipment>("/user/shipment", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, shipment_id: number) {
    try {
      await axios.delete(`/user/shipment/${shipment_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  //PreShipment
  async userPreShipmentUpdate(
    { commit },
    data: { shipment_id: number; params: IShipmentUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IShipment>(
        `/user/shipment_preparation/${data.shipment_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userPreShipmentCreate({ commit }, params: IShipmentCreateRequest) {
    try {
      const response = await axios.post<IShipment>(
        "/user/shipment_preparation",
        params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userPreShipmentDelete({ commit }, shipment_id: number) {
    try {
      await axios.delete(`/user/shipment_preparation/${shipment_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  //受注ステータスの自動切替
  async userIsEstimatedShipment({ commit }, shipment_id: number) {
    try {
      await axios.put<boolean>(
        `/user/shipment/${shipment_id}/is_estimated_shipment`
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  // shipment_statusのみを変更する処理
  async userChangeShipmentStatus(
    { commit },
    data: { shipment_id: number; params: IShipmentUpdateRequest }
  ) {
    try {
      await axios.put<IShipment>(
        `/user/shipment/${data.shipment_id}/change_shipment_status`,
        data.params
      );
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("出荷完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  // 残出荷数量を確認して0だったら受注のステータスを出荷済みにする処理
  // 0以上なら分納ステータスにする処理
  async userIsShipmentComplete({ commit }, shipment_id: number) {
    try {
      await axios.put(`/user/shipment/${shipment_id}/is_shipment_complete`);

      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("出荷完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //納品書番号登録及び取得
  async userStoredShipmentNo(
    { commit },
    data: { shipment_id: number; shipment_date: string }
  ) {
    try {
      await axios.put<boolean>(
        `/user/shipment/${data.shipment_id}/stored_shipment_no`,
        data.shipment_date
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //納品書番号更新
  async userUpdateShipmentNo(
    { commit },
    data: { shipment_id: number; shipment_no: string | null }
  ) {
    try {
      await axios.put<boolean>(
        `/user/shipment/${data.shipment_id}/update_shipment_no`,
        data
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //請求書番号更新
  async userUpdateInvoiceNo(
    { commit },
    data: { shipment_id: number; invoice_no: string | null }
  ) {
    try {
      await axios.put<boolean>(
        `/user/shipment/${data.shipment_id}/update_invoice_no`,
        data
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  }
};

export default actions;
