import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { PriceTagSupplierState } from "@/types/price_tag_supplier";
import { PriceTagSupplier } from "@/models/price_tag_supplier";
import { Pagination } from "@/models/pagination";

const state: PriceTagSupplierState = {
  price_tag_supplier: new PriceTagSupplier(),
  pagination: new Pagination()
};

export const price_tag_supplier: Module<PriceTagSupplierState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
