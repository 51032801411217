import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { BranchState } from "@/types/branch";
import { Branch } from "@/models/branch";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<BranchState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.branch || new Branch();
  },
  selectItem: state => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data.map(item => item.toSelectItem());
  },
  filterdSelectItem: state => (id: number) => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data
      .filter(item => item.customer_id === id)
      .map(item => item.toSelectItem());
  },
  find: state => (id: number) => {
    return state.pagination
      ? state.pagination.data.find(branch => {
          return branch.id == id;
        })
      : new Branch();
  }
};
export default getters;
