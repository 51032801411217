import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { ZipaddressState } from "@/types/zipaddress";
import { Zipaddress } from "@/models/zipaddress";

export const getters: GetterTree<ZipaddressState, RootState> = {
  single: state => {
    return state.zipaddress || new Zipaddress();
  }
};
export default getters;
