import { MutationTree } from "vuex";
import { BoosterState, IBooster } from "@/types/booster";
import { Booster } from "@/models/booster";

const mutations: MutationTree<BoosterState> = {
  single: (state, item: IBooster) => {
    state.booster = new Booster(item);
  },
  booster: (
    state,
    item: {
      buying_price: 0;
      selling_price: 0;
      gross_profit: 0;
      gross_profit_rate: 0;
      monthly_calc: [];
      user_calc: [];
      supplier_calc: [];
      product_calc: [];
    }
  ) => {
    state.buying_price = item.buying_price;
    state.selling_price = item.selling_price;
    state.gross_profit = item.gross_profit;
    state.gross_profit_rate = item.gross_profit_rate;
    state.monthly_calc = item.monthly_calc;
    state.user_calc = item.user_calc;
    state.supplier_calc = item.supplier_calc;
    state.product_calc = item.product_calc;
  },
  clear: state => {
    state.buying_price = 0;
    state.selling_price = 0;
    state.gross_profit = 0;
    state.gross_profit_rate = 0;
    state.monthly_calc = [];
    state.user_calc = [];
    state.supplier_calc = [];
    state.product_calc = [];
  }
};

export default mutations;
