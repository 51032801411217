import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { TradeTermState } from "@/types/trade_term";
import { TradeTerm } from "@/models/trade_term";
import { Pagination } from "@/models/pagination";

const state: TradeTermState = {
  trade_term: new TradeTerm(),
  pagination: new Pagination()
};

export const trade_term: Module<TradeTermState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
