import { Vue } from "vue-property-decorator";
import { ISelectItem } from "@/types";
import { IUser, IUserRequest } from "@/types/user";

export class User implements IUser {
  public id;
  public name;
  public name_eng;
  public kana;
  public initial_name;
  public email;
  public password;
  public role;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: IUser) {
    this.id = params ? params.id : 0;
    this.name = params ? params.name : "";
    this.name_eng = params ? params.name_eng : "";
    this.kana = params ? params.kana : "";
    this.initial_name = params ? params.initial_name : "";
    this.email = params ? params.email : "";
    this.password = params ? params.password : "";
    this.role = params ? params.role : Vue.prototype.$userRole.user.value;
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.name,
      value: this.id
    };
  }

  public toRelationUserSelectItem(): ISelectItem {
    return {
      text: this.name,
      value: this.id
    };
  }
}

export class UserRequest implements IUserRequest {
  public name = "";
  public name_eng = "";
  public kana? = "";
  public initial_name = "";
  public email = "";
  public password: string | null = null;
  public role = 0;

  public createFromUser(params: IUser) {
    this.name = params.name || "";
    this.name_eng = params.name_eng || "";
    this.kana = params.kana || "";
    this.initial_name = params.initial_name || "";
    this.email = params.email || "";
    this.password = params.password || null;
    this.role = params.role || Vue.prototype.$userRole.user.value;
  }
}
