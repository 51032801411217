import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { QuantityHistoryState } from "@/types/quantity_history";
import { QuantityHistory } from "@/models/quantity_history";

export const getters: GetterTree<QuantityHistoryState, RootState> = {
  single: state => {
    return state.quantity_history || new QuantityHistory();
  }
};
export default getters;
