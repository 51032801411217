import {
  IPriceTagSupplier,
  IPriceTagSupplierCreateRequest,
  IPriceTagSupplierRequest,
  IPriceTagSupplierUpdateRequest
} from "@/types/price_tag_supplier";
import { ISelectItem } from "@/types";

export class PriceTagSupplier implements IPriceTagSupplier {
  public id;
  public supplier_pn;
  public supplier_name;
  public supplier_id;
  public price_tag_name;
  public price_tag_id;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: IPriceTagSupplier) {
    this.id = params ? params.id : 0;
    this.supplier_pn = params ? params.supplier_pn : "";
    this.supplier_name = params ? params.supplier_name : "";
    this.supplier_id = params ? params.supplier_id : 0;
    this.price_tag_name = params ? params.price_tag_name : "";
    this.price_tag_id = params ? params.price_tag_id : 0;
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.supplier_pn,
      value: this.id
    };
  }
}

export class PriceTagSupplierRequest implements IPriceTagSupplierRequest {
  public supplier_pn = "";
  public supplier_id = 0;
  public price_tag_id = 0;
  public createFromPriceTagSupplier(price_tag_supplier: IPriceTagSupplier) {
    this.supplier_pn = price_tag_supplier.supplier_pn || "";
    this.supplier_id = price_tag_supplier.supplier_id || 0;
    this.price_tag_id = price_tag_supplier.price_tag_id || 0;
  }
}

export class PriceTagSupplierCreateRequest
  extends PriceTagSupplierRequest
  implements IPriceTagSupplierCreateRequest {}

export class PriceTagSupplierUpdateRequest
  extends PriceTagSupplierRequest
  implements IPriceTagSupplierUpdateRequest
{
  public id = 0;
  public createFromPriceTagSupplier(price_tag_supplier: IPriceTagSupplier) {
    this.id = price_tag_supplier.id || 0;

    super.createFromPriceTagSupplier(price_tag_supplier);
  }
}
