import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  IMovementStoringListRequest,
  IMovementStoringCreateRequest,
  IMovementStoringUpdateRequest,
  MovementStoringState,
  IMovementStoring
} from "@/types/movement_storing";

export const actions: ActionTree<MovementStoringState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: IMovementStoringListRequest) {
    try {
      const response = await axios.get<IPagination<IMovementStoring>>(
        "/admin/movement",
        { params }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, movement_id: number) {
    try {
      const response = await axios.get<{
        movementDetailViewModel: IMovementStoring;
      }>(`/admin/movement/${movement_id}`);
      commit("single", response.data.movementDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { movement_id: number; params: IMovementStoringUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IMovementStoring>(
        `/admin/storing/${data.movement_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },
  async adminCreate({ commit }, params: IMovementStoringCreateRequest) {
    try {
      const response = await axios.post<IMovementStoring>(
        "/admin/storing",
        params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, movement_id: number) {
    try {
      await axios.delete(`/admin/movement/${movement_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  //出荷後、倉庫移動後の入荷数量変更
  async adminUpdateQuantity(
    { commit },
    data: { movement_id: number; quantity: string }
  ) {
    try {
      await axios.put(
        `/admin/storing/${data.movement_id}/update_quantity`,
        data
      );
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: IMovementStoringListRequest) {
    try {
      const response = await axios.get<IPagination<IMovementStoring>>(
        "/user/movement",
        { params }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, movement_id: number) {
    try {
      const response = await axios.get<{
        movementDetailViewModel: IMovementStoring;
      }>(`/user/movement/${movement_id}`);
      commit("single", response.data.movementDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { movement_id: number; params: IMovementStoringUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IMovementStoring>(
        `/user/storing/${data.movement_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },
  async userCreate({ commit }, params: IMovementStoringCreateRequest) {
    try {
      const response = await axios.post<IMovementStoring>(
        "/user/storing",
        params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, movement_id: number) {
    try {
      await axios.delete(`/user/movement/${movement_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  //出荷後、倉庫移動後の入荷数量変更
  async userUpdateQuantity(
    { commit },
    data: { movement_id: number; quantity: string }
  ) {
    try {
      await axios.put(
        `/user/storing/${data.movement_id}/update_quantity`,
        data
      );
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  }
};

export default actions;
