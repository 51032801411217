import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { SupplierStaffState } from "@/types/supplier_staff";
import { SupplierStaff } from "@/models/supplier_staff";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<SupplierStaffState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.supplier_staff || new SupplierStaff();
  }
  // selectItem: state => {
  //   return state.pagination
  //     ? state.pagination.data.map(supplier_staff => SupplierStaff.toSelectItem(supplier_staff))
  //     : [];
  // },
  // find: state => (id: number) => {
  //   return state.pagination
  //     ? state.pagination.data.find(supplier_staff => {
  //         return supplier_staff.id == id;
  //       })
  //     : new SupplierStaff();
  // }
};
export default getters;
