import Vue from "vue";
import VueFlashMessage from "vue-flash-message";
import App from "./App.vue";
import "./constant";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";

// set api default url
axios.defaults.baseURL = process.env.VUE_APP_API_HOST;

// use Flash Message
Vue.use(VueFlashMessage);

// request
axios.interceptors.request.use(config => {
  store.commit("loadingIncrement");
  return config;
});

// responce
axios.interceptors.response.use(
  response => {
    store.commit("loadingDecrement");
    return response;
  },
  error => {
    store.commit("loadingError");
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false;

// logincheck
(async () => {
  await store.dispatch("auth/init");

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount("#app");
})();
