import Vue from "vue";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  ITradeTermListRequest,
  ITradeTermCreateRequest,
  ITradeTermUpdateRequest,
  TradeTermState,
  ITradeTerm
} from "@/types/trade_term";
import axios from "axios";

export const actions: ActionTree<TradeTermState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: ITradeTermListRequest) {
    try {
      const response = await axios.get<IPagination<ITradeTerm>>(
        "/admin/trade_term",
        {
          params
        }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, trade_term_id: number) {
    try {
      const response = await axios.get<ITradeTerm>(
        `/admin/trade_term/${trade_term_id}`
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { trade_term_id: number; params: ITradeTermUpdateRequest }
  ) {
    try {
      const response = await axios.patch<ITradeTerm>(
        `/admin/trade_term/${data.trade_term_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: ITradeTermCreateRequest) {
    try {
      const response = await axios.post<ITradeTerm>(
        "/admin/trade_term",
        params
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, trade_term_id: number) {
    try {
      await axios.delete(`/admin/trade_term/${trade_term_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: ITradeTermListRequest) {
    try {
      const response = await axios.get<IPagination<ITradeTerm>>(
        "/user/trade_term",
        {
          params
        }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, trade_term_id: number) {
    try {
      const response = await axios.get<ITradeTerm>(
        `/user/trade_term/${trade_term_id}`
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { trade_term_id: number; params: ITradeTermUpdateRequest }
  ) {
    try {
      const response = await axios.patch<ITradeTerm>(
        `/user/trade_term/${data.trade_term_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: ITradeTermCreateRequest) {
    try {
      const response = await axios.post<ITradeTerm>("/user/trade_term", params);

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, trade_term_id: number) {
    try {
      await axios.delete(`/user/trade_term/${trade_term_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  //invoice_onlyメソッド
  async invoiceOnlyGetList({ commit }, params: ITradeTermListRequest) {
    try {
      const response = await axios.get<IPagination<ITradeTerm>>(
        "/invoice_only/trade_term",
        {
          params
        }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  }
};

export default actions;
