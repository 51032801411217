import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { ArrangementMethodState } from "@/types/arrangement_method";
import { ArrangementMethod } from "@/models/arrangement_method";
import { Pagination } from "@/models/pagination";

const state: ArrangementMethodState = {
  arrangement_method: new ArrangementMethod(),
  pagination: new Pagination()
};

export const arrangement_method: Module<ArrangementMethodState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
