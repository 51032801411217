import {
  ICustomerProduct,
  ICustomerProductCreateRequest,
  ICustomerProductRequest,
  ICustomerProductUpdateRequest
} from "@/types/customer_product";
import { ISelectItem } from "@/types";
import { IProductReceivedOrder } from "@/types/product_received_order";

export class CustomerProduct implements ICustomerProduct {
  public id;
  public maker_name;
  public customer_name;
  public customer_pn;
  public product_name;
  public product_code;
  public product_unit;
  public product_id;
  public customer_id;
  public maker_id;
  public product_received_order: IProductReceivedOrder[];
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: ICustomerProduct) {
    this.id = params ? params.id : 0;
    this.maker_name = params ? params.maker_name : "";
    this.customer_name = params ? params.customer_name : "";
    this.customer_pn = params ? params.customer_pn : "";
    this.product_name = params ? params.product_name : "";
    this.product_code = params ? params.product_code : "";
    this.product_unit = params ? params.product_unit : "";
    this.product_id = params ? params.product_id : 0;
    this.customer_id = params ? params.customer_id : 0;
    this.maker_id = params ? params.maker_id : 0;
    this.product_received_order = params ? params.product_received_order : [];
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.customer_pn,
      value: this.id
    };
  }
}

export class CustomerProductRequest implements ICustomerProductRequest {
  public customer_pn = "";
  public product_id = 0;
  public customer_id = 0;
  public createFromCustomerProduct(customer_product: ICustomerProduct) {
    this.customer_pn = customer_product.customer_pn || "";
    this.product_id = customer_product.product_id || 0;
    this.customer_id = customer_product.customer_id || 0;
  }
}

export class CustomerProductCreateRequest
  extends CustomerProductRequest
  implements ICustomerProductCreateRequest {}

export class CustomerProductUpdateRequest
  extends CustomerProductRequest
  implements ICustomerProductUpdateRequest
{
  public id = 0;
  public createFromCustomerProduct(customer_product: ICustomerProduct) {
    this.id = customer_product.id || 0;

    super.createFromCustomerProduct(customer_product);
  }
}
