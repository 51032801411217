import { RootState } from "@/types/index";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { LoginState } from "@/types/login";

const state: LoginState = {
  request: {
    email: "",
    password: ""
  }
};

export const login: Module<LoginState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
