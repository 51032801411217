
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Getter } from "vuex-class";
import { IUser } from "@/types/user";
@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  @Getter("auth/isAdmin")
  public isAdmin!: boolean;

  @Getter("auth/isUser")
  public isUser!: boolean;

  @Getter("auth/isInvoiceOnly")
  public isInvoiceOnly!: boolean;

  @Getter("auth/me")
  public user!: IUser;

  @Getter("isLoading")
  public isLoading!: boolean;

  public pageBack() {
    this.$router.go(-1);
  }

  public drawer = true;
  public mf_modal = false;
  public mf_user_name = process.env.VUE_APP_MF_USER_NAME;
  public mf_auth_url = process.env.VUE_APP_MF_AUTH_URL;
}
