import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { CustomerPriceTagState } from "@/types/customer_price_tag";
import { CustomerPriceTag } from "@/models/customer_price_tag";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<CustomerPriceTagState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.customer_price_tag || new CustomerPriceTag();
  },
  selectItem: state => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data.map(item => item.toSelectItem());
  },
  find: state => (id: number) => {
    return state.pagination
      ? state.pagination.data.find(customer => {
          return customer.id == id;
        })
      : new CustomerPriceTag();
  },
  findCustomerPn: state => (customer_pn: string) => {
    return state.pagination
      ? state.pagination.data.find(customer_price_tag => {
          return customer_price_tag.customer_pn == customer_pn;
        })
      : new CustomerPriceTag();
  },
  filterdSelectItem: state => (customer_id: number, price_tag_id: number) => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data
      .filter(
        item =>
          item.price_tag_id == price_tag_id && item.customer_id == customer_id
      )
      .map(item => item.toSelectItem());
  }
};
export default getters;
