import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { SupplierStaffState } from "@/types/supplier_staff";
import { SupplierStaff } from "@/models/supplier_staff";
import { Pagination } from "@/models/pagination";

const state: SupplierStaffState = {
  supplier_staff: new SupplierStaff(),
  pagination: new Pagination()
};

export const supplier_staff: Module<SupplierStaffState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
