import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { OrderState } from "@/types/order";
import { Order } from "@/models/order";
import { Pagination } from "@/models/pagination";
import Vue from "vue";

export const getters: GetterTree<OrderState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.order || new Order();
  },
  selectItem: state => {
    return state.pagination
      ? state.pagination.data.map(order => Order.toSelectItem(order))
      : [];
  },
  find: state => (id: number) => {
    return state.pagination
      ? state.pagination.data.find(order => {
          return order.id == id;
        })
      : new Order();
  },
  filterOrderSchedule: state => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data.filter(
      item =>
        item.order_status == Vue.prototype.$orderStatus.wait_storing ||
        item.order_status == Vue.prototype.$orderStatus.division_storing
    );
  }
};
export default getters;
