import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  IWarehouseListRequest,
  IWarehouseCreateRequest,
  IWarehouseUpdateRequest,
  WarehouseState,
  IWarehouse,
  IWarehouseRequest
} from "@/types/warehouse";

export const actions: ActionTree<WarehouseState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: IWarehouseListRequest) {
    try {
      const response = await axios.get<IPagination<IWarehouse>>(
        "/admin/warehouse",
        { params }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, warehouse_id: number) {
    try {
      const response = await axios.get<IWarehouse>(
        `/admin/warehouse/${warehouse_id}`
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { warehouse_id: number; params: IWarehouseUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IWarehouse>(
        `/admin/warehouse/${data.warehouse_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: IWarehouseCreateRequest) {
    try {
      const response = await axios.post<IWarehouse>("/admin/warehouse", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, warehouse_id: number) {
    try {
      await axios.delete(`/admin/warehouse/${warehouse_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // 二重登録チェック
  async adminIsUniqueName(state, params: IWarehouseRequest) {
    try {
      const response = await axios.get<boolean>(
        "/admin/warehouse/is_unique_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: IWarehouseListRequest) {
    try {
      const response = await axios.get<IPagination<IWarehouse>>(
        "/user/warehouse",
        { params }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, warehouse_id: number) {
    try {
      const response = await axios.get<IWarehouse>(
        `/user/warehouse/${warehouse_id}`
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { warehouse_id: number; params: IWarehouseUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IWarehouse>(
        `/user/warehouse/${data.warehouse_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: IWarehouseCreateRequest) {
    try {
      const response = await axios.post<IWarehouse>("/user/warehouse", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, warehouse_id: number) {
    try {
      await axios.delete(`/user/warehouse/${warehouse_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // 二重登録チェック
  async userIsUniqueName(state, params: IWarehouseRequest) {
    try {
      const response = await axios.get<boolean>(
        "/user/warehouse/is_unique_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  }
};

export default actions;
