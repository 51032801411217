import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { SupplierState } from "@/types/supplier";
import { Supplier } from "@/models/supplier";
import { Pagination } from "@/models/pagination";
import { Maker } from "@/models/maker";
import { User } from "@/models/user";
import { SupplierStaff } from "@/models/supplier_staff";

export const getters: GetterTree<SupplierState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.supplier || new Supplier();
  },
  selectItem: state => {
    if (!state.pagination) {
      return [];
    }

    return state.pagination.data.map(supplier => supplier.toSelectItem());
  },
  relationMakerSelectItem: state => {
    if (!state.supplier) {
      return [];
    }

    return state.supplier.maker.map(maker =>
      new Maker(maker).toRelationMakerSelectItem()
    );
  },
  relationUserSelectItem: state => {
    if (!state.supplier) {
      return [];
    }

    return state.supplier.user.map(user =>
      new User(user).toRelationUserSelectItem()
    );
  },
  relationSupplierStaffSelectItem: state => {
    if (!state.supplier) {
      return [];
    }

    return state.supplier.supplier_staff.map(supplier_staff =>
      new SupplierStaff(supplier_staff).toRelationSupplierStaffSelectItem()
    );
  },
  find: state => (id: number) => {
    return state.pagination
      ? state.pagination.data.find(supplier => {
          return supplier.id == id;
        })
      : new Supplier();
  }
};
export default getters;
