import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { BoosterState } from "@/types/booster";
import { Booster } from "@/models/booster";

export const getters: GetterTree<BoosterState, RootState> = {
  // selectItem: state => {
  //   if (!state.pagination) {
  //     return [];
  //   }
  booster: state => {
    return state.booster || new Booster();
  },

  buying_price: state => {
    return state.buying_price || 0;
  },
  selling_price: state => {
    return state.selling_price || 0;
  },
  gross_profit: state => {
    return state.gross_profit || 0;
  },
  gross_profit_rate: state => {
    return state.gross_profit_rate || 0;
  },
  monthly_calc: state => {
    return state.monthly_calc || [];
  },
  user_calc: state => {
    return state.user_calc || [];
  },
  supplier_calc: state => {
    return state.supplier_calc || [];
  },
  product_calc: state => {
    return state.product_calc || [];
  }
};
export default getters;
