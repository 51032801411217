import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { CustomerStaffState } from "@/types/customer_staff";
import { CustomerStaff } from "@/models/customer_staff";
import { Pagination } from "@/models/pagination";

const state: CustomerStaffState = {
  customer_staff: new CustomerStaff(),
  pagination: new Pagination()
};

export const customer_staff: Module<CustomerStaffState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
