import { IOrders } from "@/types/orders";
import { IPriceTagReceivedOrder } from "@/types/price_tag_received_order";
import { IProductReceivedOrder } from "@/types/product_received_order";
import { ProductReceivedOrder } from "./product_received_order";
import { PriceTagReceivedOrder } from "./price_tag_received_order";

export class Orders implements IOrders {
  public id;
  public order_no;
  public is_asap;
  public desired_delivery_date;
  public fixed_delivery_date;
  public delivery_memo;
  public destination;
  public subtotal;
  public amount;
  public product_received_orders: IProductReceivedOrder[];
  public price_tag_received_orders: IPriceTagReceivedOrder[];

  public constructor(params?: IOrders) {
    this.id = params ? params.id : 0;
    this.order_no = params ? params.order_no : "";
    this.is_asap = params ? params.is_asap : 0;
    this.desired_delivery_date = params ? params.desired_delivery_date : "";
    this.fixed_delivery_date = params ? params.fixed_delivery_date : "";
    this.delivery_memo = params ? params.delivery_memo : "";
    this.destination = params ? params.destination : "";
    this.subtotal = params ? params.subtotal : 0;
    this.amount = params ? params.amount : 0;
    this.product_received_orders = params
      ? params.product_received_orders
      : [new ProductReceivedOrder()];
    this.price_tag_received_orders = params
      ? params.price_tag_received_orders
      : [new PriceTagReceivedOrder()];
  }
}
