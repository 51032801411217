import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  IReceivedOrderListRequest,
  IReceivedOrderCreateRequest,
  IReceivedOrderUpdateRequest,
  ReceivedOrderState,
  IReceivedOrder
} from "@/types/received_order";

export const actions: ActionTree<ReceivedOrderState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: IReceivedOrderListRequest) {
    try {
      const response = await axios.get<IPagination<IReceivedOrder>>(
        "/admin/received_order",
        { params }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, received_order_id: number) {
    try {
      const response = await axios.get<{
        receivedOrderDetailViewModel: IReceivedOrder;
      }>(`/admin/received_order/${received_order_id}`);
      commit("single", response.data.receivedOrderDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { received_order_id: number; params: IReceivedOrderUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IReceivedOrder>(
        `/admin/received_order/${data.received_order_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: IReceivedOrderCreateRequest) {
    try {
      const response = await axios.post<IReceivedOrder>(
        "/admin/received_order",
        params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, received_order_id: number) {
    try {
      await axios.delete(`/admin/received_order/${received_order_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // 受注ステータスの自動切替
  async adminReceivedOrderIsShipmentComplete(
    { commit },
    received_order_id: number
  ) {
    try {
      await axios.put(
        `/admin/received_order/${received_order_id}/is_shipment_complete`
      );

      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  // 回答納品日更新
  async adminReceivedOrderUpdateFixedDeliveryDate(
    { commit },
    data: { received_order_id: number; fixed_delivery_date: string }
  ) {
    try {
      await axios.put(
        `/admin/received_order/${data.received_order_id}/update_fixed_delivery_date`,
        data
      );

      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  //事前出荷、ロット引当からのメモ登録編集
  async adminUpdateProductReceivedOrderMemo(
    { commit },
    product_received_orders: [{ id: number; memo: string | null }]
  ) {
    try {
      await axios.put(
        `/admin/received_order/update_product_received_order_memo`,
        { product_received_orders }
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //希望納品日、回答納品日修正
  async adminUpdateDeliveryDate(
    { commit },
    data: {
      received_order_id: number;
      params: {
        is_asap: number;
        desired_delivery_date: string | null;
        fixed_delivery_date: string | null;
      };
    }
  ) {
    try {
      await axios.put<boolean>(
        `/admin/received_order/${data.received_order_id}/update_delivery_date`,
        data.params
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: IReceivedOrderListRequest) {
    try {
      const response = await axios.get<IPagination<IReceivedOrder>>(
        "/user/received_order",
        { params }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, received_order_id: number) {
    try {
      const response = await axios.get<{
        receivedOrderDetailViewModel: IReceivedOrder;
      }>(`/user/received_order/${received_order_id}`);
      commit("single", response.data.receivedOrderDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { received_order_id: number; params: IReceivedOrderUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IReceivedOrder>(
        `/user/received_order/${data.received_order_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: IReceivedOrderCreateRequest) {
    try {
      const response = await axios.post<IReceivedOrder>(
        "/user/received_order",
        params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, received_order_id: number) {
    try {
      await axios.delete(`/user/received_order/${received_order_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // 受注ステータスの自動切替
  async userReceivedOrderIsShipmentComplete(
    { commit },
    received_order_id: number
  ) {
    try {
      await axios.put(
        `/user/received_order/${received_order_id}/is_shipment_complete`
      );

      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  // 回答納品日更新
  async userReceivedOrderUpdateFixedDeliveryDate(
    { commit },
    data: { received_order_id: number; fixed_delivery_date: string }
  ) {
    try {
      await axios.put(
        `/user/received_order/${data.received_order_id}/update_fixed_delivery_date`,
        data
      );

      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  //事前出荷、ロット引当からのメモ登録編集
  async userUpdateProductReceivedOrderMemo(
    { commit },
    product_received_orders: [{ id: number; memo: string | null }]
  ) {
    try {
      await axios.put(
        `/user/received_order/update_product_received_order_memo`,
        { product_received_orders }
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //希望納品日、回答納品日修正
  async userUpdateDeliveryDate(
    { commit },
    data: {
      received_order_id: number;
      params: {
        is_asap: number;
        desired_delivery_date: string | null;
        fixed_delivery_date: string | null;
      };
    }
  ) {
    try {
      await axios.put<boolean>(
        `/user/received_order/${data.received_order_id}/update_delivery_date`,
        data.params
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  }
};

export default actions;
