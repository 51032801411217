import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  IOrderListRequest,
  IOrderCreateRequest,
  IOrderUpdateRequest,
  OrderState,
  IOrder
} from "@/types/order";

export const actions: ActionTree<OrderState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: IOrderListRequest) {
    try {
      const response = await axios.get<IPagination<IOrder>>("/admin/order", {
        params
      });
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  //発注ステータス変更
  async adminChangeOrderStatus(
    { commit },
    data: { order_id: number; order_status: number }
  ) {
    try {
      const response = await axios.put<IOrder>(
        `/admin/order/${data.order_id}/change_order_status`,
        data
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  //入荷済み分納チェック
  async adminIsStoringComplete({ commit }, order_id: number) {
    try {
      await axios.put<boolean>(`/admin/order/${order_id}/is_storing_complete`);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  //希望納品日、回答納品日修正
  async adminUpdateDeliveryDate(
    { commit },
    data: {
      order_id: number;
      params: {
        is_asap: number;
        desired_delivery_date: string | null;
        fixed_delivery_date: string | null;
      };
    }
  ) {
    try {
      await axios.put<boolean>(
        `/admin/order/${data.order_id}/update_delivery_date`,
        data.params
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminGet({ commit }, order_id: number) {
    try {
      const response = await axios.get<{ orderDetailViewModel: IOrder }>(
        `/admin/order/${order_id}`
      );
      commit("single", response.data.orderDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { order_id: number; params: IOrderUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IOrder>(
        `/admin/order/${data.order_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: IOrderCreateRequest) {
    try {
      const response = await axios.post<IOrder>("/admin/order", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, order_id: number) {
    try {
      await axios.delete(`/admin/order/${order_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: IOrderListRequest) {
    try {
      const response = await axios.get<IPagination<IOrder>>("/user/order", {
        params
      });

      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  //発注ステータス変更
  async userChangeOrderStatus(
    { commit },
    data: { order_id: number; order_status: number }
  ) {
    try {
      const response = await axios.put<IOrder>(
        `/user/order/${data.order_id}/change_order_status`,
        data
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  //入荷済み分納チェック
  async userIsStoringComplete({ commit }, order_id: number) {
    try {
      await axios.put<boolean>(`/user/order/${order_id}/is_storing_complete`);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  //希望納品日、回答納品日修正
  async userUpdateDeliveryDate(
    { commit },
    data: {
      order_id: number;
      params: {
        is_asap: number;
        desired_delivery_date: string | null;
        fixed_delivery_date: string | null;
      };
    }
  ) {
    try {
      await axios.put<boolean>(
        `/user/order/${data.order_id}/update_delivery_date`,
        data.params
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userGet({ commit }, order_id: number) {
    try {
      const response = await axios.get<{ orderDetailViewModel: IOrder }>(
        `/user/order/${order_id}`
      );
      commit("single", response.data.orderDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { order_id: number; params: IOrderUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IOrder>(
        `/user/order/${data.order_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: IOrderCreateRequest) {
    try {
      const response = await axios.post<IOrder>("/user/order", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, order_id: number) {
    try {
      await axios.delete(`/user/order/${order_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  }
};

export default actions;
