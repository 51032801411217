import { ICustomerStaff, ICustomerStaffRequest } from "@/types/customer_staff";
import { ISelectItem } from "@/types";

export class CustomerStaff implements ICustomerStaff {
  public id;
  public name;
  public department;
  public position;
  public kana;
  public email;
  public tel;
  public tel2;
  public tel3;
  public customer_id;
  public branch_id;
  public branch_name;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: ICustomerStaff) {
    this.id = params ? params.id : 0;
    this.name = params ? params.name : "";
    this.department = params ? params.department : "";
    this.position = params ? params.position : "";
    this.kana = params ? params.kana : "";
    this.email = params ? params.email : "";
    this.tel = params ? params.tel : "";
    this.tel2 = params ? params.tel2 : "";
    this.tel3 = params ? params.tel3 : "";
    this.customer_id = params ? params.customer_id : 0;
    this.branch_id = params ? params.branch_id : 0;
    this.branch_name = params ? params.branch_name : "";
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.name,
      value: this.id
    };
  }
}

export class CustomerStaffRequest implements ICustomerStaffRequest {
  public name = "";
  public department = "";
  public position = "";
  public kana = "";
  public email = "";
  public tel = "";
  public tel2 = "";
  public tel3 = "";
  public customer_id = 0;
  public branch_id: number | null = null;

  public constructor(params?: ICustomerStaffRequest) {
    if (!params) {
      return;
    }

    this.name = params.name;
    this.department = params.department;
    this.position = params.position;
    this.kana = params.kana;
    this.email = params.email;
    this.tel = params.tel;
    this.tel2 = params.tel2;
    this.tel3 = params.tel3;
    this.customer_id = params.customer_id;
    this.branch_id = params.branch_id;
  }

  public createFromCustomerStaff(customerStaff: ICustomerStaff) {
    this.name = customerStaff.name || "";
    this.department = customerStaff.department || "";
    this.position = customerStaff.position || "";
    this.kana = customerStaff.kana || "";
    this.email = customerStaff.email || "";
    this.tel = customerStaff.tel || "";
    this.tel2 = customerStaff.tel2 || "";
    this.tel3 = customerStaff.tel3 || "";
    this.customer_id = customerStaff.customer_id || 0;
    this.branch_id = customerStaff.branch_id || null;
  }
}
