/* eslint-disable */

import { getCookie } from "tiny-cookie";
import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { UserState } from "@/types/user";

const getters: GetterTree<UserState, RootState> = {
  isAuthorized: state => state.user!.id !== 0,
  accessToken: () => getCookie("access_token") || null,
  refreshToken: () => getCookie("refresh_token") || null,

  getState: state => () => {
    return state;
  },
  isAdmin: state => {
    if (!state.user) {
      return false;
    }
    return state.user.role === 5;
  },
  isUser: state => {
    if (!state.user) {
      return false;
    }
    return state.user.role === 10;
  },
  isInvoiceOnly: state => {
    if (!state.user) {
      return false;
    }
    return state.user.role === 15;
  },
  me: state => state.user
};
export default getters;
