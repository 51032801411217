import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { PaymentTermState } from "@/types/payment_term";
import { ExceptPaymentTerm, PaymentTerm } from "@/models/payment_term";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<PaymentTermState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.payment_term || new PaymentTerm();
  },
  find: state => (id: number) => {
    if (!state.pagination) {
      return new PaymentTerm();
    }

    return state.pagination.data.find(item => {
      return item.id == id;
    });
  },
  except_payment_term: state => {
    return state.except_payment_term || new ExceptPaymentTerm();
  }
};
export default getters;
