import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  IUserListRequest,
  IUserCreateRequest,
  IUserUpdateRequest,
  UserState,
  IUser,
  IUserRequest
} from "@/types/user";

export const actions: ActionTree<UserState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: IUserListRequest) {
    try {
      const response = await axios.get<IPagination<IUser>>("/admin/user", {
        params
      });
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGetStaffList({ commit }, params: IUserListRequest) {
    try {
      const response = await axios.get<IPagination<IUser>>("/admin/user", {
        params
      });
      commit("staffPagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, user_id: number) {
    try {
      const response = await axios.get<IUser>(`/admin/user/${user_id}`);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { user_id: number; params: IUserUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IUser>(
        `/admin/user/${data.user_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: IUserCreateRequest) {
    try {
      const response = await axios.post<IUser>("/admin/user", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, user_id: number) {
    try {
      await axios.delete(`/admin/user/${user_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // イニシャル二重登録チェック
  async adminIsUniqueInitialName(state, params: IUserRequest) {
    try {
      const response = await axios.get<boolean>(
        "/admin/user/is_unique_initial_name",
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },

  // メールアドレス二重登録チェック
  async adminIsUniqueEmail(state, params: IUserRequest) {
    try {
      const response = await axios.get<boolean>("/admin/user/is_unique_email", {
        params
      });
      return response.data;
    } catch (error) {
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: IUserListRequest) {
    try {
      const response = await axios.get<IPagination<IUser>>("/user/user", {
        params
      });
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGetStaffList({ commit }, params: IUserListRequest) {
    try {
      const response = await axios.get<IPagination<IUser>>("/user/user", {
        params
      });
      commit("staffPagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, user_id: number) {
    try {
      const response = await axios.get<IUser>(`/user/user/${user_id}`);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { user_id: number; params: IUserUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IUser>(
        `/user/user/${data.user_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: IUserCreateRequest) {
    try {
      const response = await axios.post<IUser>("/user/user", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, user_id: number) {
    try {
      await axios.delete(`/user/user/${user_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // イニシャル二重登録チェック
  async userIsUniqueInitialName(state, params: IUserRequest) {
    try {
      const response = await axios.get<boolean>(
        "/user/user/is_unique_initial_name",
        {
          params
        }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },

  // メールアドレス二重登録チェック
  async userIsUniqueEmail(state, params: IUserRequest) {
    try {
      const response = await axios.get<boolean>("/user/user/is_unique_email", {
        params
      });
      return response.data;
    } catch (error) {
      return false;
    }
  }
};

export default actions;
