import { IMaker, IMakerRequest } from "@/types/maker";
import { ISelectItem } from "@/types";
import { ISupplier } from "@/types/supplier";

export class Maker implements IMaker {
  public id;
  public name;
  public kana;
  public short_name;
  public order_no_name;
  public supplier: ISupplier[];
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: IMaker) {
    this.id = params ? params.id : 0;
    this.name = params ? params.name : "";
    this.kana = params ? params.kana : "";
    this.short_name = params ? params.short_name : "";
    this.order_no_name = params ? params.order_no_name : "";
    this.supplier = params ? params.supplier : [];
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.name,
      value: this.id
    };
  }

  public toRelationMakerSelectItem(): ISelectItem {
    return {
      text: this.name,
      value: this.id
    };
  }
}

export class MakerRequest implements IMakerRequest {
  public name = "";
  public kana = "";
  public short_name = "";
  public order_no_name = "";
  public supplier: ISupplier[] = [];

  public constructor(params?: IMakerRequest) {
    if (!params) {
      return;
    }

    this.name = params.name;
    this.kana = params.kana;
    this.short_name = params.short_name;
    this.order_no_name = params.order_no_name;
    this.supplier = params.supplier;
  }

  public createFromMaker(maker: IMaker): void {
    this.name = maker.name || "";
    this.kana = maker.kana || "";
    this.short_name = maker.short_name || "";
    this.order_no_name = maker.order_no_name || "";
    this.supplier = maker.supplier || [];
  }
}
