import { MutationTree } from "vuex";
import { SupplierState, ISupplier } from "@/types/supplier";
import { IPagination } from "@/types";
import { Pagination } from "@/models/pagination";
import { Supplier } from "@/models/supplier";

const mutations: MutationTree<SupplierState> = {
  pagination: (state, items: IPagination<ISupplier>) => {
    const params: IPagination<Supplier> = {
      current_page: items.current_page,
      first_page_url: items.first_page_url,
      from: items.from,
      last_page: items.last_page,
      last_page_url: items.last_page_url,
      next_page_url: items.next_page_url,
      path: items.path,
      per_page: items.per_page,
      prev_page_url: items.prev_page_url,
      to: items.to,
      total: items.total,
      data: items.data.map(item => new Supplier(item))
    };

    state.pagination = new Pagination(params);
  },
  single: (state, item: ISupplier) => {
    state.supplier = new Supplier(item);
  },
  clear: state => {
    state.supplier = new Supplier();
    state.pagination = new Pagination();
  }
};

export default mutations;
