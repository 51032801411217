import Vue from "vue";
import { ActionTree } from "vuex";
import { RootState } from "@/types";
import axios from "axios";
import {
  IQuantityHistory,
  QuantityHistoryState,
  IQuantityHistoryListRequest,
  IQuantityHistoryChangeRequest
} from "@/types/quantity_history";

export const actions: ActionTree<QuantityHistoryState, RootState> = {
  //adminメソッド
  // 出荷商品に紐づく受注、出荷、倉庫移動、入荷のデータを取得
  async adminGetList({ commit }, params: IQuantityHistoryListRequest) {
    try {
      const response = await axios.get<IQuantityHistory>(
        "/admin/batch_change/get_data",
        {
          params
        }
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },
  // 受注、出荷、倉庫移動、入荷の数量を変更
  async adminUpdate({ commit }, params: IQuantityHistoryChangeRequest) {
    try {
      await axios.put<IQuantityHistory>(
        `/admin/batch_change/update_quantity`,
        params
      );
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  }
};

export default actions;
