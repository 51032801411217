import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { PaymentMethodState } from "@/types/payment_method";
import { PaymentMethod } from "@/models/payment_method";
import { Pagination } from "@/models/pagination";

const state: PaymentMethodState = {
  payment_method: new PaymentMethod(),
  pagination: new Pagination()
};

export const payment_method: Module<PaymentMethodState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
