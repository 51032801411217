import { MutationTree } from "vuex";
import { ZipaddressState, IZipaddress } from "@/types/zipaddress";
import { Zipaddress } from "@/models/zipaddress";

const mutations: MutationTree<ZipaddressState> = {
  single: (state, item: IZipaddress[]) => {
    state.zipaddress = new Zipaddress(item[0]);
  },
  clear: state => {
    state.zipaddress = new Zipaddress();
  }
};

export default mutations;
