import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { PaymentTermState } from "@/types/payment_term";
import { ExceptPaymentTerm, PaymentTerm } from "@/models/payment_term";
import { Pagination } from "@/models/pagination";

const state: PaymentTermState = {
  payment_term: new PaymentTerm(),
  except_payment_term: new ExceptPaymentTerm(),
  pagination: new Pagination()
};

export const payment_term: Module<PaymentTermState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
