import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { MovementStoringState } from "@/types/movement_storing";
import { MovementStoring } from "@/models/movement_storing";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<MovementStoringState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.movement_storing || new MovementStoring();
  },
  find: state => (id: number) => {
    if (!state.pagination) {
      return new MovementStoring();
    }

    return state.pagination.data.find(item => {
      return item.id == id;
    });
  }
};
export default getters;
