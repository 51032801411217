import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { MakerState } from "@/types/maker";
import { Maker } from "@/models/maker";
import { Pagination } from "@/models/pagination";
import { Supplier } from "@/models/supplier";

export const getters: GetterTree<MakerState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.maker || new Maker();
  },
  selectItem: state => {
    if (!state.pagination) {
      return [];
    }

    return state.pagination.data.map(item => item.toSelectItem());
  },
  relationSupplierSelectItem: state => {
    if (!state.maker) {
      return [];
    }

    return state.maker.supplier.map(supplier =>
      new Supplier(supplier).toRelationSupplierSelectItem()
    );
  },
  find: state => (id: number) => {
    if (!state.pagination) {
      return new Maker();
    }

    return state.pagination.data.find(item => {
      return item.id == id;
    });
  }
};
export default getters;
