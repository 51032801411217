import axios from "axios";
import { saveAs } from "file-saver";
import { Module } from "vuex";
import { RootState } from "@/types";
import { ReceivedOrderState } from "@/types/received_order";
import { IInvoicePrintRequest, IShipSlipRequest } from "@/types/ship_slip";
import { IShipmentListRequest } from "@/types/shipment";
import { IOrderPrintRequest } from "@/types/order";
import qs from "qs";

/*
 * Interface
 */
export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    //adminメソッド
    adminPDF: async (
      { commit },
      data: {
        params: IShipSlipRequest;
        order_no: string;
        customer_name: string;
      }
    ) => {
      try {
        const response = await axios.post(`/admin/pdf`, data.params, {
          responseType: "blob",
          headers: { Accept: "application/pdf" }
        });
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(
          blob,
          `納品書_顧客注文番号(${data.order_no})_${data.customer_name}様.pdf`
        );
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    adminPrintInvoiceSlipA5: async (
      { commit },
      data: {
        invoice_id: number;
        params: IInvoicePrintRequest;
        invoice_no: string;
        customer_name: string;
        invoice_date: string;
      }
    ) => {
      try {
        const response = await axios.post(
          `/admin/invoice/${data.invoice_id}/invoice_slip_A5`,
          data.params,
          {
            responseType: "blob",
            headers: {
              Accept: "application/pdf"
            }
          }
        );

        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(
          blob,
          `I-${data.invoice_no}_${data.invoice_date}_${data.customer_name}様.pdf`
        );

        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    adminPrintInvoiceSlipA4: async (
      { commit },
      data: {
        invoice_id: number;
        params: IInvoicePrintRequest;
        invoice_no: string;
        customer_name: string;
        invoice_date: string;
      }
    ) => {
      try {
        const response = await axios.post(
          `/admin/invoice/${data.invoice_id}/invoice_slip_A4`,
          data.params,
          {
            responseType: "blob",
            headers: {
              Accept: "application/pdf"
            }
          }
        );

        const blob = new Blob([response.data], { type: "application/pdf" });
        if (data.params.sheet_type == "slip") {
          saveAs(
            blob,
            `P-${data.invoice_no}_${data.invoice_date}_${data.customer_name}様.pdf`
          );
        } else {
          saveAs(
            blob,
            `I-${data.invoice_no}_${data.invoice_date}_${data.customer_name}様.pdf`
          );
        }

        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    adminShipmentSchedulePDF: async (
      { commit },
      params: IShipmentListRequest
    ) => {
      try {
        const paramsSerializer = (params: IShipmentListRequest) =>
          qs.stringify(params);
        const response = await axios.get(`/admin/shipmentSchedulePDF`, {
          paramsSerializer,
          params,
          responseType: "blob",
          headers: { Accept: "application/pdf" }
        });
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(blob, `出荷スケジュール.pdf`);
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    adminOrderHomePDF: async (
      { commit },
      data: {
        params: IOrderPrintRequest;
        order_no: string;
      }
    ) => {
      try {
        const response = await axios.post(`/admin/orderHomePDF`, data.params, {
          responseType: "blob",
          headers: { Accept: "application/pdf" }
        });
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(blob, `${data.order_no}.pdf`);
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    adminOrderAwayPDF: async (
      { commit },
      data: {
        params: IOrderPrintRequest;
        order_no: string;
      }
    ) => {
      try {
        const response = await axios.post(`/admin/orderAwayPDF`, data.params, {
          responseType: "blob",
          headers: { Accept: "application/pdf" }
        });
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(blob, `${data.order_no}.pdf`);
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    //nominalUserメソッド
    userPDF: async (
      { commit },
      data: {
        params: IShipSlipRequest;
        order_no: string;
        customer_name: string;
      }
    ) => {
      try {
        const response = await axios.post(`/user/pdf`, data.params, {
          responseType: "blob",
          headers: { Accept: "application/pdf" }
        });
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(
          blob,
          `納品書_顧客注文番号(${data.order_no})_${data.customer_name}様.pdf`
        );
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    userPrintInvoiceSlipA5: async (
      { commit },
      data: {
        invoice_id: number;
        params: IInvoicePrintRequest;
        invoice_no: string;
        customer_name: string;
        invoice_date: string;
      }
    ) => {
      try {
        const response = await axios.post(
          `/user/invoice/${data.invoice_id}/invoice_slip_A5`,
          data.params,
          {
            responseType: "blob",
            headers: {
              Accept: "application/pdf"
            }
          }
        );

        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(
          blob,
          `I-${data.invoice_no}_${data.invoice_date}_${data.customer_name}様.pdf`
        );

        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    userPrintInvoiceSlipA4: async (
      { commit },
      data: {
        invoice_id: number;
        params: IInvoicePrintRequest;
        invoice_no: string;
        customer_name: string;
        invoice_date: string;
      }
    ) => {
      try {
        const response = await axios.post(
          `/user/invoice/${data.invoice_id}/invoice_slip_A4`,
          data.params,
          {
            responseType: "blob",
            headers: {
              Accept: "application/pdf"
            }
          }
        );

        const blob = new Blob([response.data], { type: "application/pdf" });
        if (data.params.sheet_type == "slip") {
          saveAs(
            blob,
            `P-${data.invoice_no}_${data.invoice_date}_${data.customer_name}様.pdf`
          );
        } else {
          saveAs(
            blob,
            `I-${data.invoice_no}_${data.invoice_date}_${data.customer_name}様.pdf`
          );
        }

        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    userShipmentSchedulePDF: async (
      { commit },
      params: IShipmentListRequest
    ) => {
      try {
        const paramsSerializer = (params: IShipmentListRequest) =>
          qs.stringify(params);
        const response = await axios.get(`/user/shipmentSchedulePDF`, {
          paramsSerializer,
          params,
          responseType: "blob",
          headers: { Accept: "application/pdf" }
        });
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(blob, `出荷スケジュール.pdf`);
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    userOrderHomePDF: async (
      { commit },
      data: {
        params: IOrderPrintRequest;
        order_no: string;
      }
    ) => {
      try {
        const response = await axios.post(`/user/orderHomePDF`, data.params, {
          responseType: "blob",
          headers: { Accept: "application/pdf" }
        });
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(blob, `${data.order_no}.pdf`);
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    userOrderAwayPDF: async (
      { commit },
      data: {
        params: IOrderPrintRequest;
        order_no: string;
      }
    ) => {
      try {
        const response = await axios.post(`/user/orderAwayPDF`, data.params, {
          responseType: "blob",
          headers: { Accept: "application/pdf" }
        });
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(blob, `${data.order_no}.pdf`);
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    }
  }
} as Module<ReceivedOrderState, RootState>;
