import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { PriceTagState } from "@/types/price_tag";
import { PriceTag } from "@/models/price_tag";
import { Pagination } from "@/models/pagination";

const state: PriceTagState = {
  price_tag: new PriceTag(),
  pagination: new Pagination()
};

export const price_tag: Module<PriceTagState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
