import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { LotState } from "@/types/lot";
import { Lot } from "@/models/lot";
import { Pagination } from "@/models/pagination";

const state: LotState = {
  lot: new Lot(),
  lots: [],
  pagination: new Pagination()
};

export const lot: Module<LotState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
