import { MutationTree } from "vuex";
import { ProductStockState } from "@/types/product_stock";

const mutations: MutationTree<ProductStockState> = {
  product_stock: (
    state,
    item: { all_quantity: number; stock_by_warehouse: [] }
  ) => {
    state.all_quantity = item.all_quantity;
    state.stock_by_warehouse = item.stock_by_warehouse;
  },
  clear: state => {
    state.all_quantity = 0;
    state.stock_by_warehouse = [];
  }
};

export default mutations;
