import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { TaxState } from "@/types/tax";
import { Tax } from "@/models/tax";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<TaxState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.tax || new Tax();
  },
  selectItem: state => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data.map(item => item.toSelectItem());
  },
  find: state => (id: number) => {
    if (!state.pagination) {
      return new Tax();
    }

    return state.pagination.data.find(item => {
      return item.id == id;
    });
  }
};
export default getters;
