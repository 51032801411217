import { IPriceTagReceivedOrder } from "@/types/price_tag_received_order";

export class PriceTagReceivedOrder implements IPriceTagReceivedOrder {
  public id;
  public price;
  public price_tag_no;
  public price_tag_id;
  public customer_price_tag_id;
  public received_order_id;

  public constructor(params?: IPriceTagReceivedOrder) {
    this.id = params ? params.id : 0;
    this.price = params ? params.price : null;
    this.price_tag_no = params ? params.price_tag_no : "";
    this.price_tag_id = params ? params.price_tag_id : 0;
    this.customer_price_tag_id = params ? params.customer_price_tag_id : null;
    this.received_order_id = params ? params.received_order_id : 0;
  }

  public createFromPriceTagReceivedOrder(
    priceTagReceivedOrder: IPriceTagReceivedOrder
  ) {
    if (priceTagReceivedOrder.id) {
      this.id = priceTagReceivedOrder.id;
    }
    this.price = priceTagReceivedOrder.price
      ? Number(Number(priceTagReceivedOrder.price).toFixed(2))
      : null;
    this.price_tag_no = priceTagReceivedOrder.price_tag_no;
    this.price_tag_id = priceTagReceivedOrder.price_tag_id;
    this.customer_price_tag_id = priceTagReceivedOrder.customer_price_tag_id;
  }
}
