import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { QuantityHistoryState } from "@/types/quantity_history";
import { QuantityHistory } from "@/models/quantity_history";

const state: QuantityHistoryState = {
  quantity_history: new QuantityHistory()
};

export const quantity_history: Module<QuantityHistoryState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
