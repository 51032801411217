import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { ShipmentState } from "@/types/shipment";
import { Shipment } from "@/models/shipment";
import { Pagination } from "@/models/pagination";

const state: ShipmentState = {
  shipment: new Shipment(),
  pagination: new Pagination(),
  calc_shipment: new Shipment()
};

export const shipment: Module<ShipmentState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
