import { MutationTree } from "vuex";
import { WarehouseState, IWarehouse } from "@/types/warehouse";
import { IPagination } from "@/types";
import { Pagination } from "@/models/pagination";
import { Warehouse } from "@/models/warehouse";

const mutations: MutationTree<WarehouseState> = {
  pagination: (state, warehouses: IPagination<IWarehouse>) => {
    const params: IPagination<Warehouse> = {
      current_page: warehouses.current_page,
      first_page_url: warehouses.first_page_url,
      from: warehouses.from,
      last_page: warehouses.last_page,
      last_page_url: warehouses.last_page_url,
      next_page_url: warehouses.next_page_url,
      path: warehouses.path,
      per_page: warehouses.per_page,
      prev_page_url: warehouses.prev_page_url,
      to: warehouses.to,
      total: warehouses.total,
      data: warehouses.data.map(warehouse => new Warehouse(warehouse))
    };

    state.pagination = new Pagination(params);
  },
  single: (state, item: IWarehouse) => {
    state.warehouse = new Warehouse(item);
  },
  clear: state => {
    state.warehouse = new Warehouse();
    state.pagination = new Pagination();
  }
};

export default mutations;
