import { MutationTree } from "vuex";
import { ProductOrderStatusState } from "@/types/product_order_status";

const mutations: MutationTree<ProductOrderStatusState> = {
  product_order_status: (
    state,
    item: { order_product: []; product_received_order: [] }
  ) => {
    state.order_product = item.order_product;
    state.product_received_order = item.product_received_order;
  },
  clear: state => {
    state.order_product = [];
    state.product_received_order = [];
  }
};

export default mutations;
