import axios from "axios";
import { ActionTree } from "vuex";
import { RootState } from "@/types";
import {
  IMonthlyCountStock,
  IMonthlyCountStockListRequest,
  MonthlyCountStockState
} from "@/types/monthly_count_stock";

export const actions: ActionTree<MonthlyCountStockState, RootState> = {
  //adminメソッド
  async adminGetMonthlyCountStock(
    { commit },
    params: IMonthlyCountStockListRequest
  ) {
    try {
      const response = await axios.get<{ viewModel: IMonthlyCountStock }>(
        "/admin/monthly_count",
        {
          params
        }
      );
      commit("monthly_count_stock", response.data.viewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGetMonthlyCountCalculation(
    { commit },
    params: IMonthlyCountStockListRequest
  ) {
    try {
      const response = await axios.get<{ viewModel: IMonthlyCountStock }>(
        "/admin/monthly_calculation",
        {
          params
        }
      );
      commit("monthly_count_stock", response.data.viewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  //nominalUserメソッド
  async userGetMonthlyCountStock(
    { commit },
    params: IMonthlyCountStockListRequest
  ) {
    try {
      const response = await axios.get<{ viewModel: IMonthlyCountStock }>(
        "/user/monthly_count",
        {
          params
        }
      );
      commit("monthly_count_stock", response.data.viewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGetMonthlyCountCalculation(
    { commit },
    params: IMonthlyCountStockListRequest
  ) {
    try {
      const response = await axios.get<{ viewModel: IMonthlyCountStock }>(
        "/user/monthly_calculation",
        {
          params
        }
      );
      commit("monthly_count_stock", response.data.viewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  }
};

export default actions;
