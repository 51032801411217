import { IPaymentMethod, IPaymentMethodRequest } from "@/types/payment_method";
import { ISelectItem } from "@/types";

export class PaymentMethod implements IPaymentMethod {
  public id;
  public name;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: IPaymentMethod) {
    this.id = params ? params.id : 0;
    this.name = params ? params.name : "";
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.name,
      value: this.id
    };
  }
}

export class PaymentMethodRequest implements IPaymentMethodRequest {
  public name = "";

  public constructor(params?: IPaymentMethodRequest) {
    if (!params) {
      return;
    }

    this.name = params.name;
  }

  public createFromPaymentMethod(payment_method: IPaymentMethod): void {
    this.name = payment_method.name;
  }
}
