import {
  ICustomerPn,
  ICustomer,
  ICustomerRequest,
  ISalesStaff
} from "@/types/customer";
import { ISelectItem } from "@/types";
import { IBranch } from "@/types/branch";

export class Customer implements ICustomer {
  public id;
  public customer_mfid;
  public uuid;
  public name;
  public kana;
  public short_name;
  public name_suffix;
  public division;
  public payment_term;
  public cutoff_day;
  public registration_status;
  public registration_no;
  public schedule_date;
  public product: ICustomerPn[] = [];
  public user: ISalesStaff[] = [];
  public users: number[] = [];
  public branch: IBranch[] = [];
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: ICustomer) {
    this.id = params ? params.id : 0;
    this.customer_mfid = params ? params.customer_mfid : "";
    this.uuid = params ? params.uuid : "";
    this.name = params ? params.name : "";
    this.kana = params ? params.kana : "";
    this.short_name = params ? params.short_name : "";
    this.name_suffix = params ? params.name_suffix : "";
    this.division = params ? params.division : "";
    this.payment_term = params ? params.payment_term : "";
    this.cutoff_day = params ? params.cutoff_day : null;
    this.registration_status = params ? params.registration_status : 0;
    this.registration_no = params ? params.registration_no : "";
    this.schedule_date = params ? params.schedule_date : "";
    this.product = params ? params.product : [];
    this.user = params ? params.user : [];
    this.users = params ? params.users : [];
    this.branch = params ? params.branch : [];
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.name,
      value: this.id
    };
  }
}

export class CustomerRequest implements ICustomerRequest {
  public customer_mfid = "";
  public uuid = "";
  public name = "";
  public kana = "";
  public short_name = "";
  public name_suffix = "";
  public division = "";
  public payment_term = "";
  public cutoff_day: number | null = null;
  public registration_status = 1;
  public registration_no = "";
  public schedule_date = "";
  public users: number[] = [];

  public createFromCustomer(customer: ICustomer) {
    this.customer_mfid = customer.customer_mfid || "";
    this.uuid = customer.uuid || "";
    this.name = customer.name || "";
    this.kana = customer.kana || "";
    this.short_name = customer.short_name || "";
    this.name_suffix = customer.name_suffix || "";
    this.division = customer.division || "";
    this.payment_term = customer.payment_term || "";
    this.cutoff_day = customer.cutoff_day || null;
    this.registration_status = customer.registration_status || 1;
    this.registration_no = customer.registration_no || "";
    this.schedule_date = customer.schedule_date || "";
    this.users = customer.users || [];
  }
}
