import axios from "axios";
import { ActionTree } from "vuex";
import { RootState } from "@/types";
import { IBooster, IBoosterListRequest, BoosterState } from "@/types/booster";

export const actions: ActionTree<BoosterState, RootState> = {
  //adminメソッド
  async adminGetBooster({ commit }, params: IBoosterListRequest) {
    try {
      const response = await axios.get<{ viewModel: IBooster }>(
        "/admin/booster",
        {
          params
        }
      );
      // commit("booster", response.data.viewModel);
      commit("single", response.data.viewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  //nominalUserメソッド
  async userGetBooster({ commit }, params: IBoosterListRequest) {
    try {
      const response = await axios.get<{ viewModel: IBooster }>(
        "/user/booster",
        {
          params
        }
      );
      // commit("booster", response.data.viewModel);
      commit("single", response.data.viewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  }
};

export default actions;
