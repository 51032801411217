import {
  IInvoice,
  IInvoiceCreateRequest,
  IInvoiceRequest,
  IInvoiceUpdateRequest,
  IInvoiceCalculation
} from "@/types/invoice";
import { IInvoicePriceTag } from "@/types/invoice_price_tag";
import { IInvoiceProduct } from "@/types/invoice_product";
import { IPaymentMethod } from "@/types/payment_method";
import { IShipment } from "@/types/shipment";
import { Branch } from "./branch";
import { Customer } from "./customer";
import { InvoicePriceTag } from "./invoice_price_tag";

export class Invoice implements IInvoice {
  public id;
  public invoice_no;
  public payment_status;
  public invoice_date;
  public payment_date;
  public paid_date;
  public tax_name;
  public tax_rate;
  public our_memo;
  public customer_id;
  public branch_id;
  public sales_staff_id;
  public user_id;
  public customer;
  public branch;
  public payment_method: IPaymentMethod[];
  public invoice_price_tag: IInvoicePriceTag[];
  public shipment: IShipment[];
  public invoice_product: IInvoiceProduct[];
  public created_at;
  public updated_at;
  public deleted_at;
  public user_name;
  public sales_staff_name;
  public sell_subtotal;
  public misc_expense;
  public price_tag_sum;
  public needs_printing;
  public is_printed;

  public constructor(params?: IInvoice) {
    this.id = params ? params.id : 0;
    this.invoice_no = params ? params.invoice_no : "";
    this.payment_status = params ? params.payment_status : 0;
    this.invoice_date = params ? params.invoice_date : "";
    this.payment_date = params ? params.payment_date : "";
    this.paid_date = params ? params.paid_date : "";
    this.tax_name = params ? params.tax_name : "";
    this.tax_rate = params ? params.tax_rate : 0;
    this.our_memo = params ? params.our_memo : "";
    this.customer_id = params ? params.customer_id : 0;
    this.branch_id = params ? params.branch_id : 0;
    this.sales_staff_id = params ? params.sales_staff_id : 0;
    this.user_id = params ? params.user_id : 0;
    this.customer = params ? params.customer : new Customer();
    this.branch = params ? params.branch : new Branch();
    this.payment_method = params ? params.payment_method : [];
    this.invoice_price_tag = params ? params.invoice_price_tag : [];
    this.shipment = params ? params.shipment : [];
    this.invoice_product = params ? params.invoice_product : [];
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
    this.user_name = params ? params.user_name : "";
    this.sales_staff_name = params ? params.sales_staff_name : "";
    this.sell_subtotal = params ? params.sell_subtotal : 0;
    this.misc_expense = params ? params.misc_expense : 0;
    this.price_tag_sum = params ? params.price_tag_sum : 0;
    this.needs_printing = params ? params.needs_printing : true;
    this.is_printed = params ? params.is_printed : false;
  }
}

export class InvoiceRequest implements IInvoiceRequest {
  public payment_status = 0;
  public invoice_date = "";
  public payment_date = "";
  public paid_date: string | null = null;
  public tax_name = "";
  public tax_rate = 0;
  public our_memo: string | null = null;
  public customer_id = 0;
  public branch_id: number | null = null;
  public sales_staff_id: number | null = null;
  public user_id = 0;
  public needs_printing = true;
  public payment_method: number[] = [];
  public price_tag: IInvoicePriceTag[] = [new InvoicePriceTag()];
  public shipment_ids: number[] = [];
  public invoice_product: IInvoiceProduct[] = [];

  public createFromInvoice(params: IInvoice): void {
    this.payment_status = params.payment_status;
    this.invoice_date = params.invoice_date;
    this.payment_date = params.payment_date;
    this.paid_date = params.paid_date;
    this.tax_name = params.tax_name;
    this.tax_rate = Number(Number(params.tax_rate).toFixed(2));
    this.our_memo = params.our_memo;
    this.customer_id = params.customer_id;
    this.branch_id = params.branch_id;
    this.sales_staff_id = params.sales_staff_id;
    this.user_id = params.user_id;
    this.needs_printing = params.needs_printing;
    this.payment_method = params.payment_method.map(payment_method => {
      return payment_method.id;
    });
    this.price_tag = params.invoice_price_tag.length
      ? params.invoice_price_tag
      : [new InvoicePriceTag()];
    this.shipment_ids = params.shipment.map(shipment => {
      return shipment.id;
    });
    this.invoice_product = params.invoice_product;
  }
}

export class InvoiceCreateRequest
  extends InvoiceRequest
  implements IInvoiceCreateRequest {}

export class InvoiceUpdateRequest
  extends InvoiceRequest
  implements IInvoiceUpdateRequest {}

export class InvoiceCalculation implements IInvoiceCalculation {
  public invoices_count = 0;
  public buying_tax_excluded = 0;
  public profit_tax_excluded = 0;
  public billing_including_tax = 0;
  public profit_rate = 0;

  public constructor(params?: IInvoiceCalculation) {
    this.invoices_count = params ? params.invoices_count : 0;
    this.buying_tax_excluded = params ? params.buying_tax_excluded : 0;
    this.profit_tax_excluded = params ? params.profit_tax_excluded : 0;
    this.billing_including_tax = params ? params.billing_including_tax : 0;
    this.profit_rate = params ? params.profit_rate : 0;
  }
}
