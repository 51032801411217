import {
  IQuantityHistory,
  IQuantityHistoryChangeRequest
} from "@/types/quantity_history";
import { ProductReceivedOrder } from "./product_received_order";
import { ProductShipment } from "./product_shipment";
import { Movement } from "./movement";
import { IProductReceivedOrder } from "@/types/product_received_order";
import { IProductShipment } from "@/types/product_shipment";
import { IMovement } from "@/types/movement";
import { MovementStoring } from "./movement_storing";
import { OrderProduct } from "./order_product";
import { IMovementStoring } from "@/types/movement_storing";

export class QuantityHistory implements IQuantityHistory {
  public product_received_order;
  public product_shipment;
  public movements: IMovement[];
  public storing;
  public order_product;

  public constructor(params?: IQuantityHistory) {
    this.product_received_order = params
      ? params.product_received_order
      : new ProductReceivedOrder();
    this.product_shipment = params
      ? params.product_shipment
      : new ProductShipment();
    this.movements = params ? params.movements : [];
    this.storing = params ? params.storing : new MovementStoring();
    this.order_product = params ? params.order_product : new OrderProduct();
  }
}

export class QuantityHistoryChangeRequest
  implements IQuantityHistoryChangeRequest
{
  product_received_order: IProductReceivedOrder;
  product_shipment: IProductShipment;
  movements: IMovement[];
  storing: IMovementStoring;

  public constructor(params?: IQuantityHistory) {
    this.product_received_order = params
      ? params.product_received_order
      : new ProductReceivedOrder();
    this.product_shipment = params
      ? params.product_shipment
      : new ProductShipment();
    if (params && params.movements) {
      this.movements = params.movements.map(movement => new Movement(movement));
    } else {
      this.movements = [];
    }
    this.storing = params
      ? new MovementStoring(params.storing)
      : new MovementStoring();
  }
}
