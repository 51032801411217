import {
  ICustomerPriceTag,
  ICustomerPriceTagCreateRequest,
  ICustomerPriceTagRequest,
  ICustomerPriceTagUpdateRequest
} from "@/types/customer_price_tag";
import { ISelectItem } from "@/types";

export class CustomerPriceTag implements ICustomerPriceTag {
  public id;
  public customer_pn;
  public customer_name;
  public customer_id;
  public price_tag_name;
  public price_tag_id;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: ICustomerPriceTag) {
    this.id = params ? params.id : 0;
    this.customer_pn = params ? params.customer_pn : "";
    this.customer_name = params ? params.customer_name : "";
    this.customer_id = params ? params.customer_id : 0;
    this.price_tag_name = params ? params.price_tag_name : "";
    this.price_tag_id = params ? params.price_tag_id : 0;
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.customer_pn,
      value: this.id
    };
  }
}

export class CustomerPriceTagRequest implements ICustomerPriceTagRequest {
  public customer_pn = "";
  public customer_id = 0;
  public price_tag_id = 0;
  public createFromCustomerPriceTag(customer_price_tag: ICustomerPriceTag) {
    this.customer_pn = customer_price_tag.customer_pn || "";
    this.customer_id = customer_price_tag.customer_id || 0;
    this.price_tag_id = customer_price_tag.price_tag_id || 0;
  }
}

export class CustomerPriceTagCreateRequest
  extends CustomerPriceTagRequest
  implements ICustomerPriceTagCreateRequest {}

export class CustomerPriceTagUpdateRequest
  extends CustomerPriceTagRequest
  implements ICustomerPriceTagUpdateRequest
{
  public id = 0;
  public createFromCustomerPriceTag(customer_price_tag: ICustomerPriceTag) {
    this.id = customer_price_tag.id || 0;

    super.createFromCustomerPriceTag(customer_price_tag);
  }
}
