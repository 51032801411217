import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { ZipaddressState } from "@/types/zipaddress";
import { Zipaddress } from "@/models/zipaddress";

const state: ZipaddressState = {
  zipaddress: new Zipaddress()
};

export const zipaddress: Module<ZipaddressState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
