import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { ReceivedOrdersManagementState } from "@/types/received_orders_management";
import { ReceivedOrdersManagement } from "@/models/received_orders_management";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<ReceivedOrdersManagementState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.received_orders_management || new ReceivedOrdersManagement();
  },
  selectItem: state => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data.map(item => item.toSelectItem());
  },
  find: state => (id: number) => {
    if (!state.pagination) {
      return new ReceivedOrdersManagement();
    }

    return state.pagination.data.find(item => {
      return item.id == id;
    });
  }
};
export default getters;
