import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { MovementState } from "@/types/movement";
import { Movement } from "@/models/movement";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<MovementState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.movement || new Movement();
  },
  find: state => (id: number) => {
    if (!state.pagination) {
      return new Movement();
    }

    return state.pagination.data.find(item => {
      return item.id == id;
    });
  }
};
export default getters;
