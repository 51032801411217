import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { CustomerPriceTagState } from "@/types/customer_price_tag";
import { CustomerPriceTag } from "@/models/customer_price_tag";
import { Pagination } from "@/models/pagination";

const state: CustomerPriceTagState = {
  customer_price_tag: new CustomerPriceTag(),
  pagination: new Pagination()
};

export const customer_price_tag: Module<CustomerPriceTagState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
