import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { MoneyforwardState } from "@/types/moneyforward";
import { Moneyforward } from "@/models/moneyforward";

export const getters: GetterTree<MoneyforwardState, RootState> = {
  single: state => {
    return state.moneyforward || new Moneyforward();
  }
};
export default getters;
