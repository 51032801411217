import { MutationTree } from "vuex";
import { LotState, ILot } from "@/types/lot";
import { IPagination } from "@/types";
import { Pagination } from "@/models/pagination";
import { Lot } from "@/models/lot";

const mutations: MutationTree<LotState> = {
  pagination: (state, items: IPagination<ILot>) => {
    const params: IPagination<Lot> = {
      current_page: items.current_page,
      first_page_url: items.first_page_url,
      from: items.from,
      last_page: items.last_page,
      last_page_url: items.last_page_url,
      next_page_url: items.next_page_url,
      path: items.path,
      per_page: items.per_page,
      prev_page_url: items.prev_page_url,
      to: items.to,
      total: items.total,
      data: items.data.map(item => new Lot(item))
    };

    state.pagination = new Pagination(params);
  },
  paginationSegment: (state, items: IPagination<ILot>) => {
    if (!state.pagination) {
      return;
    }
    const lots = items.data.map(item => new Lot(item));
    state.pagination.data = state.pagination.data.concat(lots); //要素を追加
  },
  single: (state, item: ILot) => {
    state.lot = new Lot(item);
  },
  multiple: (state, items: ILot[]) => {
    state.lots = items.map(item => new Lot(item));
  },
  clear: state => {
    state.lot = new Lot();
    state.pagination = new Pagination();
  }
};

export default mutations;
