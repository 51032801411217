import { MutationTree } from "vuex";
import { TradeTermState, ITradeTerm } from "@/types/trade_term";
import { IPagination } from "@/types";
import { Pagination } from "@/models/pagination";
import { TradeTerm } from "@/models/trade_term";

const mutations: MutationTree<TradeTermState> = {
  pagination: (state, items: IPagination<ITradeTerm>) => {
    const params: IPagination<TradeTerm> = {
      current_page: items.current_page,
      first_page_url: items.first_page_url,
      from: items.from,
      last_page: items.last_page,
      last_page_url: items.last_page_url,
      next_page_url: items.next_page_url,
      path: items.path,
      per_page: items.per_page,
      prev_page_url: items.prev_page_url,
      to: items.to,
      total: items.total,
      data: items.data.map(item => new TradeTerm(item))
    };

    state.pagination = new Pagination(params);
  },
  single: (state, item: ITradeTerm) => {
    state.trade_term = new TradeTerm(item);
  },
  clear: state => {
    state.trade_term = new TradeTerm();
    state.pagination = new Pagination();
  }
};

export default mutations;
