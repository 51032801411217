import axios from "axios";
import { ActionTree } from "vuex";
import { RootState } from "@/types";
import {
  IDuplicateProduct,
  IDuplicateProductRequest,
  ShipSlipState
} from "@/types/ship_slip";
import { InvoiceDuplicateProduct } from "@/models/ship_slip";

export const actions: ActionTree<ShipSlipState, RootState> = {
  //adminメソッド
  async adminDuplicateCheck({ commit }, params: IDuplicateProductRequest) {
    try {
      // const response = await axios.get<{ viewModel: IBooster }>(
      const response = await axios.get<IDuplicateProduct>(
        "/admin/duplicate_check",
        {
          params
        }
      );

      commit("duplicateProduct", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminInvoiceDuplicateCheck({ commit }, invoice_id: number) {
    try {
      const response = await axios.get<InvoiceDuplicateProduct>(
        `/admin/invoice/${invoice_id}/duplicate_check`
      );
      commit("invoiceDuplicateProduct", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  //nominalUserメソッド
  async userDuplicateCheck({ commit }, params: IDuplicateProductRequest) {
    try {
      // const response = await axios.get<{ viewModel: IBooster }>(
      const response = await axios.get<IDuplicateProduct>(
        "/user/duplicate_check",
        {
          params
        }
      );

      commit("duplicateProduct", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userInvoiceDuplicateCheck({ commit }, invoice_id: number) {
    try {
      const response = await axios.get<InvoiceDuplicateProduct>(
        `/user/invoice/${invoice_id}/duplicate_check`
      );
      commit("invoiceDuplicateProduct", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  }
};

export default actions;
