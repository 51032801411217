import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { ProductShipmentState } from "@/types/product_shipment";
import { ProductShipment } from "@/models/product_shipment";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<ProductShipmentState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.product_shipment || new ProductShipment();
  },
  // selectItem: (state) => {
  //   return state.pagination
  //     ? state.pagination.data.map((product_shipment) => ProductShipment.toSelectItem(product_shipment))
  //     : [];
  // },
  find: state => (id: number) => {
    if (!state.pagination) {
      return new ProductShipment();
    }

    return state.pagination.data.find(item => {
      return item.id == id;
    });
  }
};
export default getters;
