import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { ProductStockState } from "@/types/product_stock";

const state: ProductStockState = {
  stock_by_warehouse: [],
  all_quantity: 0
};

export const product_index: Module<ProductStockState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
