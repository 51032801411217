import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { CustomerState } from "@/types/customer";
import { Customer } from "@/models/customer";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<CustomerState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.customer || new Customer();
  },
  selectItem: state => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data.map(item => item.toSelectItem());
  },
  find: state => (id: number) => {
    return state.pagination
      ? state.pagination.data.find(customer => {
          return customer.id == id;
        })
      : new Customer();
  },
  findName: state => (name: string) => {
    return state.pagination
      ? state.pagination.data.find(customer => {
          return customer.name.includes(name);
        })
      : new Customer();
  }
};
export default getters;
