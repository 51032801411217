import { MutationTree } from "vuex";
import { MoneyforwardState, IMoneyforward } from "@/types/moneyforward";
import { Moneyforward } from "@/models/moneyforward";

const mutations: MutationTree<MoneyforwardState> = {
  single: (state, item: IMoneyforward) => {
    state.moneyforward = new Moneyforward(item);
  },
  clear: state => {
    state.moneyforward = new Moneyforward();
  }
};

export default mutations;
