import {
  IOrderProduct,
  IOrderProductCreateRequest,
  IOrderProductRequest,
  IOrderProductUpdateRequest
} from "@/types/order_product";
import { Order } from "./order";

export class OrderProduct implements IOrderProduct {
  public id;
  public quantity;
  public buying_unit_price;
  public amount; //バックエンド側で計算できるようになっらた不要
  public content;
  public revision;
  public product_id;
  public order_id;
  public maker_id;
  public note_id;
  public unit;
  public created_at;
  public updated_at;
  public deleted_at;
  public product_code;
  public product_name;
  public product_eng_name;
  public product_eng_code;
  public storing_quantity;
  public remaining_quantity;
  public order;

  public constructor(params?: IOrderProduct) {
    this.id = params ? params.id : 0;
    this.quantity = params ? params.quantity : 0;
    this.buying_unit_price = params ? params.buying_unit_price : 0;
    this.amount = params ? params.amount : 0; //バックエンド側で計算できるようになっらた不要
    this.content = params ? params.content : "";
    this.revision = params ? params.revision : "";
    this.product_id = params ? params.product_id : 0;
    this.order_id = params ? params.order_id : 0;
    this.maker_id = params ? params.maker_id : 0;
    this.note_id = params ? params.note_id : null;
    this.unit = params ? params.unit : "";
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
    this.product_code = params ? params.product_code : "";
    this.product_name = params ? params.product_name : "";
    this.product_eng_name = params ? params.product_eng_name : "";
    this.product_eng_code = params ? params.product_eng_code : "";
    this.storing_quantity = params ? params.storing_quantity : 0;
    this.remaining_quantity = params ? params.remaining_quantity : 0;
    this.order = params ? params.order : new Order();
  }

  //1つの商品の合計金額計算
  public get product_amount() {
    return this.buying_unit_price * this.quantity;
  }
}

export class OrderProductRequest implements IOrderProductRequest {
  public id = 0;
  public quantity: number | null = null;
  public buying_unit_price: number | null = null;
  public amount = 0; //バックエンド側で計算できるようになっらた不要
  public content: string | null = null;
  public revision: string | null = null;
  public product_id = 0;

  public createFromOrderProduct(params: IOrderProduct): void {
    this.quantity = params.quantity;
    this.buying_unit_price = params.buying_unit_price;
    this.amount = params.amount; //バックエンド側で計算できるようになっらた不要
    this.content = params.content;
    this.revision = params.revision;
    this.product_id = params.product_id;
  }

  //1つの商品の合計金額計算
  public get product_amount() {
    return (this.buying_unit_price || 0) * (this.quantity || 0);
  }
}

export class OrderProductCreateRequest
  extends OrderProductRequest
  implements IOrderProductCreateRequest {}

export class OrderProductUpdateRequest
  extends OrderProductRequest
  implements IOrderProductUpdateRequest
{
  public order_id = 0;
  public note_id: number | null = null;
  public createFromOrderProduct(params: IOrderProduct) {
    this.order_id = params.order_id;
    this.note_id = params.note_id;
    super.createFromOrderProduct(params);
  }
}
