import axios from "axios";
import { ActionTree } from "vuex";
import { RootState } from "@/types";
import { IProductStock, IProductStockListRequest } from "@/types/product_stock";
import { ProductStockState } from "@/types/product_stock";

export const actions: ActionTree<ProductStockState, RootState> = {
  //adminメソッド
  async adminGetStockShow({ commit }, params: IProductStockListRequest) {
    try {
      const response = await axios.get<{ viewModel: IProductStock }>(
        "/admin/product_stock",
        {
          params
        }
      );
      commit("product_stock", response.data.viewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  //nominalUserメソッド
  async userGetStockShow({ commit }, params: IProductStockListRequest) {
    try {
      const response = await axios.get<{ viewModel: IProductStock }>(
        "/user/product_stock",
        {
          params
        }
      );
      commit("product_stock", response.data.viewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  }
};

export default actions;
