import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  ICustomerListRequest,
  ICustomerCreateRequest,
  ICustomerUpdateRequest,
  CustomerState,
  ICustomer,
  ICustomerRequest
} from "@/types/customer";

export const actions: ActionTree<CustomerState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: ICustomerListRequest) {
    try {
      const response = await axios.get<IPagination<ICustomer>>(
        "/admin/customer",
        { params }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, customer_id: number) {
    try {
      const response = await axios.get<{ customerDetailViewModel: ICustomer }>(
        `/admin/customer/${customer_id}`
      );
      commit("single", response.data.customerDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { customer_id: number; params: ICustomerUpdateRequest }
  ) {
    try {
      const response = await axios.patch<ICustomer>(
        `/admin/customer/${data.customer_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: ICustomerCreateRequest) {
    try {
      const response = await axios.post<ICustomer>("/admin/customer", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, customer_id: number) {
    try {
      await axios.delete(`/admin/customer/${customer_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // 二重登録チェック
  async adminIsUniqueName(state, params: ICustomerRequest) {
    try {
      const response = await axios.get<boolean>(
        "/admin/customer/is_unique_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async adminIsUniqueShortName(state, params: ICustomerRequest) {
    try {
      const response = await axios.get<boolean>(
        "/admin/customer/is_unique_short_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async adminIsUniqueCustomerMfid(state, params: ICustomerRequest) {
    try {
      const response = await axios.get<boolean>(
        "/admin/customer/is_unique_customer_mfid",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },

  // 顧客編集でのテクネ担当者変更の反映
  async adminIsDeleteRelationBranchUser({ commit }, customer_id: number) {
    try {
      await axios.put(
        `/admin/customer/${customer_id}/is_delete_relation/branch_user`
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: ICustomerListRequest) {
    try {
      const response = await axios.get<IPagination<ICustomer>>(
        "/user/customer",
        { params }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, customer_id: number) {
    try {
      const response = await axios.get<{ customerDetailViewModel: ICustomer }>(
        `/user/customer/${customer_id}`
      );
      commit("single", response.data.customerDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { customer_id: number; params: ICustomerUpdateRequest }
  ) {
    try {
      const response = await axios.patch<ICustomer>(
        `/user/customer/${data.customer_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: ICustomerCreateRequest) {
    try {
      const response = await axios.post<ICustomer>("/user/customer", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, customer_id: number) {
    try {
      await axios.delete(`/user/customer/${customer_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // nominalUser二重登録チェック
  async userIsUniqueName(state, params: ICustomerRequest) {
    try {
      const response = await axios.get<boolean>(
        "/user/customer/is_unique_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async userIsUniqueShortName(state, params: ICustomerRequest) {
    try {
      const response = await axios.get<boolean>(
        "/user/customer/is_unique_short_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async userIsUniqueCustomerMfid(state, params: ICustomerRequest) {
    try {
      const response = await axios.get<boolean>(
        "/user/customer/is_unique_customer_mfid",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },

  // nominalUser顧客編集でのテクネ担当者変更の反映
  async userIsDeleteRelationBranchUser({ commit }, customer_id: number) {
    try {
      await axios.put(
        `/user/customer/${customer_id}/is_delete_relation/branch_user`
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //invoice_onlyメソッド
  async invoiceOnlyGetList({ commit }, params: ICustomerListRequest) {
    try {
      const response = await axios.get<IPagination<ICustomer>>(
        "/invoice_only/customer",
        { params }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  }
};

export default actions;
