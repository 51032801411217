import {
  IReceivedOrdersManagement,
  IReceivedOrdersManagementRequest
} from "@/types/received_orders_management";
import { ISelectItem } from "@/types";
import { IReceivedOrder } from "@/types/received_order";

export class ReceivedOrdersManagement implements IReceivedOrdersManagement {
  public id;
  public management_no;
  public received_orders: IReceivedOrder[];
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: IReceivedOrdersManagement) {
    this.id = params ? params.id : 0;
    this.management_no = params ? params.management_no : "";
    this.received_orders = params ? params.received_orders : [];
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.management_no,
      value: this.id
    };
  }
}

export class ReceivedOrdersManagementRequest
  implements IReceivedOrdersManagementRequest
{
  public management_no = "";

  public constructor(params?: IReceivedOrdersManagementRequest) {
    if (!params) {
      return;
    }

    this.management_no = params.management_no;
  }

  public createFromReceivedOrdersManagement(
    received_orders_management: IReceivedOrdersManagement
  ) {
    this.management_no = received_orders_management.management_no;
  }
}
