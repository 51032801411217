import { IPriceTag, IPriceTagRequest } from "@/types/price_tag";
import { ISelectItem } from "@/types";

export class PriceTag implements IPriceTag {
  public id;
  public name;
  public is_minus;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: IPriceTag) {
    this.id = params ? params.id : 0;
    this.name = params ? params.name : "";
    this.is_minus = params ? params.is_minus : 0;
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.name,
      value: this.id
    };
  }
}

export class PriceTagRequest implements IPriceTagRequest {
  public name = "";
  public is_minus = 0;

  public constructor(params?: IPriceTagRequest) {
    if (!params) {
      return;
    }

    this.name = params.name;
    this.is_minus = params.is_minus;
  }

  public createFromPriceTag(price_tag: IPriceTag): void {
    this.name = price_tag.name;
    this.is_minus = price_tag.is_minus;
  }
}
