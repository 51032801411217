import { ActionTree } from "vuex";
import { RootState } from "@/types/index";
import { ILoginRequest, LoginState } from "@/types/login";
import { IUser } from "@/types/user";
import axios from "axios";
import { removeCookie } from "tiny-cookie";

export const actions: ActionTree<LoginState, RootState> = {
  async login({ commit, dispatch }, request: ILoginRequest) {
    try {
      // axios.get("/sanctum/csrf-cookie");
      const response = await axios.post<{ email: string }>("/login", request);
      await dispatch("auth/authorize", response.data, { root: true });
      // await dispatch("sanctum/csrf-cookie", response.data, { root: true });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }

      return false;
    }
  },
  async signup({ commit }, request: ILoginRequest) {
    try {
      await axios.post<{ user: IUser }>("/signup", request);

      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }

      return false;
    }
  },
  async register(
    { commit },
    request: { token: string; password: string; name: string }
  ) {
    try {
      const response = await axios.post<{ user: IUser }>("/register", request);

      return response.data.user;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }

      return false;
    }
  },
  async resetCheck({ commit }, request: ILoginRequest) {
    try {
      await axios.post<{ user: IUser }>("/password/email", request);

      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }

      return false;
    }
  },
  async reset({ commit }, request: ILoginRequest) {
    try {
      await axios.post<{ user: IUser }>("/password/reset", request);

      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }

      return false;
    }
  },
  logout: async ({ commit }) => {
    await axios.post(`/logout`);
    // axios.defaults.headers.Authorization = undefined;
    // delete axios.defaults.headers.Authorization;
    removeCookie("access_token");
    removeCookie("refresh_token");
    removeCookie("abilities");
    commit("clear");
  }
};

export default actions;
