import { IProductShipment } from "@/types/product_shipment";
import { IReceivedOrder } from "@/types/received_order";
import {
  IShipment,
  IShipmentSelectItem,
  IShipmentRequest,
  IShipmentDetail,
  IShipmentEditFormDisp,
  ILotShipment
} from "@/types/shipment";
import { ProductShipment } from "./product_shipment";
import { ReceivedOrder } from "./received_order";

export class Shipment implements IShipment {
  public id;
  public shipment_date;
  public shipment_status;
  public received_order_id;
  public authorizer;
  public customer_id;
  public branch_id;
  public user_id;
  public received_order_no;
  public fixed_delivery_date;
  public authorizer_name;
  public customer_name;
  public customer_short_name;
  public branch_name;
  public branch_short_name;
  public user_name;
  public sales_staff_name;
  public sales_staff;
  public invoice_id;
  public invoice_no;
  public shipment_no;
  public products: IProductShipment[];
  public product_shipment: IProductShipment[];
  public shipment_edit_form_disp: IShipmentEditFormDisp[];
  public received_order: IReceivedOrder;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: IShipment) {
    this.id = params ? params.id : 0;
    this.shipment_status = params ? params.shipment_status : 0;
    this.shipment_date = params ? params.shipment_date : "";
    this.received_order_id = params ? params.received_order_id : 0;
    this.authorizer = params ? params.authorizer : 0;
    this.customer_id = params ? params.customer_id : 0;
    this.branch_id = params ? params.branch_id : 0;
    this.user_id = params ? params.user_id : 0;
    this.received_order_no = params ? params.received_order_no : "";
    this.fixed_delivery_date = params ? params.fixed_delivery_date : "";
    this.authorizer_name = params ? params.authorizer_name : "";
    this.customer_name = params ? params.customer_name : "";
    this.customer_short_name = params ? params.customer_short_name : "";
    this.branch_name = params ? params.branch_name : "";
    this.branch_short_name = params ? params.branch_short_name : "";
    this.user_name = params ? params.user_name : "";
    this.sales_staff_name = params ? params.sales_staff_name : "";
    this.sales_staff = params ? params.sales_staff : 0;
    this.invoice_id = params ? params.invoice_id : 0;
    this.invoice_no = params ? params.invoice_no : "";
    this.shipment_no = params ? params.shipment_no : "";
    this.products = params ? params.products : [];
    this.product_shipment = params ? params.product_shipment : [];
    this.shipment_edit_form_disp = params ? params.shipment_edit_form_disp : [];
    this.received_order = params ? params.received_order : new ReceivedOrder();
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }
  public toSelectItem(): IShipmentSelectItem {
    return {
      id: this.id,
      shipment_date: this.shipment_date,
      received_order_id: this.received_order_id
    };
  }
}

export class ShipmentRequest implements IShipmentRequest {
  public shipment_date = "";
  public shipment_status = 0;
  public received_order_id = 0;
  public authorizer: number | null = null;
  public customer_id = 0;
  public branch_id: number | null = null;
  public user_id = 0;
  public sales_staff = 0;
  public product_shipments: IProductShipment[] = [];

  public createFromShipment(param: IShipment) {
    this.shipment_date = param.shipment_date;
    this.shipment_status = param.shipment_status;
    this.received_order_id = param.received_order_id;
    this.authorizer = param.authorizer;
    this.customer_id = param.customer_id;
    this.branch_id = param.branch_id;
    this.user_id = param.user_id;

    // @todo データ加工して代入
    this.product_shipments = param.product_shipment.map(
      product_shipment => new ProductShipment(product_shipment)
    );
  }
}

export class ShipmentDetail implements IShipmentDetail {
  public lot_id: number | null = null;
  public buying_unit_price = 0;
  public warehouse_id: number | null = null;
  public stock_id: number | null = null;
  public stockage_id: number | null = null;
  public quantity: number | null = null;
  public buying_amount = 0;
  public selling_amount = 0;
  public movement_memo = "";
  public memo = "";
  public movement_id: number | null = null;
  public product_shipment_id = 0;
}

export class LotShipment implements ILotShipment {
  public amount = 0;
  public buying_unit_price = 0;
  public lot_id: number | null = null;
  public product_shipment_memo = "";
  public movement_memo = "";
  public product_shipment_id = 0;
  public quantity: number | null = null;
  public selling_unit_amount = 0;
  public stock_id = 0;
  public stockage_id = 0;
  public warehouse_id = 0;
  public movement_id = 0;
}
