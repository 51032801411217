import { MutationTree } from "vuex";
import { CustomerStaffState, ICustomerStaff } from "@/types/customer_staff";
import { IPagination } from "@/types";
import { Pagination } from "@/models/pagination";
import { CustomerStaff } from "@/models/customer_staff";

const mutations: MutationTree<CustomerStaffState> = {
  pagination: (state, items: IPagination<ICustomerStaff>) => {
    const params: IPagination<CustomerStaff> = {
      current_page: items.current_page,
      first_page_url: items.first_page_url,
      from: items.from,
      last_page: items.last_page,
      last_page_url: items.last_page_url,
      next_page_url: items.next_page_url,
      path: items.path,
      per_page: items.per_page,
      prev_page_url: items.prev_page_url,
      to: items.to,
      total: items.total,
      data: items.data.map(item => new CustomerStaff(item))
    };

    state.pagination = new Pagination(params);
  },
  single: (state, item: ICustomerStaff) => {
    state.customer_staff = new CustomerStaff(item);
  },
  clear: state => {
    state.customer_staff = new CustomerStaff();
    state.pagination = new Pagination();
  }
};

export default mutations;
