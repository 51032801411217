import { MutationTree } from "vuex";
import {
  QuantityHistoryState,
  IQuantityHistory
} from "@/types/quantity_history";
import { QuantityHistory } from "@/models/quantity_history";

const mutations: MutationTree<QuantityHistoryState> = {
  single: (state, item: IQuantityHistory) => {
    state.quantity_history = new QuantityHistory(item);
  },
  clear: state => {
    state.quantity_history = new QuantityHistory();
  }
};

export default mutations;
