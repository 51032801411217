import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { CustomerProductState } from "@/types/customer_product";
import { CustomerProduct } from "@/models/customer_product";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<CustomerProductState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.customer_product || new CustomerProduct();
  },
  selectItem: state => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data.map(item => item.toSelectItem());
  },
  find: state => (id: number) => {
    return state.pagination
      ? state.pagination.data.find(customer => {
          return customer.id == id;
        })
      : new CustomerProduct();
  },
  findCustomerPn: state => (customer_pn: string) => {
    return state.pagination
      ? state.pagination.data.find(customer_product => {
          return customer_product.customer_pn == customer_pn;
        })
      : new CustomerProduct();
  },
  filterdSelectItem: state => (customer_id: number, product_id: number) => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data
      .filter(
        item => item.product_id == product_id && item.customer_id == customer_id
      )
      .map(item => item.toSelectItem());
  }
};
export default getters;
