import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { MakerState } from "@/types/maker";
import { Maker } from "@/models/maker";
import { Pagination } from "@/models/pagination";

const state: MakerState = {
  maker: new Maker(),
  pagination: new Pagination()
};

export const maker: Module<MakerState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
