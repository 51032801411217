import {
  IStock,
  ITransportUnitPrice,
  ITransportUnitPriceUpdateRequest
} from "@/types/stock";
import { ISelectItem } from "@/types";
import dayjs from "dayjs";

export class Stock implements IStock {
  public id;
  public quantity;
  public buying_unit_price;
  public arrival_date;
  public product_id;
  public lot_id;
  public warehouse_id;
  public stockage_id;
  public lot_no;
  public warehouse_name;
  public created_at;
  public updated_at;
  public deleted_at;
  public customer_id;
  public customer_short_name;
  public memo;
  public storing_quantity_id;
  public storing_quantity;
  public transport_unit_price;
  public customer_product_id;
  public is_movement;
  public supplier_id;

  public constructor(params?: IStock) {
    this.id = params ? params.id : 0;
    this.quantity = params ? params.quantity : 0;
    this.buying_unit_price = params ? params.buying_unit_price : 0;
    this.arrival_date = params ? params.arrival_date : "";
    this.product_id = params ? params.product_id : 0;
    this.lot_id = params ? params.lot_id : 0;
    this.warehouse_id = params ? params.warehouse_id : 0;
    this.stockage_id = params ? params.stockage_id : 0;
    this.lot_no = params ? params.lot_no : "";
    this.warehouse_name = params ? params.warehouse_name : "";
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
    this.customer_id = params ? params.customer_id : 0;
    this.customer_short_name = params ? params.customer_short_name : "";
    this.memo = params ? params.memo : "";
    this.storing_quantity_id = params ? params.storing_quantity_id : 0;
    this.storing_quantity = params ? params.storing_quantity : 0;
    this.transport_unit_price = params ? params.transport_unit_price : 0;
    this.customer_product_id = params ? params.customer_product_id : 0;
    this.is_movement = params ? params.is_movement : false;
    this.supplier_id = params ? params.supplier_id : 0;
  }

  public toWarehouseSelectItem(): ISelectItem {
    return {
      text: this.warehouse_name,
      value: this.warehouse_id
    };
  }

  public toLotSelectItem(): ISelectItem {
    return {
      text:
        " ■ " +
        "No: " +
        (this.lot_no || "ー") +
        "\u00A0" +
        " ■ " +
        "入荷日: " +
        dayjs(this.arrival_date).format("MM-DD") +
        "\u00A0" +
        " ■ " +
        "@: " +
        Number(this.buying_unit_price.toFixed(2)) +
        "\u00A0" +
        " ■" +
        "在庫数: " +
        this.quantity +
        "/" +
        this.storing_quantity +
        "\u00A0" +
        " ■ " +
        "在庫メモ: " +
        (this.memo || "ー"),
      value: this.id
    };
  }

  public toBuyingUnitPriceSelectItem(): ISelectItem {
    return {
      text: String(this.buying_unit_price),
      value: this.buying_unit_price
    };
  }
}

export class TransportUnitPrice implements ITransportUnitPrice {
  public stock_id;
  public transport_unit_price;

  public constructor(params?: ITransportUnitPrice) {
    this.stock_id = params ? params.stock_id : 0;
    this.transport_unit_price = params ? params.transport_unit_price : 0;
  }
}

export class TransportUnitPriceUpdateRequest
  implements ITransportUnitPriceUpdateRequest
{
  public stocks: ITransportUnitPrice[];

  public constructor(params?: ITransportUnitPriceUpdateRequest) {
    this.stocks = params ? params.stocks : [];
  }
}
