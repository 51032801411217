import { Order } from "@/models/order";
import { IProductOrderStatus } from "@/types/product_order_status";
import { ReceivedOrder } from "@/models/received_order";
import { IOrder } from "@/types/order";
import { ISelectItem } from "@/types";

export class ProductOrderStatus implements IProductOrderStatus {
  public order_product: [Order[]];
  public product_received_order: [ReceivedOrder[]];

  public constructor(params?: IProductOrderStatus) {
    this.order_product = params ? params.order_product : [[]];
    this.product_received_order = params ? params.product_received_order : [[]];
  }

  public static toOrderSelectItem(order: IOrder): ISelectItem {
    return {
      text: order.order_no,
      value: order.id
    };
  }
}
