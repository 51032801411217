import { IPagination } from "@/types";

export class Pagination<T> implements IPagination<T> {
  public current_page = 0;
  public first_page_url = "";
  public from = 0;
  public last_page = 0;
  public last_page_url = "";
  public next_page_url: string | null = "";
  public path = "";
  public per_page = 0;
  public prev_page_url: string | null = "";
  public to = 0;
  public total = 0;
  public data: T[] = [];

  public constructor(params?: IPagination<T>) {
    if (!params) {
      return;
    }
    this.current_page = params.current_page;
    this.first_page_url = params.first_page_url;
    this.from = params.from;
    this.last_page = params.last_page;
    this.last_page_url = params.last_page_url;
    this.next_page_url = params.next_page_url;
    this.path = params.path;
    this.per_page = params.per_page;
    this.prev_page_url = params.prev_page_url;
    this.to = params.to;
    this.total = params.total;
    this.data = params.data;
  }
}
