import { MutationTree } from "vuex";
import { ReceivedOrderState, IReceivedOrder } from "@/types/received_order";
import { IPagination } from "@/types";
import { Pagination } from "@/models/pagination";
import { ReceivedOrder } from "@/models/received_order";

const mutations: MutationTree<ReceivedOrderState> = {
  pagination: (state, items: IPagination<IReceivedOrder>) => {
    const params: IPagination<ReceivedOrder> = {
      current_page: items.current_page,
      first_page_url: items.first_page_url,
      from: items.from,
      last_page: items.last_page,
      last_page_url: items.last_page_url,
      next_page_url: items.next_page_url,
      path: items.path,
      per_page: items.per_page,
      prev_page_url: items.prev_page_url,
      to: items.to,
      total: items.total,
      data: items.data.map(item => new ReceivedOrder(item))
    };

    state.pagination = new Pagination(params);
  },
  single: (state, item: IReceivedOrder) => {
    state.received_order = new ReceivedOrder(item);
  },
  clear: state => {
    state.received_order = new ReceivedOrder();
    state.pagination = new Pagination();
  }
};

export default mutations;
