import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { LotState } from "@/types/lot";
import { Lot } from "@/models/lot";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<LotState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.lot || new Lot();
  },
  multiple: state => {
    return state.lots || [];
  },
  selectItem: state => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data.map(item => item.toSelectItem());
  },
  filterdSelectItem: state => (id: number) => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data
      .filter(item => item.product_id === id)
      .map(item => item.toSelectItem());
  },
  find: state => (id: number) => {
    if (!state.pagination) {
      return new Lot();
    }

    return state.pagination.data.find(item => {
      return item.id == id;
    });
  }
};
export default getters;
