import store from "@/store";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Root",
    component: () => import("../views/Login.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue")
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("../views/Logout.vue")
  },
  {
    path: "/callback",
    name: "callback",
    component: () => import("../views/Callback.vue")
  },

  /****************************************************************************************
   * admin
   */
  //===============================================================================
  // home
  {
    path: "/admin/home",
    name: "AdminHome",
    meta: { requiresAuthAdmin: true, title: "事務ホーム" },
    component: () => import("../views/admin/Home.vue")
  },
  //===============================================================================
  // user
  {
    path: "/admin/user/list",
    name: "AdminUserList",
    meta: { requiresAuthAdmin: true, title: "ユーザ一覧" },
    component: () => import("../views/admin/user/Index.vue")
  },
  {
    path: "/admin/user/create",
    name: "AdminUserCreate",
    meta: { requiresAuthAdmin: true, title: "ユーザ登録" },
    component: () => import("../views/admin/user/Create.vue")
  },
  {
    path: "/admin/user/:user_id/edit",
    name: "AdminUserEdit",
    meta: { requiresAuthAdmin: true, title: "ユーザ編集" },
    component: () => import("../views/admin/user/_id/Edit.vue")
  },
  //===============================================================================
  // maker
  {
    path: "/admin/maker/list",
    name: "AdminMakerList",
    meta: { requiresAuthAdmin: true, title: "メーカ一覧" },
    component: () => import("../views/admin/maker/Index.vue")
  },
  {
    path: "/admin/maker/create",
    name: "AdminMakerCreate",
    meta: { requiresAuthAdmin: true, title: "メーカ登録" },
    component: () => import("../views/admin/maker/Create.vue")
  },
  {
    path: "/admin/maker/:maker_id/edit",
    name: "AdminMakerEdit",
    meta: { requiresAuthAdmin: true, title: "メーカ編集" },
    component: () => import("../views/admin/maker/_id/Edit.vue")
  },
  {
    path: "/admin/maker/:maker_id",
    meta: { requiresAuthAdmin: true },
    component: () => import("../views/admin/maker/_id/Index.vue"),
    children: [
      {
        path: "product",
        name: "AdminMakerProductList",
        meta: { requiresAuthAdmin: true, title: "メーカ詳細 / 商品一覧" },
        component: () => import("../views/admin/maker/_id/List.vue")
      }
    ]
  },
  {
    path: "/admin/maker/:maker_id/product/create",
    name: "AdminMakerProductCreate",
    meta: { requiresAuthAdmin: true, title: "商品登録" },
    component: () => import("../views/admin/product/Create.vue")
  },
  //===============================================================================
  // product
  {
    path: "/admin/product/list",
    name: "AdminProductList",
    meta: { requiresAuthAdmin: true, title: "商品一覧" },
    component: () => import("../views/admin/product/Index.vue")
  },
  {
    path: "/admin/product/create",
    name: "AdminProductCreate",
    meta: { requiresAuthAdmin: true, title: "商品登録" },
    component: () => import("../views/admin/product/Create.vue")
  },
  {
    path: "/admin/product/:product_id/edit",
    name: "AdminProductEdit",
    meta: { requiresAuthAdmin: true, title: "商品編集" },
    component: () => import("../views/admin/product/_id/Edit.vue")
  },
  {
    path: "/admin/product/:product_id",
    meta: { requiresAuthAdmin: true },
    component: () => import("../views/admin/product/_id/Index.vue"),
    children: [
      {
        path: "/",
        name: "AdminProductDetail",
        meta: { requiresAuthAdmin: true, title: "商品詳細" },
        component: () => import("../views/admin/product/_id/Detail.vue")
      },
      {
        path: "customer_product",
        name: "AdminProductCustomerProducList",
        meta: { requiresAuthAdmin: true, title: "商品詳細 / 顧客品番一覧" },
        component: () => import("../views/admin/customer_product/List.vue")
      }
    ]
  },
  {
    path: "/admin/product/:product_id/customer_product/create",
    name: "AdminProductCustomerProductCreate",
    meta: { requiresAuthAdmin: true, title: "顧客品番登録" },
    component: () => import("../views/admin/customer_product/Create.vue")
  },
  {
    path: "/admin/customer_product/:customer_product_id/edit",
    name: "AdminCustomerProductEdit",
    meta: { requiresAuthAdmin: true, title: "顧客品番編集" },
    component: () => import("../views/admin/customer_product/_id/Edit.vue")
  },
  //===============================================================================
  // supplier
  {
    path: "/admin/supplier/list",
    name: "AdminSupplierList",
    meta: { requiresAuthAdmin: true, title: "サプライヤ一覧" },
    component: () => import("../views/admin/supplier/Index.vue")
  },
  {
    path: "/admin/supplier/create",
    name: "AdminSupplierCreate",
    meta: { requiresAuthAdmin: true, title: "サプライヤ登録" },
    component: () => import("../views/admin/supplier/Create.vue")
  },
  {
    path: "/admin/supplier/:supplier_id/edit",
    name: "AdminSupplierEdit",
    meta: { requiresAuthAdmin: true, title: "サプライヤ編集" },
    component: () => import("../views/admin/supplier/_id/Edit.vue")
  },
  {
    path: "/admin/supplier/:supplier_id",
    meta: { requiresAuthAdmin: true },
    component: () => import("../views/admin/supplier/_id/Index.vue"),
    children: [
      {
        path: "/",
        name: "AdminSupplierDetail",
        meta: {
          requiresAuthAdmin: true,
          title: "サプライヤ詳細"
        },
        component: () => import("../views/admin/supplier/_id/Detail.vue")
      },
      {
        path: "supplier_staff",
        name: "AdminSupplierSupplierStaffList",
        meta: {
          requiresAuthAdmin: true,
          title: "サプライヤ詳細 / サプライヤ担当者一覧"
        },
        component: () => import("../views/admin/supplier_staff/List.vue")
      }
    ]
  },
  {
    path: "/admin/supplier/:supplier_id/supplier_staff/create",
    name: "AdminSupplierSupplierStaffCreate",
    meta: { requiresAuthAdmin: true, title: "サプライヤ担当者登録" },
    component: () => import("../views/admin/supplier_staff/Create.vue")
  },
  {
    path: "/admin/supplier_staff/:supplier_staff_id/edit",
    name: "AdminSupplierStaffEdit",
    meta: { requiresAuthAdmin: true, title: "サプライヤ担当者編集" },
    component: () => import("../views/admin/supplier_staff/_id/Edit.vue")
  },
  //===============================================================================
  // customer
  {
    path: "/admin/customer/list",
    name: "AdminCustomerList",
    meta: { requiresAuthAdmin: true, title: "顧客一覧" },
    component: () => import("../views/admin/customer/Index.vue")
  },
  {
    path: "/admin/customer/create",
    name: "AdminCustomerCreate",
    meta: { requiresAuthAdmin: true, title: "顧客登録" },
    component: () => import("../views/admin/customer/Create.vue")
  },
  {
    path: "/admin/customer/:customer_id/edit",
    name: "AdminCustomerEdit",
    meta: { requiresAuthAdmin: true, title: "顧客編集" },
    component: () => import("../views/admin/customer/_id/Edit.vue")
  },
  {
    path: "/admin/customer/:customer_id",
    meta: { requiresAuthAdmin: true },
    component: () => import("../views/admin/customer/_id/Index.vue"),
    children: [
      {
        path: "/",
        name: "AdminCustomerDetail",
        meta: { requiresAuthAdmin: true, title: "顧客詳細" },
        component: () => import("../views/admin/customer/_id/Detail.vue")
      },
      {
        path: "customer_staff",
        name: "AdminCustomerCustomerStaffList",
        meta: { requiresAuthAdmin: true, title: "顧客詳細 / 顧客担当者一覧" },
        component: () => import("../views/admin/customer_staff/List.vue")
      },
      {
        path: "branch",
        name: "AdminCustomerBranchList",
        meta: { requiresAuthAdmin: true, title: "顧客詳細 / 支店一覧" },
        component: () => import("../views/admin/branch/List.vue")
      },
      {
        path: "payment_term",
        name: "AdminCustomerPaymentTermList",
        meta: { requiresAuthAdmin: true, title: "顧客詳細 / 支払条件一覧" },
        component: () => import("../views/admin/payment_term/List.vue")
      }
    ]
  },
  {
    path: "/admin/customer/:customer_id/customer_staff/create",
    name: "AdminCustomerCustomerStaffCreate",
    meta: { requiresAuthAdmin: true, title: "顧客担当者登録" },
    component: () => import("../views/admin/customer_staff/Create.vue")
  },
  {
    path: "/admin/customer_staff/:customer_staff_id/edit",
    name: "AdminCustomerStaffEdit",
    meta: { requiresAuthAdmin: true, title: "顧客担当者編集" },
    component: () => import("../views/admin/customer_staff/_id/Edit.vue")
  },
  {
    path: "/admin/customer/:customer_id/branch/create",
    name: "AdminCustomerBranchCreate",
    meta: { requiresAuthAdmin: true, title: "支店登録" },
    component: () => import("../views/admin/branch/Create.vue")
  },
  {
    path: "/admin/branch/:branch_id/edit",
    name: "AdminBranchEdit",
    meta: { requiresAuthAdmin: true, title: "支店編集" },
    component: () => import("../views/admin/branch/_id/Edit.vue")
  },
  {
    path: "/admin/customer/:customer_id/payment_term/create",
    name: "AdminCustomerPaymentTermCreate",
    meta: { requiresAuthAdmin: true, title: "支払条件登録" },
    component: () => import("../views/admin/payment_term/Create.vue")
  },
  {
    path: "/admin/payment_term/:payment_term_id/edit",
    name: "AdminPaymentTermEdit",
    meta: { requiresAuthAdmin: true, title: "支払条件編集" },
    component: () => import("../views/admin/payment_term/_id/Edit.vue")
  },
  //===============================================================================
  //warehouse
  {
    path: "/admin/warehouse/list",
    name: "AdminWarehouseList",
    meta: { requiresAuthAdmin: true, title: "倉庫一覧" },
    component: () => import("../views/admin/warehouse/Index.vue")
  },
  {
    path: "/admin/warehouse/create",
    name: "AdminWarehouseCreate",
    meta: { requiresAuthAdmin: true, title: "倉庫登録" },
    component: () => import("../views/admin/warehouse/Create.vue")
  },
  {
    path: "/admin/warehouse/:warehouse_id/edit",
    name: "AdminWarehouseEdit",
    meta: { requiresAuthAdmin: true, title: "倉庫編集" },
    component: () => import("../views/admin/warehouse/_id/Edit.vue")
  },
  //===============================================================================
  //tax
  {
    path: "/admin/tax/list",
    name: "AdminTaxList",
    meta: { requiresAuthAdmin: true, title: "税設定一覧" },
    component: () => import("../views/admin/tax/Index.vue")
  },
  // 一旦コメントアウト
  //
  // {
  //   path: "/admin/tax/create",
  //   name: "AdminTaxCreate",
  //   meta: { requiresAuthAdmin: true, title: "税設定登録" },
  //   component: () => import("../views/admin/tax/Create.vue")
  // },
  // {
  //   path: "/admin/tax/:tax_id/edit",
  //   name: "AdminTaxEdit",
  //   meta: { requiresAuthAdmin: true, title: "税設定編集" },
  //   component: () => import("../views/admin/tax/_id/Edit.vue")
  // },
  //===============================================================================
  // payment_method
  {
    path: "/admin/payment_method/list",
    name: "AdminPaymentMethodList",
    meta: { requiresAuthAdmin: true, title: "支払方法一覧" },
    component: () => import("../views/admin/payment_method/Index.vue")
  },
  {
    path: "/admin/payment_method/create",
    name: "AdminPaymentMethodCreate",
    meta: { requiresAuthAdmin: true, title: "支払方法登録" },
    component: () => import("../views/admin/payment_method/Create.vue")
  },
  {
    path: "/admin/payment_method/:payment_method_id/edit",
    name: "AdminPaymentMethodEdit",
    meta: { requiresAuthAdmin: true, title: "支払方法編集" },
    component: () => import("../views/admin/payment_method/_id/Edit.vue")
  },
  //===============================================================================
  // price_tag
  {
    path: "/admin/price_tag/list",
    name: "AdminPriceTagList",
    meta: { requiresAuthAdmin: true, title: "諸経費一覧" },
    component: () => import("../views/admin/price_tag/Index.vue")
  },
  {
    path: "/admin/price_tag/create",
    name: "AdminPriceTagCreate",
    meta: { requiresAuthAdmin: true, title: "諸経費登録" },
    component: () => import("../views/admin/price_tag/Create.vue")
  },
  {
    path: "/admin/price_tag/:price_tag_id/edit",
    name: "AdminPriceTagEdit",
    meta: { requiresAuthAdmin: true, title: "諸経費編集" },
    component: () => import("../views/admin/price_tag/_id/Edit.vue")
  },
  {
    path: "/admin/price_tag/:price_tag_id",
    meta: { requiresAuthAdmin: true },
    component: () => import("../views/admin/price_tag/_id/Index.vue"),
    children: [
      {
        path: "/",
        name: "AdminPriceTagCustomerPriceTagList",
        meta: { requiresAuthAdmin: true, title: "諸経費詳細 / 顧客品番一覧" },
        component: () => import("../views/admin/customer_price_tag/List.vue")
      },
      {
        path: "price_tag_supplier",
        name: "AdminPriceTagPriceTagSupplierList",
        meta: {
          requiresAuthAdmin: true,
          title: "諸経費詳細 / サプライヤ品番一覧"
        },
        component: () => import("../views/admin/price_tag_supplier/List.vue")
      }
    ]
  },
  {
    path: "/admin/price_tag/:price_tag_id/customer_price_tag/create",
    name: "AdminPriceTagCustomerPriceTagCreate",
    meta: { requiresAuthAdmin: true, title: "顧客品番登録" },
    component: () => import("../views/admin/customer_price_tag/Create.vue")
  },
  {
    path: "/admin/customer_price_tag/:customer_price_tag_id/edit",
    name: "AdminCustomerPriceTagEdit",
    meta: { requiresAuthAdmin: true, title: "顧客品番編集" },
    component: () => import("../views/admin/customer_price_tag/_id/Edit.vue")
  },
  {
    path: "/admin/price_tag/:price_tag_id/price_tag_supplier/create",
    name: "AdminPriceTagPriceTagSupplierCreate",
    meta: { requiresAuthAdmin: true, title: "サプライヤ品番登録" },
    component: () => import("../views/admin/price_tag_supplier/Create.vue")
  },
  {
    path: "/admin/price_tag_supplier/:price_tag_supplier_id/edit",
    name: "AdminPriceTagSupplierEdit",
    meta: { requiresAuthAdmin: true, title: "サプライヤ品番編集" },
    component: () => import("../views/admin/price_tag_supplier/_id/Edit.vue")
  },
  //===============================================================================
  //shipment
  {
    path: "/admin/shipment/list",
    name: "AdminShipmentList",
    meta: { requiresAuthAdmin: true, title: "出荷一覧" },
    component: () => import("../views/admin/shipment/Index.vue")
  },
  {
    path: "/admin/shipment/:shipment_id",
    name: "AdminShipmentDetail",
    meta: { requiresAuthAdmin: true, title: "出荷詳細" },
    component: () => import("../views/admin/shipment/_id/Index.vue")
  },
  {
    path: "/admin/shipment/:shipment_id/edit",
    name: "AdminShipmentEdit",
    meta: { requiresAuthAdmin: true, title: "ロット引当修正" },
    component: () => import("../views/admin/shipment/_id/Edit.vue")
  },
  {
    path: "/admin/shipment/:shipment_id/pre_shipment/edit",
    name: "AdminShipmentPreShipmentEdit",
    meta: { requiresAuthAdmin: true, title: "事前出荷修正" },
    component: () => import("../views/admin/pre_shipment/_id/Edit.vue")
  },
  {
    path: "/admin/shipment/:shipment_id/pre_shipment/create",
    name: "AdminShipmentPreToShipmentCreate",
    meta: { requiresAuthAdmin: true, title: "ロット引当" },
    component: () =>
      import("../views/admin/pre_shipment/_id/ShipmentCreate.vue")
  },
  {
    path: "/admin/shipment/:shipment_id/product_shipment/:product_shipment_id/quantity_history/change",
    name: "AdminShipmentQuantityHistoryChange",
    meta: { requiresAuthAdmin: true, title: "数量履歴改変" },
    component: () => import("../views/admin/quantity_history/_id/Change.vue")
  },
  //===============================================================================
  //received_order
  {
    path: "/admin/received_orders_management/list",
    name: "AdminReceivedOrdersManagementList",
    meta: { requiresAuthAdmin: true, title: "受注一覧" },
    component: () =>
      import("../views/admin/received_orders_management/Index.vue")
  },
  {
    path: "/admin/received_order/create",
    name: "AdminReceivedOrderCreate",
    meta: { requiresAuthAdmin: true, title: "受注登録" },
    component: () => import("../views/admin/received_order/Create.vue")
  },
  {
    path: "/admin/received_order/:received_order_id/edit",
    name: "AdminReceivedOrderEdit",
    meta: { requiresAuthAdmin: true, title: "受注編集" },
    component: () => import("../views/admin/received_order/_id/Edit.vue")
  },
  {
    path: "/admin/received_order/:received_order_id",
    name: "AdminReceivedOrderIndex",
    meta: { requiresAuthAdmin: true, title: "受注詳細" },
    component: () => import("../views/admin/received_order/_id/Index.vue")
  },
  {
    path: "/admin/received_order/:received_order_id/shipment/create",
    name: "AdminReceivedOrderShipmentCreate",
    meta: { requiresAuthAdmin: true, title: "ロット引当" },
    component: () => import("../views/admin/shipment/Create.vue")
  },
  {
    path: "/admin/received_order/:received_order_id/pre_shipment/create",
    name: "AdminReceivedOrderPreShipmentCreate",
    meta: { requiresAuthAdmin: true, title: "事前出荷登録" },
    component: () => import("../views/admin/pre_shipment/Create.vue")
  },
  //===============================================================================
  //Order
  {
    path: "/admin/order/list",
    name: "AdminOrderList",
    meta: { requiresAuthAdmin: true, title: "発注一覧" },
    component: () => import("../views/admin/order/Index.vue")
  },
  {
    path: "/admin/order/create",
    name: "AdminOrderCreate",
    meta: { requiresAuthAdmin: true, title: "発注登録" },
    component: () => import("../views/admin/order/Create.vue")
  },
  {
    path: "/admin/order/:order_id",
    name: "AdminOrderDetail",
    meta: { requiresAuthAdmin: true, title: "発注詳細" },
    component: () => import("../views/admin/order/_id/Index.vue")
  },
  {
    path: "/admin/order/:order_id/create",
    name: "AdminOrderCopyCreate",
    meta: { requiresAuthAdmin: true, title: "発注登録" },
    component: () => import("../views/admin/order/CopyCreate.vue")
  },
  {
    path: "/admin/order/:order_id/edit",
    name: "AdminOrderEdit",
    meta: { requiresAuthAdmin: true, title: "発注編集" },
    component: () => import("../views/admin/order/_id/Edit.vue")
  },
  {
    path: "/admin/order/:order_id/movement_storing/create",
    name: "AdminOrderMovementStoringCreate",
    meta: { requiresAuthAdmin: true, title: "入荷処理" },
    component: () => import("../views/admin/movement_storing/Create.vue")
  },
  //===============================================================================
  //movement
  {
    path: "/admin/movement/list",
    name: "AdminMovementList",
    meta: { requiresAuthAdmin: true, title: "商品移動履歴一覧" },
    component: () => import("../views/admin/movement/Index.vue")
  },
  {
    path: "/admin/movement/create",
    name: "AdminMovementCreate",
    meta: { requiresAuthAdmin: true, title: "倉庫移動処理" },
    component: () => import("../views/admin/movement/Create.vue")
  },
  {
    path: "/admin/movement/:movement_id/edit",
    name: "AdminMovementEdit",
    meta: { requiresAuthAdmin: true, title: "倉庫移動修正" },
    component: () => import("../views/admin/movement/_id/Edit.vue")
  },
  {
    path: "/admin/movement/:shipment_id/shipment/edit",
    name: "AdminMovementShipmentEdit",
    meta: { requiresAuthAdmin: true, title: "ロット引当修正" },
    component: () => import("../views/admin/shipment/_id/Edit.vue")
  },
  {
    path: "/admin/movement/:movement_id/movement_storing/edit",
    name: "AdminMovementStoringEdit",
    meta: { requiresAuthAdmin: true, title: "入荷修正" },
    component: () => import("../views/admin/movement_storing/_id/Edit.vue")
  },
  {
    path: "/admin/movement_storing/create",
    name: "AdminStoringCreate",
    meta: { requiresAuthAdmin: true, title: "入荷処理" },
    component: () => import("../views/admin/movement_storing/Create.vue")
  },
  //===============================================================================
  //inventory
  {
    path: "/admin/inventory/list",
    name: "AdminInventoryList",
    meta: { requiresAuthAdmin: true, title: "在庫一覧" },
    component: () => import("../views/admin/inventory/Index.vue")
  },
  {
    path: "/admin/inventory/alert_list",
    name: "AdminAlertInventoryList",
    meta: { requiresAuthAdmin: true, title: "在庫アラート一覧" },
    component: () => import("../views/admin/inventory/List.vue")
  },
  {
    path: "/admin/inventory/:product_id",
    meta: { requiresAuthAdmin: true },
    component: () => import("../views/admin/inventory/_id/Index.vue"),
    children: [
      {
        path: "state",
        name: "AdminInventoryStockIndex",
        meta: { requiresAuthAdmin: true, title: "在庫詳細 / 状況一覧" },
        component: () => import("../views/admin/stock/Index.vue")
      },
      {
        path: "stock",
        name: "AdminInventoryStockList",
        meta: {
          requiresAuthAdmin: true,
          title: "在庫詳細 / LOT一覧"
        },
        component: () => import("../views/admin/stock/List.vue")
      },
      {
        path: "movement",
        name: "AdminInventoryMovementList",
        meta: { requiresAuthAdmin: true, title: "在庫詳細 / 商品移動履歴一覧" },
        component: () => import("../views/admin/movement/List.vue")
      },
      {
        path: "movement_storing/create",
        name: "AdminInventoryMovementStoringCreate",
        meta: { requiresAuthAdmin: true, title: "入荷処理" },
        component: () => import("../views/admin/movement_storing/Create.vue")
      }
    ]
  },
  {
    path: "/admin/stock/:stock_id/movement/create",
    name: "AdminStockMovementCreate",
    meta: { requiresAuthAdmin: true, title: "倉庫移動処理" },
    component: () => import("../views/admin/movement/Create.vue")
  },
  //===============================================================================
  //invoice
  {
    path: "/admin/invoice/list",
    name: "AdminInvoiceList",
    meta: { requiresAuthAdmin: true, title: "請求書一覧" },
    component: () => import("../views/admin/invoice/Index.vue")
  },
  {
    path: "/admin/invoice/create",
    name: "AdminInvoiceCreate",
    meta: { requiresAuthAdmin: true, title: "請求書登録" },
    component: () => import("../views/admin/invoice/Create.vue")
  },
  {
    path: "/admin/invoice/:invoice_id/edit",
    name: "AdminInvoiceEdit",
    meta: { requiresAuthAdmin: true, title: "請求書編集" },
    component: () => import("../views/admin/invoice/_id/Edit.vue")
  },
  {
    path: "/admin/invoice/:invoice_id",
    name: "AdminInvoiceDetail",
    meta: { requiresAuthAdmin: true, title: "請求書詳細" },
    component: () => import("../views/admin/invoice/_id/Index.vue")
  },
  //===============================================================================
  //transport_unit_price
  {
    path: "/admin/transport_unit_price/:order_id/edit",
    name: "AdminTransportUnitPriceEdit",
    meta: { requiresAuthAdmin: true, title: "輸送費単価編集" },
    component: () => import("../views/admin/transport_unit_price/_id/Edit.vue")
  },
  //===============================================================================
  // trade_term
  {
    path: "/admin/trade_term/list",
    name: "AdminTradeTermList",
    meta: { requiresAuthAdmin: true, title: "貿易条件一覧" },
    component: () => import("../views/admin/trade_term/Index.vue")
  },
  {
    path: "/admin/trade_term/create",
    name: "AdminTradeTermCreate",
    meta: { requiresAuthAdmin: true, title: "貿易条件登録" },
    component: () => import("../views/admin/trade_term/Create.vue")
  },
  {
    path: "/admin/trade_term/:trade_term_id/edit",
    name: "AdminTradeTermEdit",
    meta: { requiresAuthAdmin: true, title: "貿易条件編集" },
    component: () => import("../views/admin/trade_term/_id/Edit.vue")
  },
  //===============================================================================
  // arrangement_method
  {
    path: "/admin/arrangement_method/list",
    name: "AdminArrangementMethodList",
    meta: { requiresAuthAdmin: true, title: "手配方法一覧" },
    component: () => import("../views/admin/arrangement_method/Index.vue")
  },
  {
    path: "/admin/arrangement_method/create",
    name: "AdminArrangementMethodCreate",
    meta: { requiresAuthAdmin: true, title: "手配方法登録" },
    component: () => import("../views/admin/arrangement_method/Create.vue")
  },
  {
    path: "/admin/arrangement_method/:arrangement_method_id/edit",
    name: "AdminArrangementMethodEdit",
    meta: { requiresAuthAdmin: true, title: "手配方法編集" },
    component: () => import("../views/admin/arrangement_method/_id/Edit.vue")
  },
  //===============================================================================
  // booster
  {
    path: "/admin/booster",
    name: "AdminBooster",
    meta: { requiresAuthAdmin: true, title: "Booster" },
    component: () => import("../views/admin/booster/Home.vue")
  },

  /*************************************************************************************************************************************
   *************************************************************************************************************************************
   * user
   */
  //===============================================================================
  //home
  {
    path: "/user/home",
    name: "UserHome",
    meta: { requiresAuthUser: true, title: "営業ホーム" },
    component: () => import("../views/user/Home.vue")
  },
  //===============================================================================
  // maker
  {
    path: "/user/maker/list",
    name: "UserMakerList",
    meta: { requiresAuthUser: true, title: "メーカ一覧" },
    component: () => import("../views/user/maker/Index.vue")
  },
  {
    path: "/user/maker/create",
    name: "UserMakerCreate",
    meta: { requiresAuthUser: true, title: "メーカ登録" },
    component: () => import("../views/user/maker/Create.vue")
  },
  {
    path: "/user/maker/:maker_id/edit",
    name: "UserMakerEdit",
    meta: { requiresAuthUser: true, title: "メーカ編集" },
    component: () => import("../views/user/maker/_id/Edit.vue")
  },
  {
    path: "/user/maker/:maker_id",
    meta: { requiresAuthUser: true },
    component: () => import("../views/user/maker/_id/Index.vue"),
    children: [
      {
        path: "product",
        name: "UserMakerProductList",
        meta: { requiresAuthUser: true, title: "メーカ詳細 / 商品一覧" },
        component: () => import("../views/user/maker/_id/List.vue")
      }
    ]
  },
  //===============================================================================
  // product
  {
    path: "/user/product/list",
    name: "UserProductList",
    meta: { requiresAuthUser: true, title: "商品一覧" },
    component: () => import("../views/user/product/Index.vue")
  },
  {
    path: "/user/product/:product_id",
    meta: { requiresAuthUser: true },
    component: () => import("../views/user/product/_id/Index.vue"),
    children: [
      {
        path: "/",
        name: "UserProductDetail",
        meta: { requiresAuthUser: true, title: "商品詳細" },
        component: () => import("../views/user/product/_id/Detail.vue")
      },
      {
        path: "customer_product",
        name: "UserProductCustomerProducList",
        meta: { requiresAuthUser: true, title: "商品詳細 / 顧客品番一覧" },
        component: () => import("../views/user/customer_product/List.vue")
      }
    ]
  },
  //===============================================================================
  // supplier
  {
    path: "/user/supplier/list",
    name: "UserSupplierList",
    meta: { requiresAuthUser: true, title: "サプライヤ一覧" },
    component: () => import("../views/user/supplier/Index.vue")
  },
  {
    path: "/user/supplier/create",
    name: "UserSupplierCreate",
    meta: { requiresAuthUser: true, title: "サプライヤ登録" },
    component: () => import("../views/user/supplier/Create.vue")
  },
  {
    path: "/user/supplier/:supplier_id/edit",
    name: "UserSupplierEdit",
    meta: { requiresAuthUser: true, title: "サプライヤ編集" },
    component: () => import("../views/user/supplier/_id/Edit.vue")
  },
  {
    path: "/user/supplier/:supplier_id",
    meta: { requiresAuthUser: true },
    component: () => import("../views/user/supplier/_id/Index.vue"),
    children: [
      {
        path: "/",
        name: "UserSupplierDetail",
        meta: {
          requiresAuthUser: true,
          title: "サプライヤ詳細"
        },
        component: () => import("../views/user/supplier/_id/Detail.vue")
      },
      {
        path: "supplier_staff",
        name: "UserSupplierSupplierStaffList",
        meta: {
          requiresAuthUser: true,
          title: "サプライヤ詳細 / サプライヤ担当者一覧"
        },
        component: () => import("../views/user/supplier_staff/List.vue")
      }
    ]
  },
  {
    path: "/user/supplier/:supplier_id/supplier_staff/create",
    name: "UserSupplierSupplierStaffCreate",
    meta: { requiresAuthUser: true, title: "サプライヤ担当者登録" },
    component: () => import("../views/user/supplier_staff/Create.vue")
  },
  {
    path: "/user/supplier_staff/:supplier_staff_id/edit",
    name: "UserSupplierStaffEdit",
    meta: { requiresAuthUser: true, title: "サプライヤ担当者編集" },
    component: () => import("../views/user/supplier_staff/_id/Edit.vue")
  },
  //===============================================================================
  // customer
  {
    path: "/user/customer/list",
    name: "UserCustomerList",
    meta: { requiresAuthUser: true, title: "顧客一覧" },
    component: () => import("../views/user/customer/Index.vue")
  },
  {
    path: "/user/customer/create",
    name: "UserCustomerCreate",
    meta: { requiresAuthUser: true, title: "顧客登録" },
    component: () => import("../views/user/customer/Create.vue")
  },
  {
    path: "/user/customer/:customer_id/edit",
    name: "UserCustomerEdit",
    meta: { requiresAuthUser: true, title: "顧客編集" },
    component: () => import("../views/user/customer/_id/Edit.vue")
  },
  {
    path: "/user/customer/:customer_id",
    meta: { requiresAuthUser: true },
    component: () => import("../views/user/customer/_id/Index.vue"),
    children: [
      {
        path: "/",
        name: "UserCustomerDetail",
        meta: { requiresAuthUser: true, title: "顧客詳細" },
        component: () => import("../views/user/customer/_id/Detail.vue")
      },
      {
        path: "customer_staff",
        name: "UserCustomerCustomerStaffList",
        meta: { requiresAuthUser: true, title: "顧客詳細 / 顧客担当者一覧" },
        component: () => import("../views/user/customer_staff/List.vue")
      },
      {
        path: "branch",
        name: "UserCustomerBranchList",
        meta: { requiresAuthUser: true, title: "顧客詳細 / 支店一覧" },
        component: () => import("../views/user/branch/List.vue")
      },
      {
        path: "payment_term",
        name: "UserCustomerPaymentTermList",
        meta: { requiresAuthUser: true, title: "顧客詳細 / 支払条件一覧" },
        component: () => import("../views/user/payment_term/List.vue")
      }
    ]
  },
  {
    path: "/user/customer/:customer_id/customer_staff/create",
    name: "UserCustomerCustomerStaffCreate",
    meta: { requiresAuthUser: true, title: "顧客担当者登録" },
    component: () => import("../views/user/customer_staff/Create.vue")
  },
  {
    path: "/user/customer_staff/:customer_staff_id/edit",
    name: "UserCustomerStaffEdit",
    meta: { requiresAuthUser: true, title: "顧客担当者編集" },
    component: () => import("../views/user/customer_staff/_id/Edit.vue")
  },
  {
    path: "/user/customer/:customer_id/branch/create",
    name: "UserCustomerBranchCreate",
    meta: { requiresAuthUser: true, title: "支店登録" },
    component: () => import("../views/user/branch/Create.vue")
  },
  {
    path: "/user/branch/:branch_id/edit",
    name: "UserBranchEdit",
    meta: { requiresAuthUser: true, title: "支店編集" },
    component: () => import("../views/user/branch/_id/Edit.vue")
  },
  {
    path: "/user/customer/:customer_id/payment_term/create",
    name: "UserCustomerPaymentTermCreate",
    meta: { requiresAuthUser: true, title: "支払条件登録" },
    component: () => import("../views/user/payment_term/Create.vue")
  },
  {
    path: "/user/payment_term/:payment_term_id/edit",
    name: "UserPaymentTermEdit",
    meta: { requiresAuthUser: true, title: "支払条件編集" },
    component: () => import("../views/user/payment_term/_id/Edit.vue")
  },

  //===============================================================================
  //shipment
  {
    path: "/user/shipment/list",
    name: "UserShipmentList",
    meta: { requiresAuthUser: true, title: "出荷一覧" },
    component: () => import("../views/user/shipment/Index.vue")
  },
  {
    path: "/user/shipment/:shipment_id",
    name: "UserShipmentDetail",
    meta: { requiresAuthUser: true, title: "出荷詳細" },
    component: () => import("../views/user/shipment/_id/Index.vue")
  },
  //===============================================================================
  //received_order
  {
    path: "/user/received_orders_management/list",
    name: "UserReceivedOrdersManagementList",
    meta: { requiresAuthUser: true, title: "受注一覧" },
    component: () =>
      import("../views/user/received_orders_management/Index.vue")
  },
  {
    path: "/user/received_order/create",
    name: "UserReceivedOrderCreate",
    meta: { requiresAuthUser: true, title: "受注登録" },
    component: () => import("../views/user/received_order/Create.vue")
  },
  {
    path: "/user/received_order/:received_order_id/edit",
    name: "UserReceivedOrderEdit",
    meta: { requiresAuthUser: true, title: "受注編集" },
    component: () => import("../views/user/received_order/_id/Edit.vue")
  },
  {
    path: "/user/received_order/:received_order_id",
    name: "UserReceivedOrderIndex",
    meta: { requiresAuthUser: true, title: "受注詳細" },
    component: () => import("../views/user/received_order/_id/Index.vue")
  },
  //===============================================================================
  //Order
  {
    path: "/user/order/list",
    name: "UserOrderList",
    meta: { requiresAuthUser: true, title: "発注一覧" },
    component: () => import("../views/user/order/Index.vue")
  },
  {
    path: "/user/order/create",
    name: "UserOrderCreate",
    meta: { requiresAuthUser: true, title: "発注登録" },
    component: () => import("../views/user/order/Create.vue")
  },
  {
    path: "/user/order/:order_id",
    name: "UserOrderDetail",
    meta: { requiresAuthUser: true, title: "発注詳細" },
    component: () => import("../views/user/order/_id/Index.vue")
  },
  {
    path: "/user/order/:order_id/create",
    name: "UserOrderCopyCreate",
    meta: { requiresAuthUser: true, title: "発注登録" },
    component: () => import("../views/user/order/CopyCreate.vue")
  },
  {
    path: "/user/order/:order_id/edit",
    name: "UserOrderEdit",
    meta: { requiresAuthUser: true, title: "発注編集" },
    component: () => import("../views/user/order/_id/Edit.vue")
  },
  //===============================================================================
  //movement
  {
    path: "/user/movement/list",
    name: "UserMovementList",
    meta: { requiresAuthUser: true, title: "商品移動履歴一覧" },
    component: () => import("../views/user/movement/Index.vue")
  },
  //===============================================================================
  //inventory
  {
    path: "/user/inventory/list",
    name: "UserInventoryList",
    meta: { requiresAuthUser: true, title: "在庫一覧" },
    component: () => import("../views/user/inventory/Index.vue")
  },
  {
    path: "/user/inventory/alert_list",
    name: "UserAlertInventoryList",
    meta: { requiresAuthUser: true, title: "在庫アラート一覧" },
    component: () => import("../views/user/inventory/List.vue")
  },
  {
    path: "/user/inventory/:product_id",
    meta: { requiresAuthUser: true },
    component: () => import("../views/user/inventory/_id/Index.vue"),
    children: [
      {
        path: "state",
        name: "UserInventoryStockIndex",
        meta: { requiresAuthUser: true, title: "在庫詳細 / 状況一覧" },
        component: () => import("../views/user/stock/Index.vue")
      },
      {
        path: "stock",
        name: "UserInventoryStockList",
        meta: {
          requiresAuthUser: true,
          title: "在庫詳細 / LOT一覧"
        },
        component: () => import("../views/user/stock/List.vue")
      },
      {
        path: "movement",
        name: "UserInventoryMovementList",
        meta: { requiresAuthUser: true, title: "在庫詳細 / 商品移動履歴一覧" },
        component: () => import("../views/user/movement/List.vue")
      }
    ]
  },
  //===============================================================================
  // booster
  {
    path: "/user/booster",
    name: "UserBooster",
    meta: { requiresAuthUser: true, title: "Booster" },
    component: () => import("../views/user/booster/Home.vue")
  },
  //===============================================================================

  /****************************************************************************************
   * invoice_only
   */
  //===============================================================================
  //invoice
  {
    path: "/invoice_only/invoice/list",
    name: "InvoiceOnlyInvoiceList",
    meta: { requiresAuthInvoiceOnly: true, title: "請求書一覧" },
    component: () => import("../views/invoice_only/invoice/Index.vue")
  },
  {
    path: "/invoice_only/invoice/:invoice_id",
    name: "InvoiceOnlyInvoiceDetail",
    meta: { requiresAuthInvoiceOnly: true, title: "請求書詳細" },
    component: () => import("../views/invoice_only/invoice/_id/Index.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuthAdmin)) {
    if (!store.getters["auth/isAdmin"]) {
      store.commit("login/redirectUrl", to.fullPath);
      next({
        name: "Login"
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresAuthUser)) {
    if (!store.getters["auth/isUser"]) {
      store.commit("login/redirectUrl", to.fullPath);
      next({
        name: "Login"
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters["auth/isAuthorized"]) {
      store.commit("login/redirectUrl", to.fullPath);
      next({
        name: "Login"
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
