import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  ILotListRequest,
  ILotCreateRequest,
  ILotUpdateRequest,
  LotState,
  ILot,
  ILotRequest
} from "@/types/lot";

export const actions: ActionTree<LotState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: ILotListRequest) {
    try {
      const response = await axios.get<IPagination<ILot>>("/admin/lot", {
        params
      });
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGetLotSegments({ commit, state }, params: ILotListRequest) {
    if (state.pagination && params.product_id) {
      const isProduct = state.pagination.data.some(
        lot => lot.product_id === params.product_id
      );
      if (isProduct) {
        return; // すでに取得済みのproductだった場合
      }
    }
    try {
      const response = await axios.get<IPagination<ILot>>("/admin/lot", {
        params
      });
      if (state.pagination) {
        commit("paginationSegment", response.data);
      } else {
        commit("pagination", response.data);
      }
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, lot_id: number) {
    try {
      const response = await axios.get<ILot>(`/admin/lot/${lot_id}`);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { lot_id: number; params: ILotUpdateRequest }
  ) {
    try {
      const response = await axios.patch<ILot>(
        `/admin/lot/${data.lot_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: ILotCreateRequest) {
    try {
      const response = await axios.post<ILot[]>("/admin/lot", params);
      commit("multiple", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, lot_id: number) {
    try {
      await axios.delete(`/admin/lot/${lot_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // 二重登録チェック
  async adminIsUniqueLot(state, params: ILotRequest) {
    try {
      const response = await axios.get<boolean>("/admin/lot/is_unique_lot", {
        params
      });
      return response.data;
    } catch (error) {
      return false;
    }
  },

  //フラッシュメッセージなし
  async adminUpdateNoneMessage(
    { commit },
    data: { lot_id: number; params: ILotUpdateRequest }
  ) {
    try {
      const response = await axios.patch<ILot>(
        `/admin/lot/${data.lot_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async adminCreateNoneMessage({ commit }, params: ILotCreateRequest) {
    try {
      const response = await axios.post<ILot[]>("/admin/lot", params);
      commit("multiple", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async adminDeleteNoneMessage({ commit }, lot_id: number) {
    try {
      await axios.delete(`/admin/lot/${lot_id}`);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: ILotListRequest) {
    try {
      const response = await axios.get<IPagination<ILot>>("/user/lot", {
        params
      });
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGetLotSegments({ commit, state }, params: ILotListRequest) {
    if (state.pagination && params.product_id) {
      const isProduct = state.pagination.data.some(
        lot => lot.product_id === params.product_id
      );
      if (isProduct) {
        return; // すでに取得済みのproductだった場合
      }
    }
    try {
      const response = await axios.get<IPagination<ILot>>("/user/lot", {
        params
      });
      if (state.pagination) {
        commit("paginationSegment", response.data);
      } else {
        commit("pagination", response.data);
      }
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, lot_id: number) {
    try {
      const response = await axios.get<ILot>(`/user/lot/${lot_id}`);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { lot_id: number; params: ILotUpdateRequest }
  ) {
    try {
      const response = await axios.patch<ILot>(
        `/user/lot/${data.lot_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: ILotCreateRequest) {
    try {
      const response = await axios.post<ILot[]>("/user/lot", params);
      commit("multiple", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, lot_id: number) {
    try {
      await axios.delete(`/user/lot/${lot_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // 二重登録チェック
  async userIsUniqueLot(state, params: ILotRequest) {
    try {
      const response = await axios.get<boolean>("/user/lot/is_unique_lot", {
        params
      });
      return response.data;
    } catch (error) {
      return false;
    }
  },

  //フラッシュメッセージなし
  async userUpdateNoneMessage(
    { commit },
    data: { lot_id: number; params: ILotUpdateRequest }
  ) {
    try {
      const response = await axios.patch<ILot>(
        `/user/lot/${data.lot_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async userCreateNoneMessage({ commit }, params: ILotCreateRequest) {
    try {
      const response = await axios.post<ILot[]>("/user/lot", params);
      commit("multiple", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async userDeleteNoneMessage({ commit }, lot_id: number) {
    try {
      await axios.delete(`/user/lot/${lot_id}`);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  }
};

export default actions;
