import Vue from "vue";
import { Mixin } from "vue-mixin-decorator";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import "dayjs/locale/ja";
dayjs.locale("ja");
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

@Mixin
class GeneralMixin extends Vue {
  public today = dayjs().format("YYYY-MM-DD");
  public list_height = 0;

  // 今日の日付を取得
  public toDay() {
    return this.today;
  }

  //YYYY-MM-DDを年月日に変換
  public dateFormatJP(date: string) {
    return date ? dayjs(date).format("YYYY年MM月DD日") : "";
  }

  //YYYY-MM-DDを年月日(英語圏)に変換
  public dateFormatEN(date: string) {
    return date ? dayjs(date).locale("en").format("MMMM D, YYYY") : "";
  }

  //西暦省略
  public omitAD(date: string) {
    return date ? dayjs(date).format("MM-DD") : "";
  }

  //CSV入力MM月DD日を初期化しYYYY-MM-DDに変換
  public initializeDateForCsv(date: string) {
    return date ? dayjs(date, "M月D日").format("YYYY-MM-DD") : "";
  }

  //今日より前の日付か比較
  public isBeforeDay(date: string) {
    return date ? dayjs(date).isBefore(this.today) : "";
  }

  //今日以前の日付か比較
  public isSameOrBeforeDay(date: string) {
    return date ? dayjs(date).isSameOrBefore(this.today) : "";
  }

  //今月より何ヶ月前か取得
  public diffMonth(date: string) {
    return date ? dayjs().diff(dayjs(date), "month") : 0;
  }

  //今月から12ヶ月前の年月を取得
  public lastYearMonth() {
    return dayjs().subtract(12, "M").format("YYYY-MM");
  }

  // 要素の高さを取得
  public getHeight(id: string) {
    const element = document.getElementById(id);
    return element ? element.offsetHeight : 0;
  }

  // データテーブルの高さを計算
  public heightCalc() {
    const window_height = window.innerHeight;
    const app_bar_height = this.getHeight("app_bar");
    const search_height = this.getHeight("search");
    const tab_height = this.getHeight("tab");
    const top_height = this.getHeight("top");
    const pagination_height = this.getHeight("pagination");
    this.list_height =
      window_height -
      app_bar_height -
      search_height -
      tab_height -
      top_height -
      pagination_height;
  }

  // データテーブルの高さをセット
  private mounted() {
    // アクセス時
    this.heightCalc();
    // リサイズ時
    window.addEventListener("resize", () => {
      this.heightCalc();
    });
  }

  // 文字数カウンター
  public inputCounter(value: string) {
    let len = 0;
    for (let i = 0; i < value.length; i++) {
      // 1バイト以外は２カウント、それ以外は１カウント
      value[i].match(/^[^\x01-\x7E\uFF61-\uFF9F]+$/) ? (len += 2) : (len += 1);
    }
    return len;
  }

  // キーコード判定(半角英数記号)
  public keyCodeDetermine(code: string) {
    return this.showKeyCodeList.includes(code);
  }

  // キーコード表示可能キー
  public showKeyCodeList = [
    "KeyA",
    "KeyB",
    "KeyC",
    "KeyD",
    "KeyE",
    "KeyF",
    "KeyG",
    "KeyH",
    "KeyI",
    "KeyJ",
    "KeyK",
    "KeyL",
    "KeyM",
    "KeyN",
    "KeyO",
    "KeyP",
    "KeyQ",
    "KeyR",
    "KeyS",
    "KeyT",
    "KeyU",
    "KeyV",
    "KeyW",
    "KeyX",
    "KeyY",
    "KeyZ",
    "Digit0",
    "Digit1",
    "Digit2",
    "Digit3",
    "Digit4",
    "Digit5",
    "Digit6",
    "Digit7",
    "Digit8",
    "Digit9",
    "Space",
    "Numpad0",
    "Numpad1",
    "Numpad2",
    "Numpad3",
    "Numpad4",
    "Numpad5",
    "Numpad6",
    "Numpad7",
    "Numpad8",
    "Numpad9",
    "NumpadAdd",
    "NumpadSubtract",
    "NumpadMultiply",
    "NumpadDivide",
    "NumpadComma",
    "NumpadDecimal",
    "NumpadEqual",
    "Backquote",
    "Minus",
    "Equal",
    "BracketLeft",
    "BracketRight",
    "Backslash",
    "Semicolon",
    "Quote",
    "Comma",
    "Period",
    "Slash"
  ];

  public prefectureList = [
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県"
  ];
}

export default GeneralMixin;
