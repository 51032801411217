import Vue from "vue";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  IMakerListRequest,
  IMakerCreateRequest,
  IMakerUpdateRequest,
  MakerState,
  IMaker,
  IMakerRequest
} from "@/types/maker";
import axios from "axios";

export const actions: ActionTree<MakerState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: IMakerListRequest) {
    try {
      const response = await axios.get<IPagination<IMaker>>("/admin/maker", {
        params
      });
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, maker_id: number) {
    try {
      const response = await axios.get<IMaker>(`/admin/maker/${maker_id}`);

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { maker_id: number; params: IMakerUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IMaker>(
        `/admin/maker/${data.maker_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: IMakerCreateRequest) {
    try {
      const response = await axios.post<IMaker>("/admin/maker", params);

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, maker_id: number) {
    try {
      await axios.delete(`/admin/maker/${maker_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // 二重登録チェック
  async adminIsUniqueName(state, params: IMakerRequest) {
    try {
      const response = await axios.get<boolean>("/admin/maker/is_unique_name", {
        params
      });
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async adminIsUniqueShortName(state, params: IMakerRequest) {
    try {
      const response = await axios.get<boolean>(
        "/admin/maker/is_unique_short_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async adminIsUniqueOrderNoName(state, params: IMakerRequest) {
    try {
      const response = await axios.get<boolean>(
        "/admin/maker/is_unique_order_no_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: IMakerListRequest) {
    try {
      const response = await axios.get<IPagination<IMaker>>("/user/maker", {
        params
      });
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, maker_id: number) {
    try {
      const response = await axios.get<IMaker>(`/user/maker/${maker_id}`);

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { maker_id: number; params: IMakerUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IMaker>(
        `/user/maker/${data.maker_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: IMakerCreateRequest) {
    try {
      const response = await axios.post<IMaker>("/user/maker", params);

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, maker_id: number) {
    try {
      await axios.delete(`/user/maker/${maker_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // nominalUser二重登録チェック
  async userIsUniqueName(state, params: IMakerRequest) {
    try {
      const response = await axios.get<boolean>("/user/maker/is_unique_name", {
        params
      });
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async userIsUniqueShortName(state, params: IMakerRequest) {
    try {
      const response = await axios.get<boolean>(
        "/user/maker/is_unique_short_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async userIsUniqueOrderNoName(state, params: IMakerRequest) {
    try {
      const response = await axios.get<boolean>(
        "/user/maker/is_unique_order_no_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  }
};

export default actions;
