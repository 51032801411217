import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { ShipSlipState } from "@/types/ship_slip";

const state: ShipSlipState = {
  duplicate_product: [],
  invoice_duplicate_product: []
};

export const ship_slip: Module<ShipSlipState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
