import Vue from "vue";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  IPaymentTermListRequest,
  IPaymentTermCreateRequest,
  IPaymentTermUpdateRequest,
  PaymentTermState,
  IPaymentTerm,
  IExceptPaymentTermRequest
} from "@/types/payment_term";
import axios from "axios";

export const actions: ActionTree<PaymentTermState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: IPaymentTermListRequest) {
    try {
      const response = await axios.get<IPagination<IPaymentTerm>>(
        "/admin/payment_term",
        {
          params
        }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, payment_term_id: number) {
    try {
      const response = await axios.get<{ paymentTermViewModel: IPaymentTerm }>(
        `/admin/payment_term/${payment_term_id}`
      );
      commit("single", response.data.paymentTermViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { payment_term_id: number; params: IPaymentTermUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IPaymentTerm>(
        `/admin/payment_term/${data.payment_term_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: IPaymentTermCreateRequest) {
    try {
      const response = await axios.post<IPaymentTerm>(
        "/admin/payment_term",
        params
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, payment_term_id: number) {
    try {
      await axios.delete(`/admin/payment_term/${payment_term_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  //入金予定日計算問い合わせ
  async adminExpectPayment({ commit }, params: IExceptPaymentTermRequest) {
    try {
      const response = await axios.get<IPaymentTerm>(
        "/admin/payment_term/expect_payment",
        {
          params
        }
      );
      commit("except_payment_term", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },
  //以上未満登録チェック
  async adminIsStored(state, params: { customer_id: number }) {
    try {
      const response = await axios.get<{ is_under_amount_status: number }>(
        "/admin/payment_term/is_stored",
        {
          params
        }
      );
      return response.data.is_under_amount_status;
    } catch (error) {
      return 0;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: IPaymentTermListRequest) {
    try {
      const response = await axios.get<IPagination<IPaymentTerm>>(
        "/user/payment_term",
        {
          params
        }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, payment_term_id: number) {
    try {
      const response = await axios.get<{ paymentTermViewModel: IPaymentTerm }>(
        `/user/payment_term/${payment_term_id}`
      );

      commit("single", response.data.paymentTermViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { payment_term_id: number; params: IPaymentTermUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IPaymentTerm>(
        `/user/payment_term/${data.payment_term_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: IPaymentTermCreateRequest) {
    try {
      const response = await axios.post<IPaymentTerm>(
        "/user/payment_term",
        params
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, payment_term_id: number) {
    try {
      await axios.delete(`/user/payment_term/${payment_term_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  //入金予定日計算問い合わせ
  async userExpectPayment({ commit }, params: IExceptPaymentTermRequest) {
    try {
      const response = await axios.get<IPaymentTerm>(
        "/user/payment_term/expect_payment",
        {
          params
        }
      );
      commit("except_payment_term", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },
  //以上未満登録チェック
  async userIsStored(state, params: { customer_id: number }) {
    try {
      const response = await axios.get<{ is_under_amount_status: number }>(
        "/user/payment_term/is_stored",
        {
          params
        }
      );
      return response.data.is_under_amount_status;
    } catch (error) {
      return 0;
    }
  }
};

export default actions;
