import { IWarehouse, IWarehouseRequest } from "@/types/warehouse";
import { ISelectItem } from "@/types";

export class Warehouse implements IWarehouse {
  public id = 0;
  public name = "";
  public postalcode = "";
  public address = "";
  public created_at = "";
  public updated_at = "";
  public deleted_at = "";

  public constructor(params?: IWarehouse) {
    if (!params) {
      return;
    }
    this.id = params.id;
    this.name = params.name;
    this.postalcode = params.postalcode;
    this.address = params.address;
    this.created_at = params.created_at;
    this.updated_at = params.updated_at;
    this.deleted_at = params.deleted_at;
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.name,
      value: this.id
    };
  }

  public static toSelectItem(warehouse: IWarehouse): ISelectItem {
    return {
      text: warehouse.name,
      value: warehouse.id
    };
  }
}

export class WarehouseRequest implements IWarehouseRequest {
  public name = "";
  public postalcode = "";
  public address = "";

  public createFromWarehouse(param: IWarehouse) {
    this.name = param.name;
    this.postalcode = param.postalcode;
    this.address = param.address;
  }
}
