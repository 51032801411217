import { IZipaddress } from "@/types/zipaddress";

export class Zipaddress implements IZipaddress {
  public pref;
  public city;
  public town;
  public office;

  public constructor(params?: IZipaddress) {
    this.pref = params ? params.pref : "";
    this.city = params ? params.city : "";
    this.town = params ? params.town : "";
    this.office = params ? params.office : "";
  }
}
