import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  IInvoiceListRequest,
  IInvoiceCreateRequest,
  IInvoiceUpdateRequest,
  InvoiceState,
  IInvoice,
  IInvoiceCalculation
} from "@/types/invoice";

export const actions: ActionTree<InvoiceState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: IInvoiceListRequest) {
    try {
      const response = await axios.get<IPagination<IInvoice>>(
        "/admin/invoice",
        {
          params
        }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, invoice_id: number) {
    try {
      const response = await axios.get<{ invoiceDetailViewModel: IInvoice }>(
        `/admin/invoice/${invoice_id}`
      );
      commit("single", response.data.invoiceDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { invoice_id: number; params: IInvoiceUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IInvoice>(
        `/admin/invoice/${data.invoice_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: IInvoiceCreateRequest) {
    try {
      const response = await axios.post<IInvoice>("/admin/invoice", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, invoice_id: number) {
    try {
      await axios.delete(`/admin/invoice/${invoice_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  //入金認定日登録編集
  async adminRegistrationPaidDate(
    { commit },
    data: { invoice_id: number; requestPaidDate: { paid_date: string } }
  ) {
    try {
      await axios.put<IInvoice>(
        `/admin/invoice/${data.invoice_id}/change_collected`,
        data.requestPaidDate
      );
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //印刷フラグ更新
  async adminIsPrinted(
    { commit },
    data: { invoice_id: number; requestIsPrinted: { is_printed: boolean } }
  ) {
    try {
      await axios.put<IInvoice>(
        `/admin/invoice/${data.invoice_id}/change_is_printed`,
        data.requestIsPrinted
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  // 請求金額計算
  async adminInvoiceCalculation({ commit }, params: IInvoiceListRequest) {
    try {
      const response = await axios.get<{ viewModel: IInvoiceCalculation }>(
        "/admin/invoice/calc",
        {
          params
        }
      );
      commit("invoice_calculation", response.data.viewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: IInvoiceListRequest) {
    try {
      const response = await axios.get<IPagination<IInvoice>>("/user/invoice", {
        params
      });
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, invoice_id: number) {
    try {
      const response = await axios.get<{ invoiceDetailViewModel: IInvoice }>(
        `/user/invoice/${invoice_id}`
      );
      commit("single", response.data.invoiceDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { invoice_id: number; params: IInvoiceUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IInvoice>(
        `/user/invoice/${data.invoice_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: IInvoiceCreateRequest) {
    try {
      const response = await axios.post<IInvoice>("/user/invoice", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, invoice_id: number) {
    try {
      await axios.delete(`/user/invoice/${invoice_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  //入金認定日登録編集
  async userRegistrationPaidDate(
    { commit },
    data: { invoice_id: number; requestPaidDate: { paid_date: string } }
  ) {
    try {
      await axios.put<IInvoice>(
        `/user/invoice/${data.invoice_id}/change_collected`,
        data.requestPaidDate
      );
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //印刷フラグ更新
  async userIsPrinted(
    { commit },
    data: { invoice_id: number; requestIsPrinted: { is_printed: boolean } }
  ) {
    try {
      await axios.put<IInvoice>(
        `/user/invoice/${data.invoice_id}/change_is_printed`,
        data.requestIsPrinted
      );
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  // 請求金額計算
  async userInvoiceCalculation({ commit }, params: IInvoiceListRequest) {
    try {
      const response = await axios.get<{ viewModel: IInvoiceCalculation }>(
        "/user/invoice/calc",
        {
          params
        }
      );
      commit("invoice_calculation", response.data.viewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  //invoice_onlyメソッド
  async invoiceOnlyGetList({ commit }, params: IInvoiceListRequest) {
    try {
      const response = await axios.get<IPagination<IInvoice>>(
        "/invoice_only/invoice",
        {
          params
        }
      );

      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async invoiceOnlyGet({ commit }, invoice_id: number) {
    try {
      const response = await axios.get<{ invoiceDetailViewModel: IInvoice }>(
        `/invoice_only/invoice/${invoice_id}`
      );
      commit("single", response.data.invoiceDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  // 請求金額計算
  async invoiceOnlyInvoiceCalculation({ commit }, params: IInvoiceListRequest) {
    try {
      const response = await axios.get<{ viewModel: IInvoiceCalculation }>(
        "/invoice_only/invoice/calc",
        {
          params
        }
      );
      commit("invoice_calculation", response.data.viewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  }
};

export default actions;
