import Vue from "vue";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  IPaymentMethodListRequest,
  IPaymentMethodCreateRequest,
  IPaymentMethodUpdateRequest,
  PaymentMethodState,
  IPaymentMethod
} from "@/types/payment_method";
import axios from "axios";

export const actions: ActionTree<PaymentMethodState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: IPaymentMethodListRequest) {
    try {
      const response = await axios.get<IPagination<IPaymentMethod>>(
        "/admin/payment_method",
        {
          params
        }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, payment_method_id: number) {
    try {
      const response = await axios.get<IPaymentMethod>(
        `/admin/payment_method/${payment_method_id}`
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { payment_method_id: number; params: IPaymentMethodUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IPaymentMethod>(
        `/admin/payment_method/${data.payment_method_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: IPaymentMethodCreateRequest) {
    try {
      const response = await axios.post<IPaymentMethod>(
        "/admin/payment_method",
        params
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, payment_method_id: number) {
    try {
      await axios.delete(`/admin/payment_method/${payment_method_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: IPaymentMethodListRequest) {
    try {
      const response = await axios.get<IPagination<IPaymentMethod>>(
        "/user/payment_method",
        {
          params
        }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, payment_method_id: number) {
    try {
      const response = await axios.get<IPaymentMethod>(
        `/user/payment_method/${payment_method_id}`
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { payment_method_id: number; params: IPaymentMethodUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IPaymentMethod>(
        `/user/payment_method/${data.payment_method_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: IPaymentMethodCreateRequest) {
    try {
      const response = await axios.post<IPaymentMethod>(
        "/user/payment_method",
        params
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, payment_method_id: number) {
    try {
      await axios.delete(`/user/payment_method/${payment_method_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  //invoice_onlyメソッド
  async invoiceOnlyGetList({ commit }, params: IPaymentMethodListRequest) {
    try {
      const response = await axios.get<IPagination<IPaymentMethod>>(
        "/invoice_only/payment_method",
        {
          params
        }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  }
};

export default actions;
