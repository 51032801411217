import axios from "axios";
import { saveAs } from "file-saver";
import { Module } from "vuex";
import { RootState } from "@/types";
import { ReceivedOrderState } from "@/types/received_order";
import { IInventoryCsvRequest, IMovementCsvRequest } from "@/types/movement";
import { IShipmentListRequest } from "@/types/shipment";
import qs from "qs";

/*
 * Interface
 */
export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    //adminメソッド
    adminInventoryExport: async ({ commit }, params: IInventoryCsvRequest) => {
      try {
        const response = await axios.get(`/admin/inventory/inventory_export`, {
          params,
          responseType: "blob",
          headers: { Accept: "application/csv" }
        });
        const blob = new Blob([response.data], { type: response.data.type });
        saveAs(blob, `在庫表${params.search_span_end}.csv`);
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    adminCustomerExport: async ({ commit }) => {
      try {
        const response = await axios.get(`/admin/customer/customer_export`, {
          responseType: "blob",
          headers: { Accept: "application/csv" }
        });
        const blob = new Blob([response.data], { type: response.data.type });
        saveAs(blob, `顧客リスト.csv`);
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    adminProductExport: async ({ commit }) => {
      try {
        const response = await axios.get(`/admin/product/product_export`, {
          responseType: "blob",
          headers: { Accept: "application/csv" }
        });
        const blob = new Blob([response.data], { type: response.data.type });
        saveAs(blob, `商品リスト.csv`);
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    adminMovementExport: async ({ commit }, params: IMovementCsvRequest) => {
      try {
        const response = await axios.get(`/admin/movement/movement_export`, {
          params,
          responseType: "blob",
          headers: { Accept: "application/csv" }
        });
        const blob = new Blob([response.data], { type: response.data.type });
        saveAs(
          blob,
          `商品移動履歴リスト_${
            (params.status == 1 ? "入荷" : params.status == 2 ? "出荷" : "") +
            (params.search_span_start
              ? "_"
              : params.search_span_end
              ? "_"
              : "") +
            (params.search_span_start || "") +
            (params.search_span_start
              ? "〜"
              : params.search_span_end
              ? "〜"
              : "") +
            (params.search_span_end || "")
          }.csv`
        );
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    adminSlipExport: async ({ commit }, params: IShipmentListRequest) => {
      try {
        const paramsSerializer = (params: IShipmentListRequest) =>
          qs.stringify(params);
        const response = await axios.get(`/admin/shipment/slip_export`, {
          paramsSerializer,
          params,
          responseType: "blob",
          headers: { Accept: "application/csv" }
        });
        const blob = new Blob([response.data], { type: response.data.type });
        saveAs(blob, `納品リスト${params.search_span_start}.csv`);
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    //nominalUserメソッド
    userInventoryExport: async ({ commit }, params: IInventoryCsvRequest) => {
      try {
        const response = await axios.get(`/user/inventory/inventory_export`, {
          params,
          responseType: "blob",
          headers: { Accept: "application/csv" }
        });
        const blob = new Blob([response.data], { type: response.data.type });
        saveAs(blob, `在庫表${params.search_span_end}.csv`);
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    userCustomerExport: async ({ commit }) => {
      try {
        const response = await axios.get(`/user/customer/customer_export`, {
          responseType: "blob",
          headers: { Accept: "application/csv" }
        });
        const blob = new Blob([response.data], { type: response.data.type });
        saveAs(blob, `顧客リスト.csv`);
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    userProductExport: async ({ commit }) => {
      try {
        const response = await axios.get(`/user/product/product_export`, {
          responseType: "blob",
          headers: { Accept: "application/csv" }
        });
        const blob = new Blob([response.data], { type: response.data.type });
        saveAs(blob, `商品リスト.csv`);
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    },

    userMovementExport: async ({ commit }, params: IMovementCsvRequest) => {
      try {
        const response = await axios.get(`/user/movement/movement_export`, {
          params,
          responseType: "blob",
          headers: { Accept: "application/csv" }
        });
        const blob = new Blob([response.data], { type: response.data.type });
        saveAs(
          blob,
          `商品移動履歴リスト_${
            (params.status == 1 ? "入荷" : params.status == 2 ? "出荷" : "") +
            (params.search_span_start
              ? "_"
              : params.search_span_end
              ? "_"
              : "") +
            (params.search_span_start || "") +
            (params.search_span_start
              ? "〜"
              : params.search_span_end
              ? "〜"
              : "") +
            (params.search_span_end || "")
          }.csv`
        );
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        // Vue.prototype.$flashStorage.flash("ダウンロード失敗", "error", {
        //   timeout: 3000,
        //   important: true
        // });
        return false;
      }
    }
  }
} as Module<ReceivedOrderState, RootState>;
