import { MutationTree } from "vuex";
import { IUser, UserState } from "@/types/user";
import { User } from "@/models/user";
import { removeCookie } from "tiny-cookie";

const mutations: MutationTree<UserState> = {
  set: (state, item: IUser) => {
    state.user = new User(item);
  },
  clearUser: state => {
    state.user = new User();
  },
  clearToken: () => {
    removeCookie("access_token");
  }
};

export default mutations;
