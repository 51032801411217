import { ISupplier, ISupplierRequest } from "@/types/supplier";
import { ISelectItem } from "@/types";
import { IMaker } from "@/types/maker";
import { IUser } from "@/types/user";
import { ISupplierStaff } from "@/types/supplier_staff";

export class Supplier implements ISupplier {
  public id;
  public name;
  public kana;
  public short_name;
  public postalcode;
  public address;
  public tel;
  public fax;
  public payment_term;
  public memo;
  public registration_status;
  public registration_no;
  public schedule_date;
  public supplier_staff: ISupplierStaff[];
  public maker: IMaker[];
  public makers: number[];
  public makers_name: string[];
  public user: IUser[] = [];
  public users: number[] = [];
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: ISupplier) {
    this.id = params ? params.id : 0;
    this.name = params ? params.name : "";
    this.kana = params ? params.kana : "";
    this.short_name = params ? params.short_name : "";
    this.postalcode = params ? params.postalcode : "";
    this.address = params ? params.address : "";
    this.tel = params ? params.tel : "";
    this.fax = params ? params.fax : "";
    this.payment_term = params ? params.payment_term : "";
    this.memo = params ? params.memo : "";
    this.registration_status = params ? params.registration_status : 0;
    this.registration_no = params ? params.registration_no : "";
    this.schedule_date = params ? params.schedule_date : "";
    this.supplier_staff = params ? params.supplier_staff : [];
    this.maker = params ? params.maker : [];
    this.makers = params ? params.makers : [];
    this.makers_name = params ? params.makers_name : [];
    this.user = params ? params.user : [];
    this.users = params ? params.users : [];
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.name,
      value: this.id
    };
  }

  public toRelationSupplierSelectItem(): ISelectItem {
    return {
      text: this.name,
      value: this.id
    };
  }
}

export class SupplierRequest implements ISupplierRequest {
  public name = "";
  public kana = "";
  public short_name = "";
  public postalcode = "";
  public address = "";
  public tel = "";
  public fax = "";
  public payment_term = "";
  public memo = "";
  public registration_status = 1;
  public registration_no = "";
  public schedule_date = "";
  public maker: IMaker[] = [];
  public makers: number[] = [];
  public users: number[] = [];

  public createFromSupplier(supplier: ISupplier) {
    this.name = supplier.name || "";
    this.kana = supplier.kana || "";
    this.short_name = supplier.short_name || "";
    this.postalcode = supplier.postalcode || "";
    this.address = supplier.address || "";
    this.tel = supplier.tel || "";
    this.fax = supplier.fax || "";
    this.payment_term = supplier.payment_term || "";
    this.memo = supplier.memo || "";
    this.registration_status = supplier.registration_status || 1;
    this.registration_no = supplier.registration_no || "";
    this.schedule_date = supplier.schedule_date || "";
    this.maker = supplier.maker || [];
    this.makers = supplier.makers || [];
    this.users = supplier.users || [];
  }
}
