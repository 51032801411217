import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  IStockListRequest,
  IStockCreateRequest,
  IStockUpdateRequest,
  StockState,
  IStock,
  ITransportUnitPriceUpdateRequest
} from "@/types/stock";

export const actions: ActionTree<StockState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: IStockListRequest) {
    try {
      const response = await axios.get<IPagination<IStock>>("/admin/stock", {
        params
      });
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGetStockSegments({ commit, state }, params: IStockListRequest) {
    if (state.pagination && params.product_id) {
      const isStock = state.pagination.data.some(
        stocks => stocks.product_id === params.product_id
      );
      if (isStock) {
        return; // すでに取得済みのstockだった場合
      }
    }
    try {
      const response = await axios.get<IPagination<IStock[]>>("/admin/stock", {
        params
      });
      if (state.pagination) {
        commit("paginationSegment", response.data);
      } else {
        commit("pagination", response.data);
      }
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, stock_id: number) {
    try {
      const response = await axios.get<IStock>(`/admin/stock/${stock_id}`);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { stock_id: number; params: IStockUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IStock>(
        `/admin/stock/${data.stock_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async adminCreate({ commit }, params: IStockCreateRequest) {
    try {
      const response = await axios.post<IStock>("/admin/stock", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async adminDelete({ commit }, stock_id: number) {
    try {
      await axios.delete(`/api/stock/${stock_id}`);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  //輸送費単価登録編集
  async adminTransportUnitPrice(
    { commit },
    data: {
      stock_id: number;
      requestTransportUnitPrice: { transport_unit_price: number };
    }
  ) {
    try {
      const response = await axios.put<IStock>(
        `/admin/stock/${data.stock_id}/update_transport_unit_price`,
        data.requestTransportUnitPrice
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //輸送費単価編集フォーム用
  async adminUpdateTransportUnitPrice(
    { commit },
    params: ITransportUnitPriceUpdateRequest
  ) {
    try {
      const response = await axios.put<IStock>(
        `/admin/stock/update_transport_unit_price`,
        params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //メモ登録編集
  async adminMemoRegistration(
    { commit },
    data: { stock_id: number; requestMemo: { memo: string } }
  ) {
    try {
      const response = await axios.put<IStock>(
        `/admin/stock/${data.stock_id}/update_memo`,
        data.requestMemo
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: IStockListRequest) {
    try {
      const response = await axios.get<IPagination<IStock>>("/user/stock", {
        params
      });
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGetStockSegments({ commit, state }, params: IStockListRequest) {
    if (state.pagination && params.product_id) {
      const isStock = state.pagination.data.some(
        stocks => stocks.product_id === params.product_id
      );
      if (isStock) {
        return; // すでに取得済みのstockだった場合
      }
    }
    try {
      const response = await axios.get<IPagination<IStock[]>>("/user/stock", {
        params
      });
      if (state.pagination) {
        commit("paginationSegment", response.data);
      } else {
        commit("pagination", response.data);
      }
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, stock_id: number) {
    try {
      const response = await axios.get<IStock>(`/user/stock/${stock_id}`);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { stock_id: number; params: IStockUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IStock>(
        `/user/stock/${data.stock_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async userCreate({ commit }, params: IStockCreateRequest) {
    try {
      const response = await axios.post<IStock>("/user/stock", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async userDelete({ commit }, stock_id: number) {
    try {
      await axios.delete(`/api/stock/${stock_id}`);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  //輸送費単価登録編集
  async userTransportUnitPrice(
    { commit },
    data: {
      stock_id: number;
      requestTransportUnitPrice: { transport_unit_price: number };
    }
  ) {
    try {
      const response = await axios.put<IStock>(
        `/user/stock/${data.stock_id}/update_transport_unit_price`,
        data.requestTransportUnitPrice
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //輸送費単価編集フォーム用
  async userUpdateTransportUnitPrice(
    { commit },
    params: ITransportUnitPriceUpdateRequest
  ) {
    try {
      const response = await axios.put<IStock>(
        `/user/stock/update_transport_unit_price`,
        params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  //メモ登録編集
  async userMemoRegistration(
    { commit },
    data: { stock_id: number; requestMemo: { memo: string } }
  ) {
    try {
      const response = await axios.put<IStock>(
        `/user/stock/${data.stock_id}/update_memo`,
        data.requestMemo
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  }
};

export default actions;
