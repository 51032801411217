import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { ProductOrderStatusState } from "@/types/product_order_status";

const state: ProductOrderStatusState = {
  order_product: [],
  product_received_order: []
};

export const product_order_status: Module<ProductOrderStatusState, RootState> =
  {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
  };
