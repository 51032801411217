import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  IProductShipmentListRequest,
  IProductShipmentCreateRequest,
  IProductShipmentUpdateRequest,
  ProductShipmentState,
  IProductShipment
} from "@/types/product_shipment";
import axios from "axios";

export const actions: ActionTree<ProductShipmentState, RootState> = {
  //adminメソッド

  async adminGetList({ commit }, params: IProductShipmentListRequest) {
    try {
      const response = await axios.get<IPagination<IProductShipment>>(
        "/admin/product_shipment",
        {
          params
        }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, product_shipment_id: number) {
    try {
      const response = await axios.get<IProductShipment>(
        `/admin/product_shipment/${product_shipment_id}`
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { product_shipment_id: number; params: IProductShipmentUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IProductShipment>(
        `/admin/product_shipment/${data.product_shipment_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async adminCreate({ commit }, params: IProductShipmentCreateRequest) {
    try {
      const response = await axios.post<IProductShipment>(
        "/admin/product_shipment",
        params
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async adminDelete({ commit }, product_shipment_id: number) {
    try {
      await axios.delete(`/api/product_shipment/${product_shipment_id}`);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: IProductShipmentListRequest) {
    try {
      const response = await axios.get<IPagination<IProductShipment>>(
        "/user/product_shipment",
        {
          params
        }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, product_shipment_id: number) {
    try {
      const response = await axios.get<IProductShipment>(
        `/user/product_shipment/${product_shipment_id}`
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { product_shipment_id: number; params: IProductShipmentUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IProductShipment>(
        `/user/product_shipment/${data.product_shipment_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async userCreate({ commit }, params: IProductShipmentCreateRequest) {
    try {
      const response = await axios.post<IProductShipment>(
        "/user/product_shipment",
        params
      );

      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  },

  async userDelete({ commit }, product_shipment_id: number) {
    try {
      await axios.delete(`/api/product_shipment/${product_shipment_id}`);
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      return false;
    }
  }
};

export default actions;
