import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { MovementStoringState } from "@/types/movement_storing";
import { MovementStoring } from "@/models/movement_storing";
import { Pagination } from "@/models/pagination";

const state: MovementStoringState = {
  movement_storing: new MovementStoring(),
  pagination: new Pagination()
};

export const movement_storing: Module<MovementStoringState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
