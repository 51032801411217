import { ITradeTerm, ITradeTermRequest } from "@/types/trade_term";
import { ISelectItem } from "@/types";

export class TradeTerm implements ITradeTerm {
  public id;
  public terms;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: ITradeTerm) {
    this.id = params ? params.id : 0;
    this.terms = params ? params.terms : "";
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.terms,
      value: this.id
    };
  }
}

export class TradeTermRequest implements ITradeTermRequest {
  public terms = "";

  public constructor(params?: ITradeTermRequest) {
    if (!params) {
      return;
    }

    this.terms = params.terms;
  }

  public createFromTradeTerm(trade_term: ITradeTerm): void {
    this.terms = trade_term.terms;
  }
}
