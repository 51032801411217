import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { ReceivedOrdersManagementState } from "@/types/received_orders_management";
import { ReceivedOrdersManagement } from "@/models/received_orders_management";
import { Pagination } from "@/models/pagination";

const state: ReceivedOrdersManagementState = {
  received_orders_management: new ReceivedOrdersManagement(),
  pagination: new Pagination()
};

export const received_orders_management: Module<
  ReceivedOrdersManagementState,
  RootState
> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
