import { MutationTree } from "vuex";
import {
  ArrangementMethodState,
  IArrangementMethod
} from "@/types/arrangement_method";
import { IPagination } from "@/types";
import { Pagination } from "@/models/pagination";
import { ArrangementMethod } from "@/models/arrangement_method";

const mutations: MutationTree<ArrangementMethodState> = {
  pagination: (state, items: IPagination<IArrangementMethod>) => {
    const params: IPagination<ArrangementMethod> = {
      current_page: items.current_page,
      first_page_url: items.first_page_url,
      from: items.from,
      last_page: items.last_page,
      last_page_url: items.last_page_url,
      next_page_url: items.next_page_url,
      path: items.path,
      per_page: items.per_page,
      prev_page_url: items.prev_page_url,
      to: items.to,
      total: items.total,
      data: items.data.map(item => new ArrangementMethod(item))
    };

    state.pagination = new Pagination(params);
  },
  single: (state, item: IArrangementMethod) => {
    state.arrangement_method = new ArrangementMethod(item);
  },
  clear: state => {
    state.arrangement_method = new ArrangementMethod();
    state.pagination = new Pagination();
  }
};

export default mutations;
