import { MutationTree } from "vuex";
import {
  IDuplicateProduct,
  IInvoiceDuplicateProduct,
  ShipSlipState
} from "@/types/ship_slip";
import { DuplicateProduct, InvoiceDuplicateProduct } from "@/models/ship_slip";

const mutations: MutationTree<ShipSlipState> = {
  duplicateProduct: (state, item: IDuplicateProduct[]) => {
    state.duplicate_product = item.map(item => new DuplicateProduct(item));
  },
  invoiceDuplicateProduct: (state, item: IInvoiceDuplicateProduct[]) => {
    state.invoice_duplicate_product = item.map(
      item => new InvoiceDuplicateProduct(item)
    );
  },
  clear: state => {
    state.duplicate_product = [];
  }
};

export default mutations;
