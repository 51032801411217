import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { InvoiceState } from "@/types/invoice";
import { Invoice, InvoiceCalculation } from "@/models/invoice";
import { Pagination } from "@/models/pagination";

const state: InvoiceState = {
  invoice: new Invoice(),
  pagination: new Pagination(),
  invoice_calculation: new InvoiceCalculation()
};

export const invoice: Module<InvoiceState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
