import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { MonthlyCountStockState } from "@/types/monthly_count_stock";

const state: MonthlyCountStockState = {
  storing_sum: 0,
  shipment_sum: 0,
  warehouse_stock_array: []
};

export const monthly_count_stock: Module<MonthlyCountStockState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
