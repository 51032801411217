import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  IPriceTagSupplierListRequest,
  IPriceTagSupplierCreateRequest,
  IPriceTagSupplierUpdateRequest,
  PriceTagSupplierState,
  IPriceTagSupplier,
  IPriceTagSupplierRequest
} from "@/types/price_tag_supplier";

export const actions: ActionTree<PriceTagSupplierState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: IPriceTagSupplierListRequest) {
    try {
      const response = await axios.get<IPagination<IPriceTagSupplier>>(
        "/admin/price_tag_supplier",
        { params }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, price_tag_supplier_id: number) {
    try {
      const response = await axios.get<{
        priceTagSupplierViewModel: IPriceTagSupplier;
      }>(`/admin/price_tag_supplier/${price_tag_supplier_id}`);
      commit("single", response.data.priceTagSupplierViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: {
      price_tag_supplier_id: number;
      params: IPriceTagSupplierUpdateRequest;
    }
  ) {
    try {
      const response = await axios.patch<IPriceTagSupplier>(
        `/admin/price_tag_supplier/${data.price_tag_supplier_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: IPriceTagSupplierCreateRequest) {
    try {
      const response = await axios.post<IPriceTagSupplier>(
        "/admin/price_tag_supplier",
        params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, price_tag_supplier_id: number) {
    try {
      await axios.delete(`/admin/price_tag_supplier/${price_tag_supplier_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // 二重登録チェック
  async adminIsUniqueSupplierPN(state, params: IPriceTagSupplierRequest) {
    try {
      const response = await axios.get<boolean>(
        "/admin/price_tag_supplier/is_unique_supplierPN",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: IPriceTagSupplierListRequest) {
    try {
      const response = await axios.get<IPagination<IPriceTagSupplier>>(
        "/user/price_tag_supplier",
        { params }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, price_tag_supplier_id: number) {
    try {
      const response = await axios.get<{
        priceTagSupplierViewModel: IPriceTagSupplier;
      }>(`/user/price_tag_supplier/${price_tag_supplier_id}`);
      commit("single", response.data.priceTagSupplierViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: {
      price_tag_supplier_id: number;
      params: IPriceTagSupplierUpdateRequest;
    }
  ) {
    try {
      const response = await axios.patch<IPriceTagSupplier>(
        `/user/price_tag_supplier/${data.price_tag_supplier_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: IPriceTagSupplierCreateRequest) {
    try {
      const response = await axios.post<IPriceTagSupplier>(
        "/user/price_tag_supplier",
        params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, price_tag_supplier_id: number) {
    try {
      await axios.delete(`/user/price_tag_supplier/${price_tag_supplier_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // nominalUser二重登録チェック
  async userIsUniqueSupplierPN(state, params: IPriceTagSupplierRequest) {
    try {
      const response = await axios.get<boolean>(
        "/user/price_tag_supplier/is_unique_supplierPN",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  }
};

export default actions;
