import {
  IProduct,
  IProductCustomerProduct,
  IProductRequest
} from "@/types/product";
import { ISelectItem } from "@/types";

export class Product implements IProduct {
  public id;
  public code;
  public code_eng;
  public name;
  public name_eng;
  public size;
  public size_eng;
  public division;
  public unit;
  public unit_price;
  public aggregate_type;
  public supplier_id;
  public maker_id;
  public stockage_quantity;
  public customer_product: IProductCustomerProduct[];
  public total_lot;
  public maker_name;
  public orders_count;
  public received_orders_count;
  public alert_quantity;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: IProduct) {
    this.id = params ? params.id : 0;
    this.code = params ? params.code : "";
    this.code_eng = params ? params.code_eng : "";
    this.name = params ? params.name : "";
    this.name_eng = params ? params.name_eng : "";
    this.size = params ? params.size : "";
    this.size_eng = params ? params.size_eng : "";
    this.division = params ? params.division : "";
    this.unit = params ? params.unit : "";
    this.unit_price = params ? params.unit_price : null;
    this.aggregate_type = params ? params.aggregate_type : "";
    this.supplier_id = params ? params.supplier_id : 0;
    this.maker_id = params ? params.maker_id : 0;
    this.stockage_quantity = params ? params.stockage_quantity : 0;
    this.customer_product = params ? params.customer_product : [];
    this.total_lot = params ? params.total_lot : 0;
    this.maker_name = params ? params.maker_name : "";
    this.orders_count = params ? params.orders_count : 0;
    this.received_orders_count = params ? params.received_orders_count : 0;
    this.alert_quantity = params ? params.alert_quantity ?? null : null;
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: "品名：" + this.name + " / " + "品番：" + (this.code || "ー"),
      value: this.id
    };
  }
}

export class ProductRequest implements IProductRequest {
  public code = "";
  public code_eng = "";
  public name = "";
  public name_eng = "";
  public size = "";
  public size_eng = "";
  public division = "";
  public unit = "";
  public unit_price: number | null = null;
  public aggregate_type = "";
  public supplier_id = 0;
  public maker_id = 0;
  public alert_quantity: number | null = null;
  public orders_count = 0;
  public received_orders_count = 0;

  public createFromProduct(params: IProduct): void {
    this.code = params.code || "";
    this.code_eng = params.code_eng || "";
    this.name = params.name || "";
    this.name_eng = params.name_eng || "";
    this.size = params.size || "";
    this.size_eng = params.size_eng || "";
    this.division = params.division || "";
    this.unit = params.unit || "";
    this.unit_price = params.unit_price || null;
    this.aggregate_type = params.aggregate_type || "";
    this.supplier_id = params.supplier_id || 0;
    this.maker_id = params.maker_id || 0;
    this.alert_quantity = params.alert_quantity ?? null;
    this.orders_count = params.orders_count || 0;
    this.received_orders_count = params.received_orders_count || 0;
  }
}
