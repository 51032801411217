import {
  IBooster,
  ICustomerCalc,
  IMonthlyCalc,
  IProductCalc,
  ISupplierCalc,
  ISalesCalc
} from "@/types/booster";

export class Booster implements IBooster {
  public buying_price;
  public selling_price;
  public gross_profit;
  public gross_profit_rate;
  public monthly_calc: IMonthlyCalc[];
  public sales_calc: ISalesCalc[];
  public supplier_calc: ISupplierCalc[];
  public product_calc: IProductCalc;
  public customer_calc: ICustomerCalc[];

  public constructor(params?: IBooster) {
    this.buying_price = params ? params.buying_price : 0;
    this.selling_price = params ? params.selling_price : 0;
    this.gross_profit = params ? params.gross_profit : 0;
    this.gross_profit_rate = params ? params.gross_profit_rate : 0;
    this.monthly_calc = params ? params.monthly_calc : [];
    this.sales_calc = params ? params.sales_calc : [];
    this.supplier_calc = params ? params.supplier_calc : [];
    this.product_calc = params
      ? params.product_calc
      : { profit: [], shipment_quantity: [] };
    this.customer_calc = params ? params.customer_calc : [];
  }
}
