import Vue from "vue";
import axios from "axios";
import { Module } from "vuex";
import { RootState } from "@/types";
import { ReceivedOrderState } from "@/types/received_order";

/*
 * Interface
 */
export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    //adminメソッド
    //発注商品メモ、受注商品メモ編集
    async adminUpdateNote(
      { commit },
      notes: [{ id: number | null; content: string | null }]
    ) {
      try {
        await axios.put(`/admin/note/update_content`, {
          notes
        });
        commit("error/resetError", null, { root: true });
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        Vue.prototype.$flashStorage.flash(
          "システムエラー:もう一度お試しください",
          "error",
          { timeout: 3000, important: true }
        );
        return false;
      }
    },

    //nominalUserメソッド
    //発注商品メモ、受注商品メモ編集
    async userUpdateNote(
      { commit },
      notes: [{ id: number | null; content: string | null }]
    ) {
      try {
        await axios.put(`/user/note/update_content`, {
          notes
        });
        commit("error/resetError", null, { root: true });
        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          commit("error/error", error.response.data, { root: true });
        }
        Vue.prototype.$flashStorage.flash(
          "システムエラー:もう一度お試しください",
          "error",
          { timeout: 3000, important: true }
        );
        return false;
      }
    }
  }
} as Module<ReceivedOrderState, RootState>;
