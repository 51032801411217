import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { CustomerProductState } from "@/types/customer_product";
import { CustomerProduct } from "@/models/customer_product";
import { Pagination } from "@/models/pagination";

const state: CustomerProductState = {
  customer_product: new CustomerProduct(),
  pagination: new Pagination()
};

export const customer_product: Module<CustomerProductState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
