import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { StockState } from "@/types/stock";
import { Stock } from "@/models/stock";
import { Pagination } from "@/models/pagination";

const state: StockState = {
  stock: new Stock(),
  pagination: new Pagination()
};

export const stock: Module<StockState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
