import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { UserState } from "@/types/user";
import { User } from "@/models/user";
import { Pagination } from "@/models/pagination";

const state: UserState = {
  user: new User(),
  pagination: new Pagination(),
  staff_pagination: new Pagination()
};

export const user: Module<UserState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
