import { IBranch, IBranchRequest } from "@/types/branch";
import { ISelectItem } from "@/types";
import { ISalesStaff } from "@/types/customer";

export class Branch implements IBranch {
  public id;
  public uuid;
  public name;
  public kana;
  public short_name;
  public email;
  public postalcode;
  public prefecture;
  public address1;
  public address2;
  public tel;
  public fax;
  public customer_id;
  public users: number[] = [];
  public user: ISalesStaff[] = [];
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: IBranch) {
    this.id = params ? params.id : 0;
    this.uuid = params ? params.uuid : "";
    this.name = params ? params.name : "";
    this.kana = params ? params.kana : "";
    this.short_name = params ? params.short_name : "";
    this.email = params ? params.email : "";
    this.postalcode = params ? params.postalcode : "";
    this.prefecture = params ? params.prefecture : "";
    this.address1 = params ? params.address1 : "";
    this.address2 = params ? params.address2 : "";
    this.tel = params ? params.tel : "";
    this.fax = params ? params.fax : "";
    this.customer_id = params ? params.customer_id : 0;
    this.users = params ? params.users : [];
    this.user = params ? params.user : [];
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.name,
      value: this.id
    };
  }
}

export class BranchRequest implements IBranchRequest {
  public uuid;
  public name;
  public kana;
  public short_name;
  public email;
  public postalcode;
  public prefecture = "";
  public address1 = "";
  public address2 = "";
  public tel;
  public fax;
  public customer_id;
  public users: number[] = [];

  public constructor(params?: IBranchRequest) {
    this.uuid = params ? params.uuid : "";
    this.name = params ? params.name : "";
    this.kana = params ? params.kana : "";
    this.short_name = params ? params.short_name : "";
    this.email = params ? params.email : "";
    this.postalcode = params ? params.postalcode : "";
    this.prefecture = params ? params.prefecture : "";
    this.address1 = params ? params.address1 : "";
    this.address2 = params ? params.address2 : "";
    this.tel = params ? params.tel : "";
    this.fax = params ? params.fax : "";
    this.customer_id = params ? params.customer_id : 0;
    this.users = params ? params.users : [];
  }

  public createFromBranch(branch: IBranch) {
    this.uuid = branch.uuid || "";
    this.name = branch.name || "";
    this.kana = branch.kana || "";
    this.short_name = branch.short_name || "";
    this.email = branch.email || "";
    this.postalcode = branch.postalcode || "";
    this.prefecture = branch.prefecture || "";
    this.address1 = branch.address1 || "";
    this.address2 = branch.address2 || "";
    this.tel = branch.tel || "";
    this.fax = branch.fax || "";
    this.customer_id = branch.customer_id || 0;
    this.users = branch.users || [];
  }
}
