import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { BoosterState } from "@/types/booster";
import { Booster } from "@/models/booster";

const state: BoosterState = {
  booster: new Booster(),
  buying_price: 0,
  selling_price: 0,
  gross_profit: 0,
  gross_profit_rate: 0,
  monthly_calc: [],
  user_calc: [],
  supplier_calc: [],
  product_calc: []
};

export const booster: Module<BoosterState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
