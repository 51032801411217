import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
import ja from "vuetify/src/locale/ja";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // primary: colors.grey.darken4, // #E53935
        secondary: colors.yellow.darken3 // #F9A825
        // accent: colors.indigo.base, // #3F51B5
        // adminPrimary: colors.grey.lighten3, //#EEEEEE
        // doneBtn: colors.grey, //#9E9E9E
      }
    }
  },
  lang: {
    locales: { ja },
    current: "ja"
  }
});
