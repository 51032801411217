import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { ReceivedOrderState } from "@/types/received_order";
import { ReceivedOrder } from "@/models/received_order";
import { Pagination } from "@/models/pagination";

const state: ReceivedOrderState = {
  received_order: new ReceivedOrder(),
  pagination: new Pagination()
};

export const received_order: Module<ReceivedOrderState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
