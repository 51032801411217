import { ISelectItem } from "@/types";
import { ISupplierStaff, ISupplierStaffRequest } from "@/types/supplier_staff";

export class SupplierStaff implements ISupplierStaff {
  public id = 0;
  public name = "";
  public kana = "";
  public email = "";
  public tel = "";
  public supplier_id = 0;
  public created_at = "";
  public updated_at = "";
  public deleted_at = "";

  public constructor(params?: ISupplierStaff) {
    this.id = params ? params.id : 0;
    this.name = params ? params.name : "";
    this.kana = params ? params.kana : "";
    this.email = params ? params.email : "";
    this.tel = params ? params.tel : "";
    this.supplier_id = params ? params.supplier_id : 0;
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toRelationSupplierStaffSelectItem(): ISelectItem {
    return {
      text: this.name,
      value: this.id
    };
  }
}

export class SupplierStaffRequest implements ISupplierStaffRequest {
  public name = "";
  public kana = "";
  public email = "";
  public tel = "";
  public supplier_id = 0;

  public createFromSupplierStaff(param: ISupplierStaff) {
    this.name = param.name;
    this.kana = param.kana;
    this.email = param.email;
    this.tel = param.tel;
    this.supplier_id = param.supplier_id;
  }
}
