import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  IBranchListRequest,
  IBranchCreateRequest,
  IBranchUpdateRequest,
  BranchState,
  IBranch,
  IBranchRequest
} from "@/types/branch";

export const actions: ActionTree<BranchState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: IBranchListRequest) {
    try {
      const response = await axios.get<IPagination<IBranch>>("/admin/branch", {
        params
      });
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, branch_id: number) {
    try {
      const response = await axios.get<{ branchDetailViewModel: IBranch }>(
        `/admin/branch/${branch_id}`
      );
      commit("single", response.data.branchDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { branch_id: number; params: IBranchUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IBranch>(
        `/admin/branch/${data.branch_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: IBranchCreateRequest) {
    try {
      const response = await axios.post<IBranch>("/admin/branch", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, branch_id: number) {
    try {
      await axios.delete(`/admin/branch/${branch_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // 二重登録チェック
  async adminIsUniqueName(state, params: IBranchRequest) {
    try {
      const response = await axios.get<boolean>(
        "/admin/branch/is_unique_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async adminIsUniqueShortName(state, params: IBranchRequest) {
    try {
      const response = await axios.get<boolean>(
        "/admin/branch/is_unique_short_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: IBranchListRequest) {
    try {
      const response = await axios.get<IPagination<IBranch>>("/user/branch", {
        params
      });
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, branch_id: number) {
    try {
      const response = await axios.get<{ branchDetailViewModel: IBranch }>(
        `/user/branch/${branch_id}`
      );
      commit("single", response.data.branchDetailViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { branch_id: number; params: IBranchUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IBranch>(
        `/user/branch/${data.branch_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: IBranchCreateRequest) {
    try {
      const response = await axios.post<IBranch>("/user/branch", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, branch_id: number) {
    try {
      await axios.delete(`/user/branch/${branch_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // 二重登録チェック
  async userIsUniqueName(state, params: IBranchRequest) {
    try {
      const response = await axios.get<boolean>("/user/branch/is_unique_name", {
        params
      });
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async userIsUniqueShortName(state, params: IBranchRequest) {
    try {
      const response = await axios.get<boolean>(
        "/user/branch/is_unique_short_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  }
};

export default actions;
