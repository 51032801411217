import {
  IReferenceNumber,
  IReferenceNumberRequest
} from "@/types/reference_number";
import { ISelectItem } from "@/types";

export class ReferenceNumber implements IReferenceNumber {
  public id;
  public ref_no;
  public order_id;
  public order_no;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: IReferenceNumber) {
    this.id = params ? params.id : 0;
    this.ref_no = params ? params.ref_no : "";
    this.order_id = params ? params.order_id : 0;
    this.order_no = params ? params.order_no : "";
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.ref_no,
      value: this.id
    };
  }
}

export class ReferenceNumberRequest implements IReferenceNumberRequest {
  public ref_no = "";
  public order_id = 0;

  public createFromReferenceNumber(param: IReferenceNumber) {
    this.ref_no = param.ref_no;
    this.order_id = param.order_id;
  }
}
