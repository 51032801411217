import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { CustomerStaffState } from "@/types/customer_staff";
import { CustomerStaff } from "@/models/customer_staff";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<CustomerStaffState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.customer_staff || new CustomerStaff();
  },
  selectItem: state => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data.map(item => item.toSelectItem());
  },
  filterdSelectItem: state => (id: number) => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data
      .filter(item => item.branch_id === id)
      .map(item => item.toSelectItem());
  },
  find: state => (id: number) => {
    return state.pagination
      ? state.pagination.data.find(customer_staff => {
          return customer_staff.id == id;
        })
      : new CustomerStaff();
  }
};
export default getters;
