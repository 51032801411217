import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { ReceivedOrderState } from "@/types/received_order";
import { ReceivedOrder } from "@/models/received_order";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<ReceivedOrderState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.received_order || new ReceivedOrder();
  },
  selectItem: state => {
    if (!state.pagination) {
      return [];
    }

    return state.pagination.data.map(received_order =>
      ReceivedOrder.toSelectItem(received_order)
    );
  },
  filterdSelectItem: state => (id: number) => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data
      .filter(received_order => received_order.customer_id === id)
      .map(received_order => ReceivedOrder.toSelectItem(received_order));
  },
  find: state => (id: number) => {
    if (!state.pagination) {
      return new ReceivedOrder();
    }

    return state.pagination.data.find(item => {
      return item.id == id;
    });
  }
};
export default getters;
