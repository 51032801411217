import { IProduct } from "@/types/product";
import { IProductShipment } from "@/types/product_shipment";
import { Lot } from "./lot";
// import { ISelectItem } from "@/types";

export class ProductShipment implements IProductShipment {
  public id;
  public quantity;
  public buying_unit_price;
  public selling_unit_price;
  public amount;
  public selling_unit_amount;
  public memo;
  public product_id;
  public product_code;
  public product_unit;
  public product_name;
  public lot_id;
  public lot_no;
  public customer_product_id;
  public customer_pn;
  public movement_memo;
  public warehouse_id;
  public stock_id;
  public stockage_id;
  public movement_id;
  public maker_id;
  public note_id;
  public shipment_id;
  public received_order_quantity;
  public product: IProduct[];
  public lot;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: IProductShipment) {
    this.id = params ? params.id : 0;
    this.quantity = params ? params.quantity : 0;
    this.buying_unit_price = params ? params.buying_unit_price : 0;
    if (params && typeof params.amount === "string") {
      this.amount = parseFloat(params.amount);
    } else {
      this.amount = params ? params.amount : 0;
    }
    this.selling_unit_price = params ? params.selling_unit_price : 0;
    if (params && typeof params.selling_unit_amount === "string") {
      this.selling_unit_amount = parseFloat(params.selling_unit_amount);
    } else {
      this.selling_unit_amount = params ? params.selling_unit_amount : 0;
    }
    this.memo = params ? params.memo : "";
    this.product_id = params ? params.product_id : 0;
    this.product_code = params ? params.product_code : "";
    this.product_unit = params ? params.product_unit : "";
    this.product_name = params ? params.product_name : "";
    this.lot_id = params ? params.lot_id : 0;
    this.lot_no = params ? params.lot_no : null;
    this.customer_product_id = params ? params.customer_product_id : null;
    this.customer_pn = params ? params.customer_pn : "";
    this.movement_memo = params ? params.movement_memo : "";
    this.warehouse_id = params ? params.warehouse_id : null;
    this.stock_id = params ? params.stock_id : null;
    this.stockage_id = params ? params.stockage_id : null;
    this.movement_id = params ? params.movement_id : null;
    this.maker_id = params ? params.maker_id : 0;
    this.note_id = params ? params.note_id : null;
    this.shipment_id = params ? params.shipment_id : 0;
    this.received_order_quantity = params ? params.received_order_quantity : 0;
    this.product = params ? params.product : [];
    this.lot = params ? params.lot : new Lot();
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }
}
