import axios from "axios";
import { ActionTree } from "vuex";
import { RootState } from "@/types";
import {
  IProductOrderStatus,
  IProductOrderStatusListRequest,
  ProductOrderStatusState
} from "@/types/product_order_status";

export const actions: ActionTree<ProductOrderStatusState, RootState> = {
  //adminメソッド
  async adminGetProductOrderStatus(
    { commit },
    params: IProductOrderStatusListRequest
  ) {
    try {
      const response = await axios.get<{ viewModel: IProductOrderStatus }>(
        "/admin/product_order_status",
        {
          params
        }
      );
      commit("product_order_status", response.data.viewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  //nominalUserメソッド
  async userGetProductOrderStatus(
    { commit },
    params: IProductOrderStatusListRequest
  ) {
    try {
      const response = await axios.get<{ viewModel: IProductOrderStatus }>(
        "/user/product_order_status",
        {
          params
        }
      );
      commit("product_order_status", response.data.viewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  }
};

export default actions;
