import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { StockState } from "@/types/stock";
import { Stock } from "@/models/stock";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<StockState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.stock || new Stock();
  },
  selectWarehouseWithProducts:
    state =>
    (
      product_id: number,
      customer_id: number,
      stock_id?: number,
      edit?: boolean
    ) => {
      if (!state.pagination) {
        return [];
      }
      if (edit) {
        return state.pagination.data
          .filter(
            item =>
              (item.product_id == product_id &&
                item.customer_id == customer_id &&
                item.storing_quantity != 0) ||
              (item.product_id == product_id &&
                item.customer_id == null &&
                item.storing_quantity != 0) ||
              item.id == stock_id
          )
          .map(item => item.toWarehouseSelectItem());
      }
      if (!edit) {
        return state.pagination.data
          .filter(
            item =>
              (item.product_id == product_id &&
                item.quantity != 0 &&
                item.customer_id == customer_id) ||
              (item.product_id == product_id &&
                item.quantity != 0 &&
                item.customer_id == null) ||
              item.id == stock_id
          )
          .map(item => item.toWarehouseSelectItem());
      }
    },
  warehouseFilterdSelectItem:
    state =>
    (
      product_id: number,
      customer_id: number,
      warehouse_id: number,
      stock_id?: number,
      edit?: boolean
    ) => {
      if (!state.pagination) {
        return [];
      }
      if (edit) {
        return state.pagination.data
          .filter(
            item =>
              (item.product_id == product_id &&
                item.customer_id == customer_id &&
                item.warehouse_id == warehouse_id &&
                item.storing_quantity != 0) ||
              (item.product_id == product_id &&
                item.customer_id == null &&
                item.warehouse_id == warehouse_id &&
                item.storing_quantity != 0) ||
              item.id == stock_id
          )
          .map(item => item.toLotSelectItem());
      }
      if (!edit) {
        return state.pagination.data
          .filter(
            item =>
              (item.product_id == product_id &&
                item.customer_id == customer_id &&
                item.warehouse_id == warehouse_id &&
                item.quantity != 0) ||
              (item.product_id == product_id &&
                item.customer_id == null &&
                item.warehouse_id == warehouse_id &&
                item.quantity != 0) ||
              item.id == stock_id
          )
          .map(item => item.toLotSelectItem());
      }
    },
  lotFilterdSelectItem:
    state =>
    (product_id: number, warehouse_id: number, lot_id: number | null) => {
      if (!state.pagination) {
        return [];
      }
      return state.pagination.data
        .filter(
          item =>
            item.product_id === product_id &&
            item.warehouse_id === warehouse_id &&
            item.lot_id === lot_id
        )
        .map(item => item.toBuyingUnitPriceSelectItem());
    },
  findBuyingUnitPriceFilterdItem:
    state =>
    (
      product_id: number,
      warehouse_id: number,
      lot_id: number | null,
      buying_unit_price: number
    ) => {
      if (!state.pagination) {
        return new Stock();
      }
      return state.pagination.data.find(item => {
        return (
          item.product_id === product_id &&
          item.warehouse_id === warehouse_id &&
          item.lot_id === lot_id &&
          item.buying_unit_price == buying_unit_price
        );
      });
    },
  customerProductFilterdSumQuantity: state => (customer_product_id: number) => {
    if (!state.pagination) {
      return [];
    }
    let total_quantity = 0;
    const filterd_stock_list = state.pagination.data.filter(
      item => item.customer_product_id === customer_product_id
    );
    for (const [, filterd_stock] of filterd_stock_list.entries()) {
      total_quantity += filterd_stock.quantity;
    }
    return total_quantity;
  },
  find: state => (id: number) => {
    if (!state.pagination) {
      return new Stock();
    }

    return state.pagination.data.find(item => {
      return item.id == id;
    });
  }
};
export default getters;
