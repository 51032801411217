import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { ProductOrderStatusState } from "@/types/product_order_status";
import { ProductOrderStatus } from "@/models/product_order_status";

export const getters: GetterTree<ProductOrderStatusState, RootState> = {
  // selectItem: state => {
  //   if (!state.pagination) {
  //     return [];
  //   }
  order_product: state => {
    return state.order_product || [];
  },
  product_received_order: state => {
    return state.product_received_order || [];
  },
  OrderSelectItem: state => {
    return state.order_product
      ? state.order_product.map(order =>
          ProductOrderStatus.toOrderSelectItem(order)
        )
      : [];
  }
};
export default getters;
