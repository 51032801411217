import { IBranchRequest } from "@/types/branch";
import { ICustomerRequest } from "@/types/customer";
import {
  IMoneyforward,
  IMoneyforwardDepartmentCreateRequest,
  IMoneyforwardDepartmentUpdateRequest,
  IMoneyforwardPartnerCreateRequest,
  IMoneyforwardPartnerUpdateRequest
} from "@/types/moneyforward";

export class Moneyforward implements IMoneyforward {
  public id;
  public code;
  public departments: { id: string }[];

  public constructor(params?: IMoneyforward) {
    this.id = params ? params.id : "";
    this.code = params ? params.code : "";
    this.departments = params ? params.departments : [];
  }
}

export class MoneyforwardPartnerCreateRequest
  implements IMoneyforwardPartnerCreateRequest
{
  public code = "";
  public name = "";
  public name_kana = "";
  public name_suffix = "";
  public departments = [];

  public createFromCustomer(params: ICustomerRequest): void {
    this.code = params.customer_mfid;
    this.name = params.name;
    this.name_kana = params.kana;
    this.name_suffix = params.name_suffix;
  }
}

export class MoneyforwardPartnerUpdateRequest
  implements IMoneyforwardPartnerUpdateRequest
{
  public code = "";
  public name = "";
  public name_kana = "";
  public name_suffix = "";

  public createFromCustomer(params: ICustomerRequest): void {
    this.code = params.customer_mfid;
    this.name = params.name;
    this.name_kana = params.kana;
    this.name_suffix = params.name_suffix;
  }
}

export class MoneyforwardDepartmentCreateRequest
  implements IMoneyforwardDepartmentCreateRequest
{
  public zip = "";
  public prefecture = "";
  public address1 = "";
  public address2 = "";
  public tel = "";
  public email = "";
  public person_dept = "";

  public createFromBranch(params: IBranchRequest): void {
    this.zip = params.postalcode;
    this.prefecture = params.prefecture;
    this.address1 = params.address1;
    this.address2 = params.address2;
    this.tel = params.tel;
    this.email = params.email;
    this.person_dept = params.name;
  }
}

export class MoneyforwardDepartmentUpdateRequest
  extends MoneyforwardDepartmentCreateRequest
  implements IMoneyforwardDepartmentUpdateRequest {}
