import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { InvoiceState } from "@/types/invoice";
import { Invoice, InvoiceCalculation } from "@/models/invoice";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<InvoiceState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.invoice || new Invoice();
  },
  invoice_calculation: state => {
    return state.invoice_calculation || new InvoiceCalculation();
  },
  // selectItem: state => {
  //   return state.pagination
  //     ? state.pagination.data.map(invoice => Invoice.toSelectItem(invoice))
  //     : [];
  // },
  find: state => (id: number) => {
    return state.pagination
      ? state.pagination.data.find(invoice => {
          return invoice.id == id;
        })
      : new Invoice();
  }
};
export default getters;
