import { MutationTree } from "vuex";
import { ShipmentState, IShipment } from "@/types/shipment";
import { IPagination } from "@/types";
import { Pagination } from "@/models/pagination";
import { Shipment } from "@/models/shipment";

const mutations: MutationTree<ShipmentState> = {
  pagination: (state, items: IPagination<IShipment>) => {
    const params: IPagination<Shipment> = {
      current_page: items.current_page,
      first_page_url: items.first_page_url,
      from: items.from,
      last_page: items.last_page,
      last_page_url: items.last_page_url,
      next_page_url: items.next_page_url,
      path: items.path,
      per_page: items.per_page,
      prev_page_url: items.prev_page_url,
      to: items.to,
      total: items.total,
      data: items.data.map(item => new Shipment(item))
    };

    state.pagination = new Pagination(params);
  },
  single: (state, item: IShipment) => {
    state.shipment = new Shipment(item);
  },
  calcSingle: (state, item: IShipment) => {
    state.calc_shipment = new Shipment(item);
  },
  clear: state => {
    state.shipment = new Shipment();
    state.pagination = new Pagination();
  }
};

export default mutations;
