import { User } from "@/models/user";
import { RootState } from "@/types";
import { UserState } from "@/types/user";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";

const state: UserState = {
  user: new User()
};

export const auth: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
