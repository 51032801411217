import { IOrderPriceTag } from "@/types/order_price_tag";

export class OrderPriceTag implements IOrderPriceTag {
  public id;
  public price;
  public price_tag_no;
  public price_tag_id;
  public price_tag_supplier_id;
  public price_tag_name;
  public supplier_pn;
  public is_minus;

  public constructor(params?: IOrderPriceTag) {
    this.id = params ? params.id : 0;
    this.price = params ? params.price : null;
    this.price_tag_no = params ? params.price_tag_no : "";
    this.price_tag_id = params ? params.price_tag_id : 0;
    this.price_tag_supplier_id = params ? params.price_tag_supplier_id : null;
    this.price_tag_name = params ? params.price_tag_name : "";
    this.supplier_pn = params ? params.supplier_pn : "";
    this.is_minus = params ? params.is_minus : 0;
  }

  public createFromOrderPriceTag(orderPriceTag: IOrderPriceTag) {
    if (orderPriceTag.id) {
      this.id = orderPriceTag.id;
    }
    this.price = orderPriceTag.price;
    this.price_tag_no = orderPriceTag.price_tag_no;
    this.price_tag_id = orderPriceTag.price_tag_id;
    this.price_tag_supplier_id = orderPriceTag.price_tag_supplier_id;
    this.price_tag_name = orderPriceTag.price_tag_name;
    this.supplier_pn = orderPriceTag.supplier_pn;
    this.is_minus = orderPriceTag.is_minus;
  }
}
