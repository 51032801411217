import { RootState } from "@/types";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { ErrorState } from "@/types/error";

const state: ErrorState = {
  error: {
    message: "",
    errors: {}
  }
};

export const error: Module<ErrorState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations
};
