import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { MonthlyCountStockState } from "@/types/monthly_count_stock";

export const getters: GetterTree<MonthlyCountStockState, RootState> = {
  // selectItem: state => {
  //   if (!state.pagination) {
  //     return [];
  //   }
  storing_sum: state => {
    return state.storing_sum || 0;
  },
  shipment_sum: state => {
    return state.shipment_sum || 0;
  },
  warehouse_stock_array: state => {
    return state.warehouse_stock_array || [];
  }
};
export default getters;
