import {
  ILot,
  ILotRequest,
  ILotCreateRequest,
  ILotUpdateRequest
} from "@/types/lot";
import { ISelectItem } from "@/types";

export class Lot implements ILot {
  public id;
  public lot_no;
  public product_id;
  public product_name;
  public product_code;
  public user_id;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: ILot) {
    this.id = params ? params.id : 0;
    this.lot_no = params ? params.lot_no : "";
    this.product_id = params ? params.product_id : 0;
    this.product_name = params ? params.product_name : "";
    this.product_code = params ? params.product_code : "";
    this.user_id = params ? params.user_id : 0;
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.lot_no,
      value: this.id
    };
  }
}

export class LotRequest implements ILotRequest {
  public lot_no: string | null = null;
  public product_id = 0;
  public user_id = 0;

  public createFromLot(lot: ILot) {
    this.lot_no = lot.lot_no;
    this.product_id = lot.product_id;
    this.user_id = lot.user_id;
  }
}

export class LotCreateRequest implements ILotCreateRequest {
  public lots: ILotRequest[] = [];
}

export class LotUpdateRequest implements ILotUpdateRequest {
  public lot_no: string | null = null;
  public product_id = 0;
  public user_id = 0;

  public constructor(params?: ILotUpdateRequest) {
    if (!params) {
      return;
    }

    this.lot_no = params.lot_no;
    this.product_id = params.product_id;
    this.user_id = params.user_id;
  }

  public createFromLot(lot: ILot) {
    this.lot_no = lot.lot_no;
    this.product_id = lot.product_id;
    this.user_id = lot.user_id;
  }
}
