import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { ShipmentState } from "@/types/shipment";
import { Shipment } from "@/models/shipment";
import { Pagination } from "@/models/pagination";
import Vue from "vue";

export const getters: GetterTree<ShipmentState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.shipment || new Shipment();
  },
  calcSingle: state => {
    return state.calc_shipment || new Shipment();
  },
  filterdShipmentList: state => (id: number) => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data.filter(item => item.received_order_id === id);
  },
  filterShipmentSchedule: state => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data.filter(
      item =>
        item.shipment_status ==
          Vue.prototype.$shipmentStatus.estimated_shipment ||
        item.shipment_status == Vue.prototype.$shipmentStatus.pre_shipment
    );
  }
  // selectItem: state => {
  //   return state.pagination
  //     ? state.pagination.data.map(shipment => Shipment.toSelectItem(shipment))
  //     : [];
  // },
  // find: state => (id: number) => {
  //   return state.pagination
  //     ? state.pagination.data.find(shipment => {
  //         return shipment.id == id;
  //       })
  //     : new Shipment();
  // }
};
export default getters;
