import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { ShipSlipState } from "@/types/ship_slip";

export const getters: GetterTree<ShipSlipState, RootState> = {
  duplicateProduct: state => {
    return state.duplicate_product || [];
  },
  invoiceDuplicateProduct: state => {
    return state.invoice_duplicate_product || [];
  }
};
export default getters;
