import Vue from "vue";
Vue.prototype.$userRole = {
  admin: { value: 5, label: "管理者" },
  user: { value: 10, label: "ユーザ" }
};

Vue.prototype.$orderStatus = {
  order_registration: 1,
  ordered: 2,
  wait_storing: 3,
  stored: 4,
  cancel_order: 5,
  division_storing: 6
};

Vue.prototype.$receivedOrderStatus = {
  new_received_order: 1,
  received_ordered: 2,
  estimated_shipment: 3,
  shipped: 4,
  cancel_received_order: 5,
  division_storing: 6,
  fixed_delivery_date: 7
};

Vue.prototype.$movementStatus = {
  storing: 1,
  shipment: 2,
  movement: 3
};

Vue.prototype.$shipmentStatus = {
  estimated_shipment: 1,
  shipped: 2,
  pre_shipment: 3
};

Vue.prototype.$paymentStatus = {
  uncollected: 1,
  collected: 2,
  unnecessary_collect: 3
};

Vue.prototype.$registrationStatus = {
  registered: 1,
  registration_scheduled: 2,
  no_scheduled: 3
};

Vue.prototype.$currency_type = {
  jpy: 1,
  usd: 2,
  eur: 3
};

Vue.prototype.$adminSideNav = [
  {
    name: "HOME",
    to: "/admin/home",
    icon: "mdi-home"
  },
  {
    name: "受注一覧",
    to: "/admin/received_orders_management/list",
    icon: "mdi-phone-incoming",
    contents: ["受注登録", "受注編集/削除", "事前出荷登録", "ロット引当"]
  },
  {
    name: "発注一覧",
    to: "/admin/order/list",
    icon: "mdi-package-variant-closed-plus",
    contents: ["発注登録", "発注編集/削除", "発注書発行", "入荷処理"]
  },
  {
    name: "出荷一覧",
    to: "/admin/shipment/list",
    icon: "mdi-truck",
    contents: [
      "出荷処理",
      "事前出荷修正/削除",
      "ロット引当",
      "ロット引当修正/削除",
      "出荷スケジュールDL"
    ]
  },
  {
    name: "在庫一覧",
    to: "/admin/inventory/list",
    icon: "mdi-warehouse",
    contents: ["倉庫移動処理", "入荷処理", "在庫表DL"]
  },
  {
    name: "商品移動履歴一覧",
    to: "/admin/movement/list",
    icon: "mdi-history",
    contents: [
      "ロット引当修正/削除",
      "入荷修正/削除",
      "倉庫移動修正/削除",
      "商品移動履歴リストDL"
    ]
  },
  {
    name: "Booster",
    to: "/admin/booster",
    icon: "mdi-chart-bar"
  }
];

Vue.prototype.$adminSideSettingNav = [
  {
    name: "ユーザ",
    to: "/admin/user/list",
    icon: "mdi-account"
  },
  {
    name: "メーカ",
    to: "/admin/maker/list",
    icon: "mdi-factory"
  },
  {
    name: "サプライヤ",
    to: "/admin/supplier/list",
    icon: "mdi-office-building-outline"
  },
  {
    name: "商品",
    to: "/admin/product/list",
    icon: "mdi-package-variant-closed"
  },
  {
    name: "顧客",
    to: "/admin/customer/list",
    icon: "mdi-account-group"
  },
  {
    name: "倉庫",
    to: "/admin/warehouse/list",
    icon: "mdi-warehouse"
  },
  {
    name: "税設定",
    to: "/admin/tax/list",
    icon: "mdi-currency-jpy"
  },
  {
    name: "支払方法",
    to: "/admin/payment_method/list",
    icon: "mdi-account-cash"
  },
  {
    name: "諸経費",
    to: "/admin/price_tag/list",
    icon: "mdi-cash-plus"
  },
  {
    name: "貿易条件",
    to: "/admin/trade_term/list",
    icon: "mdi-ferry"
  },
  {
    name: "手配方法",
    to: "/admin/arrangement_method/list",
    icon: "mdi-handshake-outline"
  }
];

Vue.prototype.$userSideNav = [
  {
    name: "HOME",
    to: "/user/home",
    icon: "mdi-home"
  },
  {
    name: "受注一覧",
    to: "/user/received_orders_management/list",
    icon: "mdi-phone-incoming",
    contents: ["受注登録", "受注編集"]
  },
  {
    name: "発注一覧",
    to: "/user/order/list",
    icon: "mdi-package-variant-closed-plus",
    contents: ["発注登録", "発注編集", "発注書発行"]
  },
  {
    name: "出荷一覧",
    to: "/user/shipment/list",
    icon: "mdi-truck",
    contents: ["出荷スケジュールDL"]
  },
  {
    name: "在庫一覧",
    to: "/user/inventory/list",
    icon: "mdi-warehouse",
    contents: ["在庫表DL"]
  },
  {
    name: "商品移動履歴一覧",
    to: "/user/movement/list",
    icon: "mdi-history",
    contents: ["商品移動履歴リストDL"]
  },
  {
    name: "Booster",
    to: "/user/booster",
    icon: "mdi-chart-bar"
  }
];

Vue.prototype.$invoiceOnlySideNav = [
  {
    name: "請求書一覧",
    to: "/invoice_only/invoice/list",
    icon: "mdi-receipt-text-outline"
  }
];

Vue.prototype.$userSideSettingNav = [
  {
    name: "メーカ",
    to: "/user/maker/list",
    icon: "mdi-factory"
  },
  {
    name: "サプライヤ",
    to: "/user/supplier/list",
    icon: "mdi-office-building-outline"
  },
  {
    name: "商品",
    to: "/user/product/list",
    icon: "mdi-package-variant-closed"
  },
  {
    name: "顧客",
    to: "/user/customer/list",
    icon: "mdi-account-group"
  }
];
