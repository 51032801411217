import { GetterTree } from "vuex";
import { RootState } from "@/types";
import { WarehouseState } from "@/types/warehouse";
import { Warehouse } from "@/models/warehouse";
import { Pagination } from "@/models/pagination";

export const getters: GetterTree<WarehouseState, RootState> = {
  list: state => {
    return state.pagination ? state.pagination.data : [];
  },
  pagination: state => {
    return state.pagination || new Pagination();
  },
  single: state => {
    return state.warehouse || new Warehouse();
  },
  selectItem: state => {
    if (!state.pagination) {
      return [];
    }
    return state.pagination.data.map(item => item.toSelectItem());
  },
  find: state => (id: number) => {
    if (!state.pagination) {
      return new Warehouse();
    }

    return state.pagination.data.find(item => {
      return item.id == id;
    });
  }
};
export default getters;
