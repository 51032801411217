import { RootState } from "@/types";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Module } from "vuex";
import { CustomerState } from "@/types/customer";
import { Customer } from "@/models/customer";
import { Pagination } from "@/models/pagination";

const state: CustomerState = {
  customer: new Customer(),
  pagination: new Pagination()
};

export const customer: Module<CustomerState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
