import { MutationTree } from "vuex";
import {
  ProductShipmentState,
  IProductShipment
} from "@/types/product_shipment";
import { IPagination } from "@/types";
import { Pagination } from "@/models/pagination";
import { ProductShipment } from "@/models/product_shipment";

const mutations: MutationTree<ProductShipmentState> = {
  pagination: (state, items: IPagination<IProductShipment>) => {
    const params: IPagination<ProductShipment> = {
      current_page: items.current_page,
      first_page_url: items.first_page_url,
      from: items.from,
      last_page: items.last_page,
      last_page_url: items.last_page_url,
      next_page_url: items.next_page_url,
      path: items.path,
      per_page: items.per_page,
      prev_page_url: items.prev_page_url,
      to: items.to,
      total: items.total,
      data: items.data.map(item => new ProductShipment(item))
    };

    state.pagination = new Pagination(params);
  },
  single: (state, item: IProductShipment) => {
    state.product_shipment = new ProductShipment(item);
  },
  clear: state => {
    state.product_shipment = new ProductShipment();
    state.pagination = new Pagination();
  }
};

export default mutations;
