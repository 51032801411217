import Vue from "vue";
import axios from "axios";
import { ActionTree } from "vuex";
import { IPagination, RootState } from "@/types";
import {
  IProductListRequest,
  IProductCreateRequest,
  IProductUpdateRequest,
  ProductState,
  IProduct,
  IProductRequest
} from "@/types/product";

export const actions: ActionTree<ProductState, RootState> = {
  //adminメソッド
  async adminGetList({ commit }, params: IProductListRequest) {
    try {
      const response = await axios.get<IPagination<IProduct>>(
        "/admin/product",
        { params }
      );
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGetProductSegments(
    { commit, state },
    params: IProductListRequest
  ) {
    if (state.pagination && params.maker_id) {
      const isMaker = state.pagination.data.some(
        product => product.maker_id === params.maker_id
      );
      if (isMaker) {
        return; // すでに取得済みのmakerだった場合
      }
    }
    try {
      const response = await axios.get<IPagination<IProduct[]>>(
        "/admin/product",
        { params }
      );
      if (state.pagination) {
        commit("paginationSegment", response.data);
      } else {
        commit("pagination", response.data);
      }
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGetSearchList({ commit }, params: IProductListRequest) {
    try {
      const response = await axios.get<IPagination<IProduct>>(
        "/admin/product",
        { params }
      );
      commit("search_list", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminGet({ commit }, product_id: number) {
    try {
      const response = await axios.get<{ productViewModel: IProduct }>(
        `/admin/product/${product_id}`
      );
      commit("single", response.data.productViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async adminUpdate(
    { commit },
    data: { product_id: number; params: IProductUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IProduct>(
        `/admin/product/${data.product_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminCreate({ commit }, params: IProductCreateRequest) {
    try {
      const response = await axios.post<IProduct>("/admin/product", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async adminDelete({ commit }, product_id: number) {
    try {
      await axios.delete(`/admin/product/${product_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // 二重登録チェック
  async adminIsUniqueName(state, params: IProductRequest) {
    try {
      const response = await axios.get<boolean>(
        "/admin/product/is_unique_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async adminIsUniqueCode(state, params: IProductRequest) {
    try {
      const response = await axios.get<boolean>(
        "/admin/product/is_unique_code",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },

  //nominalUserメソッド
  async userGetList({ commit }, params: IProductListRequest) {
    try {
      const response = await axios.get<IPagination<IProduct>>("/user/product", {
        params
      });
      commit("pagination", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGetProductSegments({ commit, state }, params: IProductListRequest) {
    if (state.pagination && params.maker_id) {
      const isMaker = state.pagination.data.some(
        product => product.maker_id === params.maker_id
      );
      if (isMaker) {
        return; // すでに取得済みのmakerだった場合
      }
    }
    try {
      const response = await axios.get<IPagination<IProduct[]>>(
        "/user/product",
        { params }
      );
      if (state.pagination) {
        commit("paginationSegment", response.data);
      } else {
        commit("pagination", response.data);
      }
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGetSearchList({ commit }, params: IProductListRequest) {
    try {
      const response = await axios.get<IPagination<IProduct>>("/user/product", {
        params
      });
      commit("search_list", response.data);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userGet({ commit }, product_id: number) {
    try {
      const response = await axios.get<{ productViewModel: IProduct }>(
        `/user/product/${product_id}`
      );
      commit("single", response.data.productViewModel);
      commit("error/resetError", null, { root: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
    }
  },

  async userUpdate(
    { commit },
    data: { product_id: number; params: IProductUpdateRequest }
  ) {
    try {
      const response = await axios.patch<IProduct>(
        `/user/product/${data.product_id}`,
        data.params
      );
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("更新完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userCreate({ commit }, params: IProductCreateRequest) {
    try {
      const response = await axios.post<IProduct>("/user/product", params);
      commit("single", response.data);
      commit("error/resetError", null, { root: true });
      Vue.prototype.$flashStorage.flash("登録完了", "success", {
        timeout: 3000,
        important: true
      });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー:もう一度お試しください",
        "error",
        { timeout: 3000, important: true }
      );
      return false;
    }
  },

  async userDelete({ commit }, product_id: number) {
    try {
      await axios.delete(`/user/product/${product_id}`);
      Vue.prototype.$flashStorage.flash("削除完了", "success", {
        timeout: 3000,
        important: true
      });
      commit("error/resetError", null, { root: true });
      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        commit("error/error", error.response.data, { root: true });
      }
      Vue.prototype.$flashStorage.flash(
        "システムエラー：もう一度お試しください",
        "error",
        {
          timeout: 3000,
          important: true
        }
      );
      return false;
    }
  },

  // 二重登録チェック
  async userIsUniqueName(state, params: IProductRequest) {
    try {
      const response = await axios.get<boolean>(
        "/user/product/is_unique_name",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  },
  async userIsUniqueCode(state, params: IProductRequest) {
    try {
      const response = await axios.get<boolean>(
        "/user/product/is_unique_code",
        { params }
      );
      return response.data;
    } catch (error) {
      return false;
    }
  }
};

export default actions;
