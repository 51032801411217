import { ITax, ITaxRequest } from "@/types/tax";
import { ISelectItem } from "@/types";

export class Tax implements ITax {
  public id;
  public name;
  public tax_rate;
  public is_default;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: ITax) {
    this.id = params ? params.id : 0;
    this.name = params ? params.name : "";
    this.tax_rate = params ? params.tax_rate : null;
    this.is_default = params ? params.is_default : false;
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }

  public toSelectItem(): ISelectItem {
    return {
      text: this.name,
      value: this.id
    };
  }
}

export class TaxRequest implements ITaxRequest {
  public name = "";
  public tax_rate: number | null = null;
  public is_default = false;

  public createFromTax(param: ITax) {
    this.name = param.name;
    this.tax_rate = param.tax_rate;
    this.is_default = param.is_default;
  }
}
