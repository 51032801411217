import { MutationTree } from "vuex";
import { IError } from "@/types";
import { ErrorState } from "@/types/error";

export const mutations: MutationTree<ErrorState> = {
  error: (state, data: IError) => {
    if (data.errors) {
      state.error.errors = data.errors;
      state.error.message = "";
    } else {
      state.error.errors = {};
      state.error.message = data.message;
    }
  },
  resetError: state => {
    state.error = {
      message: "",
      errors: {}
    };
  }
};
export default mutations;
