import {
  IExceptPaymentTerm,
  IExceptPaymentTermRequest,
  IPaymentTerm,
  IPaymentTermCreateRequest,
  IPaymentTermRequest,
  IPaymentTermUpdateRequest
} from "@/types/payment_term";

export class PaymentTerm implements IPaymentTerm {
  public id;
  public amount;
  public is_under_amount;
  public payment_month;
  public payment_day;
  public add_day;
  public other_terms;
  public customer_id;
  public payment_method_id;
  public payment_method_name;
  public created_at;
  public updated_at;
  public deleted_at;

  public constructor(params?: IPaymentTerm) {
    this.id = params ? params.id : 0;
    this.amount = params ? params.amount : null;
    this.is_under_amount = params ? params.is_under_amount : null;
    this.payment_month = params ? params.payment_month : null;
    this.payment_day = params ? params.payment_day : null;
    this.add_day = params ? params.add_day : null;
    this.other_terms = params ? params.other_terms : null;
    this.customer_id = params ? params.customer_id : 0;
    this.payment_method_id = params ? params.payment_method_id : 0;
    this.payment_method_name = params ? params.payment_method_name : "";
    this.created_at = params ? params.created_at : "";
    this.updated_at = params ? params.updated_at : "";
    this.deleted_at = params ? params.deleted_at : "";
  }
}

export class PaymentTermRequest implements IPaymentTermRequest {
  public customer_id = 0;
}

export class PaymentTermCreateRequest
  extends PaymentTermRequest
  implements IPaymentTermCreateRequest
{
  public payment_terms: IPaymentTerm[] = [new PaymentTerm()];
}

export class PaymentTermUpdateRequest
  extends PaymentTermRequest
  implements IPaymentTermUpdateRequest
{
  public amount: number | null = null;
  public is_under_amount: boolean | null = null;
  public payment_month: number | null = null;
  public payment_day: number | null = null;
  public add_day: number | null = null;
  public other_terms: string | null = null;
  public payment_method_id = 0;
  public customer_id = 0;

  public createFromPaymentTerm(payment_term: IPaymentTerm): void {
    this.amount = payment_term.amount || null;
    this.is_under_amount = payment_term.is_under_amount;
    this.payment_month = payment_term.payment_month || null;
    this.payment_day = payment_term.payment_day || null;
    this.add_day = payment_term.add_day || null;
    this.other_terms = payment_term.other_terms || null;
    this.customer_id = payment_term.customer_id || 0;
    this.payment_method_id = payment_term.payment_method_id || 0;
  }
}

export class ExceptPaymentTerm implements IExceptPaymentTerm {
  public payment_date;
  public payment_term_id;
  public payment_method_id;
  public payment_method_name;

  public constructor(params?: IExceptPaymentTerm) {
    this.payment_date = params ? params.payment_date : null;
    this.payment_term_id = params ? params.payment_term_id : 0;
    this.payment_method_id = params ? params.payment_method_id : 0;
    this.payment_method_name = params ? params.payment_method_name : "";
  }
}

export class ExceptPaymentTermRequest implements IExceptPaymentTermRequest {
  public customer_id = 0;
  public invoice_date = "";
  public total_amount = 0;
}
