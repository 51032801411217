import { IInvoicePriceTag } from "@/types/invoice_price_tag";

export class InvoicePriceTag implements IInvoicePriceTag {
  public price;
  public actual_price;
  public invoice_id;
  public price_tag_id;
  public tag_name;
  public is_minus;

  public constructor(params?: IInvoicePriceTag) {
    this.price = params ? params.price : null;
    this.actual_price = params ? params.actual_price : null;
    this.invoice_id = params ? params.invoice_id : 0;
    this.price_tag_id = params ? params.price_tag_id : 0;
    this.tag_name = params ? params.tag_name : "";
    this.is_minus = params ? params.is_minus : false;
  }
}
