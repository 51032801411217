import { ActionTree } from "vuex";
import { RootState, IToken } from "@/types";
import { IUser, UserState } from "@/types/user";
import Axios from "axios";
import { removeCookie, setCookie } from "tiny-cookie";

const actions: ActionTree<UserState, RootState> = {
  init: async ({ dispatch }) => {
    const accessToken: boolean = await dispatch("checkAccessToken");
    if (accessToken) {
      return true;
    }
    return false;
  },
  checkAccessToken: async ({ getters, dispatch }) => {
    const accessToken: string | null = getters.accessToken;
    if (!accessToken) {
      return false;
    }
    await dispatch("setBearer", accessToken);
    await dispatch("getAuthorizedUser");
    if (!getters.isAuthorized) {
      return false;
    }
    return true;
  },
  checkRefreshToken: async ({ getters, dispatch }) => {
    const refreshToken: string | null = getters.refreshToken;
    if (!refreshToken) {
      return false;
    }
    await dispatch("refresh", refreshToken);
    if (!getters.isAuthorized) {
      return false;
    }
    return true;
  },
  getAuthorizedUser: async ({ commit }) => {
    try {
      const response = await Axios.get<IUser>("/user");
      commit("set", response.data);
      return true;
    } catch {
      return false;
    }
  },
  setToken: (state, token: IToken) => {
    setCookie("access_token", token.token.plainTextToken, {
      expires: `8640000s`
    });
  },

  setAbilities: (state, token: IToken) => {
    //cookie abilitiesに保存組
    setCookie("abilities", token.token.accessToken.abilities);
  },

  setBearer: (state, accessToken: string) => {
    Axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  },

  authorize: async ({ dispatch }, token: IToken) => {
    try {
      await Promise.all([
        dispatch("setToken", token),
        dispatch("setAbilities", token),
        dispatch("setBearer", token.token.plainTextToken)
      ]);
      await dispatch("getAuthorizedUser");
      return true;
    } catch {
      return false;
    }
  },

  deauthorize: async ({ commit }) => {
    Axios.defaults.headers.common.Authorization = "";
    delete Axios.defaults.headers.common.Authorization;
    removeCookie("access_token");
    removeCookie("refresh_token");
    //一応
    removeCookie("abilities");

    commit("clearUser");
    commit("clearToken");
  },
  setRedirectUrl: async (state, url: string) => {
    setCookie("redirect_url", url, { expires: "1D" });
  },
  clearRedirectUrl: async () => {
    removeCookie("redirect_url");
  }
  // refresh: async ({ dispatch }, refreshToken: string) => {
  //   try {
  //     const response = await Axios.post<IToken>("/oauth/refresh", {
  //       refresh_token: refreshToken
  //     });
  //     await dispatch("authorize", response.data);

  //     return true;
  //   } catch {
  //     return false;
  //   }
  // }
};

export default actions;
